import { DirectLine } from 'botframework-directlinejs';
import ReactWebChat from 'botframework-webchat';
import React from 'react';
import brandlogo from "./../../../assets/img/ChatBot_icon.svg";
import { Utility } from '../../utility';

const styleOptions = {
    botAvatarImage: brandlogo,
    bubbleBackground: '#ffffff',
    bubbleFromUserBackground: '#eb812a',
    bubbleFromUserBorderRadius: 10,
    bubbleBorderRadius:10, 
    bubbleFromUserTextColor:'#ffffff'
  };

//   styleOptions.bubbleBorderRadius = {
//     ...styleSet.textContent
//   }

class Webchat extends React.Component {
    constructor(){
        super();
        let botKey = Utility.getBotToken();
        this.directLine = new DirectLine({ token: botKey });
        // this.directLine = new DirectLine({ token: 'aw5kQuffygU.nVKXmwarj0dfvLJVA0Sb3-v24cm_gRF_madaaJ8bphg' });
    }

    render(){
        
        return(
            <ReactWebChat
                directLine={this.directLine}
                styleOptions={styleOptions}
                >                
            </ReactWebChat>             
        )
        
    }
   
}

export default Webchat;