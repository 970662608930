import React, { useState, useEffect } from 'react';
import './css/styles.css'
import MCHeader from "./common/Header";
import StarRating from "./common/StarRating";
import DriversQuestionsData from "./common/DriversQuestionsData";
// import ktmLogo from '../../assets/images/ktmNewLogo.png'
import ktmLogo from '../../assets/images/KTM.svg';
import huskarnaLogo from '../../assets/images/Husqvarna.svg';
import ktmLogoThanksScreen from '../../assets/images/ktmLogoThanksScreen.png'
import { decryptCrypto } from "./common/Crypto";
import { checkNPSLinkIsValid, fetchQuestionsData, saveQuestionsData } from './common/services';
import LinkInvalid from './common/InvalidLink';
import { AjaxLoader } from "../../common/Components/AjaxLoader";
import ThankComponent from './common/ThankyouScreen';
import { detectCurrentDevice, getUniqueIdFromBrowser } from './common/utils'
import { AppName, logSubmitOrBrowserCloseLogHandler, logErrorHandler, logRatingHandler, logFreeTextHandler, logLanguageClick } from './common/AppInsightLog';
import withAppInsights from "./appInsights";
import { KTM, KTM_APP_LINK, SOMETHING_WENT_WRONG } from './common/Constants';
import FeedbackExpiredKTMNew from '../../assets/images/FeedbackExpiredKTMNew.svg'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load()

const KTMMainContainer = () => {
    const currentTime = new Date()
    const [cryptoData, setCryptoData] = useState('')
    const [currentRating, setCurrentRating] = useState(null);
    const [questionsData, setQuestionsData] = useState(null)
    const [customerName, setCustomerName] = useState('')
    const [isLinkValid, setIsLinkValid] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(null)
    const [isFetchApiCalled, setIsFetchApiCalled] = useState(false)
    const [showThankyouScreen, setShowThankyouScreen] = useState(false)

    const onSubmitButtonClick = async (feedbackLines, suggetionText = '') => {
        const { parentSchemaName, docId, feedbackId1, visit, phone, documentNo } = cryptoData
        logFreeTextHandler(AppName.MC, parentSchemaName, suggetionText)
        const finalMCSubmitObj = {
            FeedbackLines: feedbackLines,
            IsFinalFeedbackSubmitted: true,
            SourceOfFeedback: "NPS",
            Remarks: suggetionText,
            FeedbackStarRating: currentRating,
            ParentSchemaName: parentSchemaName,
            DocumentNo: documentNo,
            ParentDocumentName: documentNo,
            MobileNo: phone,
            ProductType: "Probiking",
            DocID: docId,
            Recipient: phone,
            FeedbackID: feedbackId1,
            Visit: visit,
            SourceDevice: detectCurrentDevice() || '',
            NPS: "2.0",

            // "FeedbackID": '705',
            // "DocumentNo": "PBRJC12771202100079",
            // "LinkSentDate": "2021-06-25 12:22:10",
            // "MobileNo": "7798773577",
        }
        setIsFetchApiCalled(true)
        const postDataResponse = await saveQuestionsData(finalMCSubmitObj, KTM)
        logSubmitOrBrowserCloseLogHandler(AppName.MC, parentSchemaName, currentTime)
        setIsFetchApiCalled(false)
        if (postDataResponse && postDataResponse.IsRequestSuccessfull) {
            setShowThankyouScreen(postDataResponse)
        } else {
            setIsLinkValid(false)
            setErrorMessage(SOMETHING_WENT_WRONG)
            logErrorHandler(SOMETHING_WENT_WRONG)
        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    const alertUser = e => {
        logSubmitOrBrowserCloseLogHandler(AppName.KTM, cryptoData?.parentSchemaName, currentTime)
    }

    useEffect(() => {
        async function fetchMyAPI() {
            const decryptData = await decryptCrypto();
            const fp = await fpPromise
            const result = await fp.get()
            const visitorId = result.visitorId
            if (!decryptData) {
                setErrorMessage(SOMETHING_WENT_WRONG)
                setIsLinkValid(false)
                return
            }
            setCryptoData(decryptData)
            if (decryptData && decryptData.delearName) {
                const { feedbackId1, phone, linkSentDate, documentNo, parentSchemaName, customerName } = decryptData
                setCustomerName(customerName)
                const DeviceType = detectCurrentDevice() || ''
                const DeviceId = `${getUniqueIdFromBrowser()}_${DeviceType}_${visitorId}_${parentSchemaName}_${documentNo}`
                const obj = {
									MobileNo: phone,
									DeviceId,
									DeviceType,
									FeedbackID: feedbackId1,
									DocumentNo: documentNo,
									LinkSentDate: linkSentDate,
									ParentSchemaName: parentSchemaName,
									BU: "PB",
									ProductType: "Probiking",
									// "DocumentNo": "PBRJC12771202100079",
									// "MobileNo": "7798773577",
									// DeviceId,
									// "DeviceType": detectCurrentDevice() || '',
									// "FeedbackID": '705',
									// "LinkSentDate": "2021-08-25 12:22:10",
								};

                const validLinkData = await checkNPSLinkIsValid(obj, KTM)
                const { isNPSValidDevice, npsLinkIsValidResponse, Message, DeviceMessage, IsAlreadySubmitted } = validLinkData?.Data || {}
                if (!validLinkData || !validLinkData?.IsRequestSuccessfull) {
                    setIsFetchApiCalled(false)
                    setIsLinkValid(false)
                    setErrorMessage(SOMETHING_WENT_WRONG)
                    logErrorHandler(SOMETHING_WENT_WRONG)
                } else if (isNPSValidDevice === true && npsLinkIsValidResponse?.IsLinkValid === true) {
                    getQuestionsData(Number(feedbackId1))
                    setIsLinkValid(true)
                    setErrorMessage('')
                } else if (IsAlreadySubmitted === true) {
                    setErrorMessage(Message)
                    setIsAlreadySubmitted(true)
                    setIsLinkValid(false)
                    logErrorHandler(Message)
                } else if (npsLinkIsValidResponse?.IsLinkValid === false) {
                    setErrorMessage(Message)
                    setIsLinkValid(false)
                    logErrorHandler(Message)
                } else if (isNPSValidDevice === false) {
                    setErrorMessage(DeviceMessage)
                    setIsLinkValid(false)
                    logErrorHandler(DeviceMessage)
                } else {
                    setIsLinkValid(false)
                    setErrorMessage(SOMETHING_WENT_WRONG)
                    logErrorHandler(SOMETHING_WENT_WRONG)
                }
            }
        }
        fetchMyAPI()
    }, [])

    const getQuestionsData = async (LegacyId) => {
        if (!LegacyId) return
        setCurrentRating(null)
        window.scrollTo({ top: 0 });
        setIsFetchApiCalled(true)
        logLanguageClick(AppName.MC, LegacyId)
        const questionsArray = await fetchQuestionsData({ LegacyId: Number(LegacyId) }, KTM)
        if (!questionsArray || !questionsArray.result) {
            setErrorMessage(SOMETHING_WENT_WRONG)
            logErrorHandler(SOMETHING_WENT_WRONG)
            setIsFetchApiCalled(false)
        } else {
            setQuestionsData(questionsArray)
            setIsFetchApiCalled(false)
        }
    }

    const onRatingClick = (ratingValue) => {
        logRatingHandler(AppName.MC, cryptoData?.parentSchemaName, ratingValue)
        setCurrentRating(ratingValue)
    }

    const showPage = isLinkValid === true && (questionsData && questionsData.result)
    const showLoader = isLinkValid === null && questionsData === null || isFetchApiCalled

    if (showThankyouScreen)
        return (
            <ThankComponent
                title='Get the KTM App'
                color='#fff'
                borderColor='#ff6600'
                // logo={ktmLogoThanksScreen}
                logo={ktmLogo}
                huskarnaLogo={huskarnaLogo}
                parentSchemaName= {cryptoData?.parentSchemaName}
                isKTM ={true}
                className='thanksContainerKTM'
                appName={AppName.KTM}
                link={KTM_APP_LINK}
                msg={questionsData && questionsData.result && questionsData.result.ThankYouMsg}
            />
        )

    return (
        <div
            className='mainServiceContainer'
            id='mainServiceContainerId'
        >
            <MCHeader
                delearName={cryptoData?.delearName}
                location={cryptoData?.location}
                isKTM={true}
                // logo={ktmLogo}
                logo={ktmLogo}
                huskarnaLogo={huskarnaLogo}
            />
            {showLoader ? <AjaxLoader fullScreen /> : null}
            {
                (isLinkValid === false || isAlreadySubmitted === true) ?
                    <LinkInvalid
                        message={errorMessage} //"“Feeback is already submitted”"
                        isKTM
                        isLinkExpire={isAlreadySubmitted === true ? false : true}
                        inValidLinkImage={FeedbackExpiredKTMNew}
                        alreadySubmittedImage='feedbackSubmitImageKTM'
                    />
                    :
                    null
            }

            {showPage &&
                <>
                    <StarRating
                        questionsData={questionsData}
                        cryptoData={cryptoData}
                        customerName={customerName}
                        getQuestionsData={getQuestionsData}
                        setCurrentRating={onRatingClick}
                        currentRating={currentRating}
                        isKTM={true}
                    />
                    <div id='questionsMainContainer'>
                        {
                            currentRating !== null ?
                                <DriversQuestionsData
                                    questionsData={questionsData && questionsData.result}
                                    onSubmitButtonClick={onSubmitButtonClick}
                                    currentRating={currentRating}
                                    appName={AppName.KTM}
                                    isKTM={true}
                                    isPromoters={currentRating > 6 ? true : false}
                                    parentSchemaName={cryptoData?.parentSchemaName || ''}
                                /> : null
                        }
                    </div>
                </>
            }
        </div>
    )
}

function MainService() {
    return <KTMMainContainer />
}

export default withAppInsights(MainService)
