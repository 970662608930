import {createReducer} from "../../../../reducers/createReducer";
import {NLPConfigConstants} from "./NLPConfigConstants";

const initialState = {
    activeView : 1,
    selectedApp: {},
    schemaId: "01ef92d7-9671-04a7-7ddb-0ddf34ce0d53",
    documentId: "df90b40b-b1ce-48a6-83c4-2bffa38d1aac",
    appData: {},
    selectedIntent: {},
    entities: []
};

/*
  activeView:
    1- TableView
    2- EditView
 */

export const nlpConfigReducer = createReducer(initialState, {
    [NLPConfigConstants.UI_SET_NLP_ACTIVE_VIEW]: setActiveView,
    [NLPConfigConstants.UI_SET_SELECTED_APP]: setSelectedApp,
    [NLPConfigConstants.UI_SET_NLP_SCHEMA_ID]: setNLPSchemaId,
    [NLPConfigConstants.UI_SET_NLP_APP_DATA]: setNLPAppData,
    [NLPConfigConstants.UI_SET_NLP_SELECTED_INTENT]: setNLPSelectedIntent,
    [NLPConfigConstants.UI_UPDATE_INTENT_NAME]: updateNLPName,
    [NLPConfigConstants.UI_SET_ENTITY_DATA]: setEntityData
});

function setActiveView(state, action) {
    return {
        ...state,
        activeView: action.payload
    }
}

function setSelectedApp(state, action){
    return{
        ...state,
        selectedApp: action.payload
    }
}

function setNLPSchemaId(state, action) {
    return{
        ...state,
        schemaId: action.payload
    }
}

function setNLPAppData(state, action) {
    return {
        ...state,
        appData: action.payload
    }
}

function setNLPSelectedIntent(state, action){
    return {
        ...state,
        selectedIntent: action.payload
    }
}

function updateNLPName(state, action) {
    return {
        ...state,
        selectedIntent: {
            ...state.selectedIntent,
            name: action.payload
        }
    }
}

function setEntityData(state, action){
    return {
        ...state,
        entities: action.payload
    }
}