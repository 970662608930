import React from "react";

import imgsuccessIcon from "../../../assets/images/successIcon.svg";

import MCHeader from "./MCHeader";

const MCFeedbackGiven = ({ message }) => {
  return (

    <div style={{ position: "relative",height:"92vh" }}>
      <MCHeader />
      <div className="mainContainer" style={{ position: "relative" }}>
            
            <div className="middleRate">
              <img src={imgsuccessIcon} width={80} />
            </div>

            <div className="thanksTxtSmall">{message}</div>
          </div>
    </div>
    
  );
};

export default MCFeedbackGiven;
