import React, { Component, Fragment } from 'react';
import '../../designer.css';
import AssestService from "../../Services/assestService";
import { LoadPanel } from 'devextreme-react/ui/load-panel';
import {withRouter} from 'react-router-dom';
import queryString from 'querystring';
import {Form, List} from "devextreme-react";
import {generateLayout} from "../../Services/controlClasses";
import cardImg from "./../../.././../assets/img/bokeh-cover-bg.jpg";
import httpService from '../../Services/httpService';
import  { imageCards } from "../../Services/appenums";
import HtmlReactParser from "html-react-parser";
import loaderIcon from '../../../../assets/img/preview-loader.svg';

class ExternalPreview  extends Component {
    constructor(){
        super();

        this.state={
            itemData: [],
            loading: true,
            jsonData : null,
            companyData :  null,
            cards: [],
            modules : [],
            height : 1,
            showSeacrch : true,
            filteredData : null
        };
        this.valueChanged = this.valueChanged.bind(this);
        this.imageCards = imageCards;
    }

    async componentDidMount() {
        let {SchemaId, DocumentId, Search, ParentId} = queryString.parse(this.props.location.search.substr(1));
        let cards = [];
        let _requestModules = [];


        if (Search){
            // let modules = this.GetModules();
            // if(!modules){
                const response = await AssestService.getExternalPreviewModules(SchemaId, ParentId);
                const modules = response.data.Data;
            // }

            this.setState({modules : modules, loading : false, showSeacrch : true});

            // const _StoreCards = this.GetCards();
            // if(_StoreCards){
            //     modules.forEach((module) => {
            //         let _cards = _StoreCards.filter(o => o.ModuleId === module.DocumentId);
            //         if(_cards) 
            //             _cards.forEach(card => cards.push(card));
            //         else 
            //             _requestModules.push(module);
            //     })
            // }else {
            //     _requestModules = modules;
            // }

            // if(_requestModules.length > 0){
            //     const moduleCards = await AssestService.getCardByModules(SchemaId, _requestModules);
            //     moduleCards.forEach((module) => {
            //         let DocumentId = module.config.headers.DocumentId;
            //         let _module = modules.find(o => o.DocumentId === DocumentId);
            //         module.data.Data.forEach((card) => {
            //             cards.push({ModuleName : _module.DisplayName, ModuleId: _module.DocumentId ,...card})
            //         })
            //     })
            // }

            // this.SetModulesAndCards(modules, cards);
            //this.setState({cards : cards, loading : false, showSeacrch : true});
        }else{
            this.setState({showSeacrch : false });
            this.SetFormLayout(DocumentId);
        }
    }


    async SetFormLayout(DocumentId){
        this.setState({loading : true});
        let isImageExists = false;
        
        let { SchemaId } = queryString.parse(this.props.location.search.substr(1));

        const layoutResponse = await AssestService.getModuleDetails(SchemaId, DocumentId);
        let longDescrption = layoutResponse.data.LongDescription;

        this.setState({ longDescrption , loading : false });

        // const assestResponse = await AssestService.getAssests(SchemaId, DocumentId);

        // let formData = {};
        // let layoutItems =[];
        // let cardsData = assestResponse.data.Data;
    
        // const cardsLayout = JSON.parse(layoutResponse.data.Layout);
    
        // cardsLayout.forEach(element => {
        //     let dataField = element.cardId.replace(/[-]/g,"");
        //     let card = cardsData.find(o => o.id === element.cardId);
        //     if(card){
        //         if(this.imageCards.indexOf(card.TemplateId) !== -1) 
        //         {
        //             isImageExists = true;
        //             card.Image = cardImg;
        //         }
        //         card.preview = true;
        //         layoutItems.push({ TemplateId:element.editorOptions.elementAttr, DocumentId : element.cardId, id : element.cardId })
        //         formData[dataField] = card;
        //     }
        // });
    
        // let newLayout = generateLayout(layoutItems,false);
        // this.setState({ jsonData : newLayout,companyData : formData });
        // if(isImageExists)
        //     this.setCardImages();
        // else 
        //     this.setState({loading : false});
    }

    getLayout() {
        const { itemData } = this.state;
        return generateLayout(itemData);
    }

    async setCardImages(){
        var that =this;
        let { SchemaId } = queryString.parse(this.props.location.search.substr(1));
        let cards = {...this.state.companyData};
        Object.keys(cards).forEach((key)=>{
            if(this.imageCards.indexOf(cards[key].TemplateId) !== -1) {
                AssestService.getCardImage(SchemaId,cards[key])
                .then((response) => {
                    if(response){
                        cards[key].Image = "data:image/jpeg;base64," + response;
                        this.setState({loading : true});
                        setTimeout(() => {  
                            that.setState({companyData : cards, loading : false});
                        }, 5);
                    }else{
                        that.setState({loading : false});     
                    }
                })
                .catch((error) => {
                    that.setState({loading : false});    
                })
            }
        });
    }

    ItemTemplate(data) {
        return <div>{data.DisplayName}</div>;
    }

    listSearchHandler = (e) => {
        const longDescrption = e.itemData.LongDescription;
        this.setState({showSeacrch : false , longDescrption});
        // const DocumentId = e.itemData.ParentId;
        // this.setState({showSeacrch : false});
        // this.SetFormLayout(DocumentId);
    }

     

    SetModulesAndCards(modules, cards){
        // localStorage.setItem("modules", JSON.stringify(modules));
        // localStorage.setItem("cards",JSON.stringify(cards))
    }

    GetModules(){
        return null;
        // const modules = localStorage.getItem("modules");
        // return modules ? JSON.parse(modules) : null;
    }

    GetCards(){
        return null;
        // const cards = localStorage.getItem("cards");
        // return cards ? JSON.parse(cards) : null;
    }

     valueChanged(data) {
         let _filterData = [];
         let height = 1;
         let filter = data.value;
         if(filter.length === 0){
             height = 1;
             this.setState({height : 1, searchValue : filter});
         }else {
            const lowercasedFilter = filter.toLowerCase();
            let cards = [...this.state.modules];
            const filteredData = cards.filter(item => {
                return Object.keys(item).some(key =>
                    typeof item[key] === 'string' && item[key].toLowerCase().includes(lowercasedFilter)
                );
            });
            filteredData.forEach(e => {
                let module = filteredData.find(o => o.DocumentId === e.DocumentId);
                if(_filterData.length == 0){
                    _filterData.push(module);
                }else{
                    let _module = _filterData.find(o => o.DocumentId === e.DocumentId);
                    if(!_module) _filterData.push(module);
                }
            })
            this.setState({filteredData : _filterData, height : 300, searchValue : filter});
         }
         
      }

    render() {
        let {loading , showSeacrch, searchValue, longDescrption, filteredData, height} = this.state;

        if(loading) return <LoadPanel indicatorSrc={loaderIcon} visible={true} shadingColor="rgba(0,0,0,0.1)"/>;

        const visibleForm = showSeacrch ? 'none' : 'block';
        
        return (
            <Fragment>
                <div className="d-flex externalPreview">
                    <div className="artBoardmain artBoardmainMobile">
                        <div className="rightPan" style={{ height : '100vh' }}>
                            {/* <div className="header">
                                <div className="IcoHeader">
                                    <i className="fa fa-angle-left" aria-hidden="true" />
                                    <i className="fa fa-bars lbar" aria-hidden="true" />
                                    <i className="fa fa-search" aria-hidden="true" />
                                </div>
                            </div> */}
                            <List
                                dataSource={filteredData}
                                height={height}
                                itemRender={this.ItemTemplate}
                                // searchExpr={'DisplayName'}
                                searchEnabled={true}
                                onItemClick={this.listSearchHandler}
                                searchEditorOptions={
                                     {
                                         onValueChanged : this.valueChanged,
                                         value : searchValue
                                     }
                                }
                                visible = {showSeacrch}
                            />
                            <div 
                                style ={{display : visibleForm}}  
                                className="content scrollbar scrollbarEx" id="style-1">
                                <div className="force-overflow" style={{ width: "100%" }}>
                                    {/* <Form
                                        scrollingEnabled={true}
                                        formData={this.state.companyData}
                                        items={this.state.jsonData}
                                    /> */}
                                   {longDescrption ? HtmlReactParser(longDescrption) : null }
                                </div>
                            </div>
                            <div style={{ clear: "both" }} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(ExternalPreview);
