import {createReducer} from "../../reducers/createReducer";
import {ReportConstant} from "./reportConstant";

const initialState = {
    tabIndex: -1,
    dateType: '',
    fromDate: '',
    toDate: '',
    distributor: '',
    country: '',
    dateAutoFilter: null,
    data: {},
    viewIndex: 0,
    navigationSchemaId: '',
    navigationViewId: ''
};

export const reportReducer = createReducer(initialState, {
    [ReportConstant.UI_SET_REPORT_TAB_INDEX]: setTabIndex,
    [ReportConstant.UI_SET_DATE_TYPE]: setDateType,
    [ReportConstant.UI_SET_DATE_FILTER]: setDateFilter,
    [ReportConstant.UI_SET_FROM_DATE]: setFromDate,
    [ReportConstant.UI_SET_TO_DATE]: setToDate,
    [ReportConstant.UI_SET_DISTRIBUTOR]: setDistributor,
    [ReportConstant.UI_SET_COUNTRY]: setCountry,
    [ReportConstant.UI_SET_DATA]: setData,
    [ReportConstant.UI_SHOW_VIEW]: showView,
    [ReportConstant.UI_SET_NAVIGATION]: setNavigation
});

function setTabIndex(state, action){
    return {
        ...state,
        tabIndex: action.payload
    }
}

function setDateType(state, action){
    return {
        ...state,
        dateType: action.payload
    }
}

function setDateFilter(state, action) {
    return {
        ...state,
        dateAutoFilter: action.payload
    }
}

function setFromDate(state, action) {
    return {
        ...state,
        fromDate: action.payload
    }
}

function setToDate(state, action) {
    return {
        ...state,
        toDate: action.payload
    }
}

function setDistributor(state, action) {
    return {
        ...state,
        distributor: action.payload
    }
}

function setCountry(state, action) {
    return {
        ...state,
        country: action.payload
    }
}

function setData(state, action){
    return {
        ...state,
        data: action.payload
    }
}

function showView(state, action){
    return {
        ...state,
        viewIndex: action.payload
    }
}

function setNavigation(state, action){
    return {
        ...state,
        navigationSchemaId: action.payload.schemaId,
        navigationViewId: action.payload.viewId
    }
}