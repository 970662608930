import { commonConstants } from "./commonConstants";

export function setShowChangePassword(value) {
    return {
        type: commonConstants.UI_SET_SHOW_CHANGE_PASSWORD,
        payload: value
    }
}

export function setSelectedNavigationItem(value) {
    return {
        type: commonConstants.UI_SET_SELECTED_NAVIGATION_ITEM,
        payload: value
    }
}

export function setLoginActiveTab(value) {
    return {
        type: commonConstants.UI_SET_LOGIN_ACTIVE_TAB,
        payload: value
    }
}

export function setHomeMetaData(value) {
    return {
        type: commonConstants.UI_SET_HOME_METADATA,
        payload: value
    }
}

export function setViewName(value) {
    return {
        type: commonConstants.UI_SET_VIEW_NAME,
        payload: value
    }
}

export function toggleNavigationWidth() {
    return {
        type: commonConstants.UI_TOGGLE_NAVIGATION_WIDTH
    }
}

export function setSelectedDocumentView(value) {
    return {
        type: commonConstants.UI_SET_SELECTED_DOCUMENT_VIEW,
        payload: value
    }
}

export function toggleSubNavigationVisibility() {
    return {
        type: commonConstants.UI_TOGGLE_SUB_NAVIGATION_VISIBILITY
    }
}

export function setSelectedNonBusinessItem(value) {
    return {
        type: commonConstants.UI_SET_SELECTED_NON_BUSINESS_ITEM,
        payload: value
    }
}

export function setSelectedSubNavigationItem(value) {
    return {
        type: commonConstants.UI_SET_SELECTED_SUB_NAVIGATION_ITEM,
        payload: value
    }
}

export function setSelectedSchema(value) {
    return {
        type: commonConstants.UI_SET_SELECTED_SCHEMA,
        payload: value
    }
}

export function setSelectedFilter(value) {
    console.log("setSelectedFilterAction", value);
    return {
        type: commonConstants.UI_SET_SELECTED_FILTER,
        payload: value
    }
}

export function showExpandedMenuOnClose(value) {
    return {
        type: commonConstants.UI_SET_SHOW_EXPANDED_MENU_ON_CLOSE,
        payload: value
    }
}

export function setAllSchemaVisibility(value) {
    return {
        type: commonConstants.UI_SET_ALL_SCHEMA_VISIBILITY,
        payload: value
    }
}

export function setSelectedDocument(value) {
    return {
        type: commonConstants.UI_SET_SELECTED_DOCUMENT,
        payload: value
    }
}

export const showCustomForm = (show, formOptions = {}, cellInfo = {}, component = {}, rowIndex = 0, customItems = []) => ({
    type: commonConstants.UI_SHOW_CUSTOM_FORM,
    payload: { show, cellInfo, formOptions, component, rowIndex, customItems }
});

export const setActiveTabName = (viewName) => ({
    type: commonConstants.UI_SET_ACTIVE_TAB_NAME,
    payload: viewName
});

export const SetSubscriptionData = (subscription, schemaMetaData = []) => ({
    type: commonConstants.UI_SET_SUBSCRIPTION_DATA,
    payload: { subscription, schemaMetaData }
});

export const setCurrentGridData = (data) => ({
    type: commonConstants.UI_SET_CURRENT_GRID_DATA,
    payload: data
});

export const setIsLoading = (flag) => ({
    type: commonConstants.UI_SET_IS_LOADING,
    payload: flag
});

export const setIsExporting = (flag) => ({
    type: commonConstants.UI_SET_IS_EXPORTING,
    payload: flag
});

export const setHoverOnCollapsed = (flag) => ({
    type: commonConstants.UI_SET_HOVER_ON_COLLAPSED,
    payload: flag
});

export const setSucessMessage = (value) => ({
    type: commonConstants.UI_SET_SUCESSS_MESSAGE,
    payload: value
});

export const setPopupFormOptions = (options) => ({
    type: commonConstants.UI_SET_POPUP_FORM_OPTIONS,
    payload: options
});

export const updateGridData = (data) => ({
    type: commonConstants.UI_UPDATE_GRID_DATA,
    payload: data
});

export const updateDocumentSpecifics = (document) => ({
    type: commonConstants.UI_UPDATE_DOCUMENT_SPECIFICS,
    payload: document
});

export const setActiveProgressTab = (activeTab) => ({
    type: commonConstants.SET_ACTIVE_PROGRESS_TAB,
    payload: activeTab
});

export const disableImportButton = (flag) => ({
    type: commonConstants.DISABLE_IMPORT_BUTTON,
    payload: flag
});

export const setGoToBackItem = (gotoBack, prevData = {}) => ({
    type: commonConstants.UI_SET_GO_TO_BACK,
    payload: { gotoBack, prevData }
});

export const setActiveViewDevConsole = (value) => ({
    type: commonConstants.UI_SET_ACTIVE_VIEW_DEV_CONSOLE,
    payload: value
});

export const setShowSearchOptions = (showSearchBar, documentId, viewDocumentId) => ({
    type: commonConstants.UI_SET_SHOW_SEARCH_OPTION,
    payload: { showSearchBar, documentId, viewDocumentId }
});

export const setSelectedSearchData = (search, clearSearch) => ({
    type: commonConstants.UI_SET_SELECTED_SEARCH_DATA,
    payload: { search, clearSearch }
});

export const setSelectedDashBoard = (dashBoard) => ({
    type: commonConstants.UI_SET_SELECTED_DASHBOARD_VIEW,
    payload: dashBoard
});

export const setIsFormLoading = (flag) => ({
    type: commonConstants.UI_SET_IS_FORM_LOADING,
    payload: flag
});

export const setGraphFilterString = (value) => ({
    type: commonConstants.UI_SET_GRAPH_FILTER_STRING,
    payload: value
});

export const setHomeChargingImageCount = (value) => ({
    type: commonConstants.UI_SET_HOMECHARGING_IMAGE_COUNT,
    payload: value,
});

export const setUploadedImageCount = (value) => ({
    type: commonConstants.UI_UPLOADED_IMAGE_COUNT,
    payload: value,
});


