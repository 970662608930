import React from "react";
import ThumbUpThanksNew from '../../../assets/images/ThumbUpThanksNew.svg'

export const MCLinkInvalid = ({ message, isLinkExpire, inValidLinkImage, alreadySubmittedImage }) => {
    const height = window.screen.height - (window.innerWidth >= 450 ? 280 : 200)
    return (
        <div className="invalidLinkMainContainer">
            {
                isLinkExpire ?
                    <div style={{ marginTop: '97px' }} className={'expireContainer'} >
                        <img src={inValidLinkImage} width={50} height={50} />
                        <div className="linkInvalidText">{message}</div>
                    </div>
                    :
                    <>
                        <div className='expireContainer' >
                            <img src={ThumbUpThanksNew} width={50} height={50} />
                            <div className="linkInvalidText">{message}</div>
                        </div>
                        <div style={{
                            height,
                            minHeight: 0,
                            backgroundSize: window.innerWidth >= 450 ? '100% 100%' : '100% 85%'
                        }}
                            className={alreadySubmittedImage}
                        />
                    </>
            }
        </div>
    );
};

export default MCLinkInvalid;
