import http from "./httpService";
import {Utility} from "../../../common/utility";
import { AlertToast } from "../../../common/AlertToast";
import { AlertPopup } from "./../../../common/AlertPopup";

const authKey = "authKey";

const urls = {
    documentview : 'documentview',
    document : 'document',
    resource : 'resource',
    attachmentbinary : 'attachmentbinary'
  }

export async function getAssests(schemaId, parentId) {
    http.setAcessToken(Utility.getAuthenticationToken());
    return http.get(`${global.config.baseApiUrl}/${urls.documentview}/${schemaId}/GetChildAssets?parentid=${parentId}&take=1000`);
}

export async function updateCard(schemaId, cardId, data) {
    http.setAcessToken(Utility.getAuthenticationToken());
    return http.put(`${global.config.baseApiUrl}/${urls.document}/${schemaId}/${cardId}`, data);
}

export async function updateLayout(schemaId, moduleId, data) {
    http.setAcessToken(Utility.getAuthenticationToken());
    return await http.put(`${global.config.baseApiUrl}/${urls.document}/${schemaId}/${moduleId}` , data);
}

export async function saveCards(schemaId, moduleId, cards, layout) {
    http.setAcessToken(Utility.getAuthenticationToken());

    let cardsEndpoints = [];
    cards.forEach(element => {
        if (element.ImageFile) {
          http.setResourceHeaders(element);
          var formData = new FormData();
          formData.append('data' , element.ImageFile);
          cardsEndpoints.push(
            http.post(`${global.config.baseApiUrl}/${urls.resource}/${schemaId}/${element.id}` , formData)
          )
        }
    
        cardsEndpoints.push(
          http.put(`${global.config.baseApiUrl}/${urls.document}/${schemaId}/${element.id}`, element)
        );
      });

    http.all(cardsEndpoints).then((response) => {
        console.log(response);
        saveLayout(schemaId, moduleId, layout)
    });

}



function saveLayout(schemaId, moduleId, layout){
    const data = {"Layout" : layout};
    updateLayout(schemaId, moduleId, data).then((response) => {
        if(!response.data.IsRequestSuccessfull){
            AlertPopup.showPopup("error", response.data.Message);
        }
        else{
            AlertToast.success("Layout Created.");
        }
    }).catch((error) => {
        AlertPopup.showPopup("error", error);
    });
}


export async function getModuleDetails(schemaId, moduleId){
    http.setAcessToken(Utility.getAuthenticationToken());
    return http.get(`${global.config.baseApiUrl}/${urls.document}/${schemaId}/${moduleId}?take=1000`);
}

export async function getAttachmentBinary(schemaId, element){
    http.setAcessToken(Utility.getAuthenticationToken());
    return http.get(`${global.config.baseApiUrl}/${urls.attachmentbinary}/${schemaId}/${element.id}/${element.SystemName}`)
  }

export async function getCardImage(schemaId, element){
    http.setAcessToken(Utility.getAuthenticationToken());
        return http.get(`${global.config.baseApiUrl}/${urls.resource}/${schemaId}/${element.id}/${element.SystemName}`,
                {responseType : 'arraybuffer'})
                .then(
                    (response) => 
                        Buffer.from(response.data,'binary').toString('base64')
                    )
                .catch(error => {
                    console.log(error);
                    return null;
                })
}

export async function createNewCards(schemaId, cards){
    let createCardsEndpoints = [];
    http.setAcessToken(Utility.getAuthenticationToken());
    cards.forEach(card => {
      let data = card;
      createCardsEndpoints.push(http.post(`${global.config.baseApiUrl}/${urls.document}/${schemaId}/`,data))
    })
    return http.all(createCardsEndpoints);
  }
  
  export async function deleteCard(schemaId, documentId){
    http.setAcessToken(Utility.getAuthenticationToken());
    return http.delete(`${global.config.baseApiUrl}/${urls.document}/${schemaId}/${documentId}`);
  }

  export async function getExternalPreviewModules(schemaId, parentId, displayName){
        http.setAcessToken(Utility.getAuthenticationToken());
        http.setDisplayName(displayName, parentId);
        //return http.get(`${global.config.baseApiUrl}/${urls.documentview}/${schemaId}/AssetResources?ParentId='/${parentId}`);
        return http.get(global.config.baseApiUrl + '/' + urls.documentview + "/" + schemaId + "/" + "AssetResources?ParentId=" + parentId);
  }

  export async function getData(schemaId, viewId){
        http.setAcessToken(Utility.getAuthenticationToken());
        return http.get(global.config.baseApiUrl + '/searchdocumentview/' + schemaId + "/" + viewId);
  }

  export async function getCardByModules(schemaId, modules){
        let endPoints = [];
        modules.forEach((module) =>{
            endPoints.push(getExternalPreviewModules(schemaId, module.DocumentId, module.DisplayName))
        })

        return http.all(endPoints);
  }



export default {
    getAssests,
    updateCard,
    saveCards,
    getModuleDetails,
    getAttachmentBinary,
    getCardImage,
    createNewCards,
    deleteCard,
    getExternalPreviewModules,
    getData,
    getCardByModules
};
