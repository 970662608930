import {designerConstants} from "./DesignerConstants";
import {createReducer} from "../../reducers/createReducer";

const initialState = {
    activeView: 1,
    selectedDocument: {},
    showPreview: false,
    usedCards: [],
    unusedCards: []
};

export const designerReducer = createReducer(initialState, {
    [designerConstants.UI_SET_DESIGNER_ACTIVE_VIEW]: setActiveView,
    [designerConstants.UI_SET_DESIGNER_SELECTED_DOCUMENT]: setSelectedDocument,
    [designerConstants.UI_SET_DESIGNER_SHOW_PREVIEW]: setShowPreview,
    [designerConstants.UI_SET_DESIGNER_USED_CARDS]: setUsedCards,
    [designerConstants.UI_SET_DESIGNER_UNUSED_CARDS]: setUnUsedCards
});

function setActiveView(state, action){
    return {
        ...state,
        activeView: action.payload
    }
}

function setSelectedDocument(state, action){
    return {
        ...state,
        selectedDocument: action.payload
    }
}

function setShowPreview(state, action){
    return {
        ...state,
        showPreview: action.payload
    }
}

function setUnUsedCards(state, action){
    return {
        ...state,
        usedCards: action.payload
    }
}

function setUsedCards(state, action){
    return {
        ...state,
        usedCards: action.payload
    }
}