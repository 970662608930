import React from "react";

import imgArrow from "../../../assets/images/arrow.svg";

import imgArrowBack from "../../../assets/images/arrowBack.svg";

import Switch from "react-switch";
import { Redirect } from "react-router-dom";
import FinanceInteract from "./MCFinanceInteract";
import MCHeader from "./MCHeader";
import { AppName, ActionType, PageName, FeedbackType, logSubmitHandler, logErrorHandler, logRatingHandler,logQuestionHandler, logFreeTextHandler } from '../services/AppInsightLog';
//const SuggestionsComponent = ({ stars, gotoNext, gotoBack, handleChange, checked, onRatingFinanceClick }) =>
export class SuggestionsComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            rating: null,
            financeChecked: false,
            salesChecked: true,
            ratingQuestion: null,
            question: null,
            IsFinanceOther: false,
            financeComment: "",
            showPopup: false,
            enableNext: false,
        };
        this.onRatingFinanceClick = this.onRatingFinanceClick.bind(this);
        this.onFinanceAnswerSelect = this.onFinanceAnswerSelect.bind(this);
        this.onFinanceCommentChangeTemp = this.onFinanceCommentChangeTemp.bind(this);
        this.onSalesSwitchChange = this.onSalesSwitchChange.bind(this);
        this.onFinalCommentChangeHandler = this.onFinalCommentChangeHandler.bind(this);
    }

    componentDidMount() {
        const { tempFinanceData } = this.props;
        if (tempFinanceData) {
            const selectedlen = tempFinanceData.answers.FeebackAnswers.filter(x => x.IsSelected).length;
            this.setState({
                financeComments: tempFinanceData.financeComments,
                rating: tempFinanceData.fininceRate,
                question: tempFinanceData.answers,
                salesChecked: tempFinanceData.isSaleCheck,
                financeChecked: true,
                enableNext: selectedlen > 0,
                IsFinanceOther : tempFinanceData.IsFinanceOther
            });
        } else {
            this.getFinanceData();
        }
    }
    getFinanceData = () => {
        const { financeQuestions } = this.props;
        const ratingQuestion = financeQuestions.find(
            o => o.FrontEndCode === "RatingQuestion"
        );
        const question = financeQuestions.find(o => o.FrontEndCode === "Promotors");
        console.log(question);
        const feedbackAnswers = question.FeebackAnswers.sort((a,b) => a.DisplayIndex - b.DisplayIndex)
        question.FeebackAnswers = feedbackAnswers;
        question.FeebackAnswers.map((a, index) => {
            a.IsSelected = false;
            a.answerImages = this.getAnswerImage(a.DisplayIndex);
        });
        this.setState({ ratingQuestion, question });
    }
    onFinanceSwitchChange = checked => {
        this.setState({ financeChecked: checked, rating: null });
        this.getFinanceData();
    };
    onSalesSwitchChange = checked => {
        const { onSalesSwitchChange } = this.props;
        this.setState({ salesChecked: checked });
        if (onSalesSwitchChange) onSalesSwitchChange(checked);
    };
    onRatingFinanceClick = item => {
        const question = this.getQuestion(item);
        this.setState({ question, rating: item, IsFinanceOther: false });
        logRatingHandler(this.props.appInsights, AppName.MC,FeedbackType.Finance,item)
    };
    onFinanceAnswerSelect = (item) => {
        const answers = this.state.question.FeebackAnswers;
        const index = answers.findIndex(a => a.id === item.id);
        answers[index].IsSelected = !answers[index].IsSelected;
        if (answers.length === index + 1) {
            answers[index].IsOthersRow = true;
            this.setState({
                IsFinanceOther: !this.state.IsFinanceOther,
                showPopup: answers[index].IsSelected ? true : false
            });
        }
        const selectedlen = answers.filter(x => x.IsSelected).length;
        this.setState([...this.state.question.FeebackAnswers, answers]);
        this.setState({ enableNext: selectedlen > 0 });
        this.logQuestionEvent(answers[index]);
    }

    logQuestionEvent(Answer) {
        const { question  } = this.state;
        logQuestionHandler(this.props.appInsights, 
          AppName.MC,
          FeedbackType.Finance,
          question.DisplayName,
          Answer.DisplayHeader,
          Answer.DisplayDescription,
          Answer.IsSelected
          )
      }
    getQuestion = item => {
        const { financeQuestions } = this.props;
        let answers = [];
        if (item >= 0 && item <= 6)
            answers = financeQuestions.find(x => x.FrontEndCode === "Detractors");
        else if (item >= 7 && item <= 8)
            answers = financeQuestions.find(x => x.FrontEndCode === "Promotors");
        else if (item >= 9 && item <= 10)
            answers = financeQuestions.find(x => x.FrontEndCode === "Promotors");
        const feedbackAnswers = answers.FeebackAnswers.sort((a,b) => a.DisplayIndex - b.DisplayIndex)
        answers.FeebackAnswers = feedbackAnswers;
        answers.FeebackAnswers.map((a, index) => {
            a.IsSelected = false;
            a.answerImages = this.getAnswerImage(a.DisplayIndex);
        });
        return answers;
    };

    getAnswerImage(index) {
        switch (index) {
            case 1:
                return {
                    image: require("../../../assets/images/financingIco.svg"),
                    imageSelected: require("../../../assets/images/financingIco_Selected.svg")
                };
            case 2:
                return {
                    image: require("../../../assets/images/financeIco.svg"),
                    imageSelected: require("../../../assets/images/financeIco_Selected.svg")
                };
            case 3:
                return {
                    image: require("../../../assets/images/financeTermIco.svg"),
                    imageSelected: require("../../../assets/images/financeTermIco_Selected.svg")
                };
            case 4:
                return {
                    image: require("../../../assets/images/loanIco.svg"),
                    imageSelected: require("../../../assets/images/loanIco_Selected.svg")
                }
            case 5:
                return {
                    image: require("../../../assets/images/othersIco.svg"),
                    imageSelected: require("../../../assets/images/othersIco_Selected.svg")
                }
            default:
                return {
                    image: require("../../../assets/images/othersIco.svg"),
                    imageSelected: require("../../../assets/images/othersIco_Selected.svg")
                }
        }
    }


    onFinanceCommentChangeTemp = (value) => {
        this.setState({ financeComment: value, showPopup: true });
    }
    gotoNext = () => {
        const data = {
            fininceComments: this.state.financeComment,
            fininceRate: this.state.rating,
            answers: this.state.question,
            isSaleCheck: this.state.salesChecked
        }
        if (this.state.financeChecked)
            this.props.gotoNext("tempsave", data);
        else
            this.props.gotoNext("save", data);
    }
    onHidePopup = () => {
        const { onHidePopup } = this.props;
        this.setState({ showPopup: false });
        onHidePopup();
    }
    onFinalCommentChangeHandler = (event) => {
        const { onFinalCommentChange } = this.props;
        event.preventDefault();
        this.setState({ showPopup: true });
        onFinalCommentChange(event.target.value);
    }
    onShownPopup = () => {
        const { onShownPopup } = this.props;
        this.setState({ showPopup: true });
        onShownPopup();

    }
    render() {
        const { financeChecked, salesChecked, ratingQuestion, rating, onFinalCommentChangeHandler } = this.state;
        const { gotoBack, onFinalCommentChange, finalComments, suggestion, ratingObj, delearName, location, remarks } = this.props;
        const btnText = financeChecked ? "NEXT" : "SEND FEEDBACK";
        const enableNext = (btnText === "NEXT" && this.state.enableNext && rating !== null) ? true :
            (btnText === "SEND FEEDBACK") ? true : false;

        return (
            <div className="main1">
                <MCHeader delearName={delearName} location={location} />
                <form>
                    <main className="mainContainer" style={{ flex: "1" }}>

                        <div className="middleMainSugg" style={{ position: "relative" }}>
                            <div>
                                <div style={{ float: "left", width: "80%" }}>
                                    {ratingQuestion && ratingQuestion.DisplayName ? ratingQuestion.DisplayName : null}
                                </div>
                                <div
                                    style={{ float: "right", width: "20%", textAlign: "right" }}
                                >
                                    <label>
                                        <Switch
                                            offColor="#b9b9b9"
                                            onColor="#459ddd"
                                            onChange={this.onFinanceSwitchChange}
                                            checked={financeChecked}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div style={{ clear: "both" }}></div>
                            {financeChecked ? (
                                <FinanceInteract
                                    onRatingFinanceClick={this.onRatingFinanceClick}
                                    stars={this.state.list}
                                    rating={this.state.rating}
                                    question={this.state.question}
                                    onFinanceAnswerSelect={this.onFinanceAnswerSelect}
                                    IsFinanceOther={this.state.IsFinanceOther}
                                    onFinanceCommentChange={this.onFinanceCommentChangeTemp}
                                    financeComments={this.state.financeComments}
                                    remarks={remarks}
                                    onHidePopup={this.onHidePopup}
                                    showPopup={this.state.showPopup}
                                    onShownPopup={this.onShownPopup}
                                />
                            ) :
                                <div>
                                    <div style={{ width: "98%", paddingTop: 20 }}>
                                        {suggestion && suggestion.DisplayName ? suggestion.DisplayName : null}
                                    </div>

                                    <div className="container">
                                        <div style={{ width: "100%", marginTop: 10 }}>
                                            <textarea
                                                onChange={(event) => this.onFinalCommentChangeHandler(event)}
                                                value={finalComments}
                                                onBlur={this.onHidePopup}
                                                onClick={this.onShownPopup}
                                                placeholder="Enter your suggestions"
                                                rows="8"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div style={{ height: 55 }}>
                                        <div style={{ float: "left", width: "75%" }}>
                                            {ratingObj && ratingObj.DisplayName ? ratingObj.DisplayName : null}
                                        </div>
                                        <div
                                            style={{ float: "right", width: "25%", textAlign: "right" }}
                                        >
                                            <label>
                                                <Switch
                                                    offColor="#b9b9b9"
                                                    onColor="#459ddd"
                                                    onChange={this.onSalesSwitchChange}
                                                    checked={salesChecked}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>}
                        </div>

                    </main>

                    {!this.state.showPopup ? <footer >
                        <div className="submitBtBack"
                            onClick={() => gotoBack()} >
                            <img
                                src={imgArrowBack}
                                width={25}
                                style={{ paddingRight: 10, marginLeft: -25 }}
                            />
                            BACK
                                </div>

                        <div
                            className={enableNext ? "submitBt" : "submitBt disableBt"}
                            style={{ position: "absolute", right: 0 }}
                            onClick={() => { this.gotoNext() }}
                        >
                            <img
                                src={imgArrow}
                                width={25}
                                style={{ paddingRight: 10, marginLeft: 25 }}
                            />
                            {btnText}
                        </div>
                    </footer> : null}
                </form>
            </div>
        );
    }
}

export default SuggestionsComponent;
