import React from "react";
import HtmlReacParser from "html-react-parser";
import editmy from '../../../../assets/img/edit.svg';
import deletemy from '../../../../assets/img/delete.svg';

var imageName = require('./../../../../assets/img/bokeh-cover-bg.jpg');
const CardHeader = props => {
    let showTool = props.showTool ? "display" : "none";
    let showPropIcon = props.showPropIcon ? "display" : "none";
    return (
        <div style={{ display: `${showTool}` }}>
            <div className="htopInn" id="titleInn">&nbsp;</div>
            <div className="editIcon">
                    <img src={editmy} style={{ display: `${showPropIcon}` }}
                        className="fa fa-clone"
                        onClick={e => props.onProperty(props.Data)}
                        aria-hidden="true"
                        title="Edit" alt="Edit" />
                    <img src={deletemy} className="fa fa-trash-o"
                        onClick={e => props.onCardDelete(props.Data)}
                        aria-hidden="true"
                        title="Delete" alt="Delete" />
            </div>
        </div>
    );
};

const withImage = props => {

    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Template title",
                Heading: "Template title",
                LongDescription:
                    "Some quick example text to build on the card title and make up the bulk of the card's content.",
                Image: imageName,
                Footer: ""
            };
    return (
        <div className="CardA">
            <CardHeader
                title="CARD WITH IMAGE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
            <div className="title">
                <i className="fa fa fa-fire" style={{ marginRight: "10px" }} />
                {Heading}
            </div>
            <div className="listBox">
                <div className="rtbox" />
                <div>
                    <img className="imgBox" src={Image} />
                </div>
                <div className="lineelips" style={{height: `${CardHeight}px`}}>
                    {HtmlReacParser(LongDescription)} 
                </div>
                <div style={{ clear: "both" }} />
            </div>
            {!preview ?  <div className="textDeco">Card with image</div> : null}
        </div>
    );
};

const withText = props => {
    
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Template title",
                Heading: "Template title",
                LongDescription:
                    "Some quick example text to build on the card title and make up the bulk of the card's content.",
                Image: imageName,
                Footer: ""
            };
    return (
        <div className="CardB">
            <CardHeader
                title="CARD WITHOUT IMAGE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
            <div className="title">
                <i className="fa fa fa-fire" style={{ marginRight: "10px" }} />
                {Heading}
            </div>
            <div className="listBox" style={{height: `${CardHeight}px`}}>
                <div className="lineelips">{HtmlReacParser(LongDescription)}</div>
                <div style={{ clear: "both" }} />
            </div>
            {!preview ?  <div className="textDeco">Card without image</div> : null}
        </div>
    );
};

const withCardWithinCard = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <div className="listBoxnoCurveA">
            <CardHeader
                    title="INFO CARD"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
            />
            <div className="listBoxnoCurve">
                <div className="title">
                    <i className="fa fa fa-fire" style={{ marginRight: "10px" }} />
                    {Heading}
                </div>
                <div className="inMarg">
                    {HtmlReacParser(LongDescription)}
                    <div className="title2">
                        <i className="fa fa fa-fire" style={{ marginRight: "10px" }} />
                        Title will be here
                    </div>
                    <div className="listBox3">
                        <div className="lineelips">
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const withTable = props => {
    const tBody = <table style={{'border':'0', 'width' : '100%'}}> 
                        <tbody> 
                            <tr className="box">
                                <td className="inBox"> 
                                    {/* <div className="ico">9</div> */}
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox"> 
                                    {/* <div className="ico">2</div> */}
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox">
                                    {/* <div className="ico">3</div> */}
                                    <div className="text">Combination Instrument</div>
                                </td>
                            </tr>
                       </tbody>
                  </table>

    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        tableData, 
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "",
                Image: imageName,
                Footer: "",
                tableData:null,
                preview: true
            };
    return (
        <div className="containerInnC">
            <CardHeader
                title="CARD WITH TABLE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
  
            <div style={{ display: "none" }}>
                <div className="htopInn" id="titleInn">
                    Table Type
                </div>
                
                <div className="editIcon">
                    <i className="fa fa-clone" aria-hidden="true" title="Edit" />
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash-o" aria-hidden="true" title="Delete" />
                </div>
            </div>
            {props.new ? tBody : preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody}
            {/* {preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody} */}
        </div>
    );
};


const withTableHeader = props => {
    const tBody = <table style={{'border':'0', 'width' : '100%'}}> 
                        <tbody> 
                            <tr className="box">
                                <td className="inBox"> 
                                    {/* <div className="ico">9</div> */}
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox"> 
                                    {/* <div className="ico">2</div> */}
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox">
                                    {/* <div className="ico">3</div> */}
                                    <div className="text">Combination Instrument</div>
                                </td>
                            </tr>
                       </tbody>
                  </table>

    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        tableData, 
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "",
                Image: imageName,
                Footer: "",
                tableData:null,
                preview: true
            };
    return (
        <div className="containerInnC">
            <CardHeader
                title="CARD WITH TABLE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
            <div style={{ display: "none" }}>
                <div className="htopInn" id="titleInn">
                    Table Type
                </div>
                
                <div className="editIcon">
                    <i className="fa fa-clone" aria-hidden="true" title="Edit" />
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash-o" aria-hidden="true" title="Delete" />
                </div>
            </div>
            <div className="title">{Heading}</div>
            {props.new ? tBody : preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody}
            {/* {preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody} */}
        </div>
    );
};


const withGrayBGFull = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            

            <div className="inFo">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox" style={{height: `${CardHeight}px`}}>
                    <div>
                        {HtmlReacParser(LongDescription)}
                    </div>
                </div>
                {!preview ?  <div className="textDeco">Card Information grey bg full</div> : null}
            </div>
        </React.Fragment>
    );
};

const withGrayBG = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="inFotwo">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox" style={{height: `${CardHeight}px`}}>
                    <div>{HtmlReacParser(LongDescription)}</div>
                </div>
                {!preview ?  <div className="textDeco">Card Information grey bg</div> : null}
            </div>

        </React.Fragment>
    );
};

const withHorzImage = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="usrDef">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox">
                    <img className="img" src={Image} />
                    <div className="listBoxText" style={{height: `${CardHeight}px`}}>
                        {HtmlReacParser(LongDescription)}
                    </div>
                </div>
                {!preview ?  <div className="textDeco">Card with horizontal image</div> : null}
            </div>
        </React.Fragment>
    );
};

const withRightImage = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>        
            <div className="usrDefTwo">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                    <div className="listBox">
                        <div className="img"  >
                            <img src={Image} />
                        </div>
                        <div style={{position:'relative',height: `${CardHeight}px`}}>
                            {HtmlReacParser(LongDescription)}
                        </div>
                    <div style={{clear:'both'}}></div>
                </div>
                {!preview ?  <div className="textDeco">Card with right image</div> : null}
            </div>

        </React.Fragment>
    );
};

const withLeftImage = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="usrDefThree">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox">
                    <div className="img">
                        <img src={Image} />
                    </div>
                    <div style={{position:'relative',height: `${CardHeight}px`}}>
                        {HtmlReacParser(LongDescription)}
                    </div>
                    <div style={{clear:'both'}}></div>
                 </div>
                 {!preview ?  <div className="textDeco">Card with left image</div> : null}
            </div>
      </React.Fragment>
    );
  };
  
  const withFullImage = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="usrDef">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox">
                    <img className="imgf" src={Image} />
                </div>
                {!preview ?  <div className="textDeco">Card with full image</div> : null}
            </div>
        </React.Fragment>
    );
};

const withoutTitle = props => {
    
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Template title",
                Heading: "Template title",
                LongDescription:
                    "Some quick example text to build on the card title and make up the bulk of the card's content.",
                Image: imageName,
                Footer: ""
            };
    return (
        <div className="CardBNoTitle">
            <CardHeader
                title="CARD WITHOUT TITLE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
            <div className="title">
                {/* <i className="fa fa fa-fire" style={{ marginRight: "10px" }} />
                {Heading} */}
            </div>
            <div className="listBox" style={{height: `${CardHeight}px`}}>
                <div className="lineelips">{HtmlReacParser(LongDescription)}</div>
                <div style={{ clear: "both" }} />
            </div>
            {!preview ?  <div className="textDeco">Card without Title</div> : null}
        </div>
    );
};


const withNumberTable = props => {
    const tBody = <table style={{'border':'0', 'width' : '100%'}}> 
                        <tbody> 
                            <tr className="box">
                                <td className="inBox"> 
                                    <div className="ico">1</div>
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox"> 
                                    <div className="ico">2</div>
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox">
                                    <div className="ico">3</div>
                                    <div className="text">Combination Instrument</div>
                                </td>
                            </tr>
                       </tbody>
                  </table>

    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        tableData, 
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "",
                Image: imageName,
                Footer: "",
                tableData:null,
                preview: true
            };
    return (
        <div className="containerInnC">
            <CardHeader
                title="CARD WITH TABLE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
  
            <div style={{ display: "none" }}>
                <div className="htopInn" id="titleInn">
                    Table Type
                </div>
                
                <div className="editIcon">
                    <i className="fa fa-clone" aria-hidden="true" title="Edit" />
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash-o" aria-hidden="true" title="Delete" />
                </div>
            </div>
            {props.new ? tBody : preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody}
        </div>
    );
};

const withNumberTableHeader = props => {
    const tBody = <table style={{'border':'0', 'width' : '100%'}}> 
                        <tbody> 
                            <tr className="box">
                                <td className="inBox"> 
                                    <div className="ico">1</div>
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox"> 
                                    <div className="ico">2</div>
                                    <div className="text">Combination Instrument</div> 
                                </td>
                                <td className="inBox">
                                    <div className="ico">3</div>
                                    <div className="text">Combination Instrument</div>
                                </td>
                            </tr>
                       </tbody>
                  </table>
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        tableData, 
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "",
                Image: imageName,
                Footer: "",
                tableData:null,
                preview: true
            };
    return (
        <div className="containerInnC">
            <CardHeader
                title="CARD WITH TABLE"
                Data={props.Data}
                showTool={props.showHeaderTool}
                showPropIcon={props.showPropertyIcon}
                onCardDelete={props.onCardDelete}
                onProperty={props.onProperty}
            />
            <div style={{ display: "none" }}>
                <div className="htopInn" id="titleInn">
                    Table Type
                </div>
                
                <div className="editIcon">
                    <i className="fa fa-clone" aria-hidden="true" title="Edit" />
                    &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash-o" aria-hidden="true" title="Delete" />
                </div>
            </div>
            <div className="title">{Heading}</div>
            {props.new ? tBody : preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody}
            {/* {preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody} */}
        </div>
    );
};


const withFullImageWithoutHeader = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="usrDef">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="listBox">
                    <img className="imgf" src={Image} />
                </div>
                {!preview ?  <div className="textDeco">Card with full image without header.</div> : null}
            </div>
        </React.Fragment>
    );
};


const withLeftImageWithoutHeader = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="usrDefThree">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="listBox">
                    <div className="img">
                        <img src={Image} />
                    </div>
                    <div style={{position:'relative',height: `${CardHeight}px`}}>
                        {HtmlReacParser(LongDescription)}
                    </div>
                    <div style={{clear:'both'}}></div>
                 </div>
                 {!preview ?  <div className="textDeco">Card with left image without header.</div> : null}
            </div>
      </React.Fragment>
    );
  };

  const withRightImageWithoutHeader = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>        
            <div className="usrDefTwo">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="listBox">
                        <div className="img"  >
                            <img src={Image} />
                        </div>
                        <div style={{position:'relative',height: `${CardHeight}px`}}>
                            {HtmlReacParser(LongDescription)}
                        </div>
                    <div style={{clear:'both'}}></div>
                </div>
                {!preview ?  <div className="textDeco">Card with right image without header.</div> : null}
            </div>

        </React.Fragment>
    );
};

const withHorzImageWithoutHeader = props => {
    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: ""
            };
    return (
        <React.Fragment>
            <div className="usrDef">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="listBox">
                    <img className="img" src={Image} />
                    <div className="listBoxText" style={{height: `${CardHeight}px`}}>
                        {HtmlReacParser(LongDescription)}
                    </div>
                </div>
                {!preview ?  <div className="textDeco">Card with horizontal image without header</div> : null}
            </div>
        </React.Fragment>
    );
};


const withFullImageTable = props => {

    const tBody = <table style={{'width' : '100%'}}> 
                    <tbody> 
                        <tr className="box">
                            <td className="inBox"> 
                                <div className="text">Combination Instrument</div> 
                            </td>
                            <td className="inBox"> 
                                <div className="text">Combination Instrument</div> 
                            </td>
                        </tr>
                    </tbody>
                </table>

    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight,
        ResourceData,
        tableData
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: "",
                ResourceData : ""
            };
    return (
        <React.Fragment>
            <div className="usrDefThree">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox">
                    <div className="imgNew" style={{ float: "left", }}>
                        <img src={Image} />
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div className="switchTableNew" style={{ height: `${CardHeight}px` }}>
                        <div className="sitchTableNew">            
                            {props.new ? tBody : preview ? HtmlReacParser(LongDescription) :tableData ? tableData : tBody}
                        </div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                {!preview ? <div className="textDeco">Card with image table</div> : null}
            </div>
        </React.Fragment>
    );
};


const withLeftImageBottomTable = props => {

    const tBody = <table style={{'width' : '100%'}}> 
                    <tbody> 
                        <tr className="box">
                            <td className="inBox"> 
                                <div className="text">Combination Instrument</div> 
                            </td>
                            <td className="inBox"> 
                                <div className="text">Combination Instrument</div> 
                            </td>
                            <td className="inBox"> 
                                <div className="text">Combination Instrument</div> 
                            </td>
                        </tr>
                    </tbody>
                </table>

    const {
        id,
        DisplayName,
        Heading,
        Body,
        Image,
        Footer,
        LongDescription,
        ShortDescription,
        preview,
        CardHeight,
        ResourceData,
        tableData
    } =
        props.Data !== undefined
            ? props.Data
            : {
                id: 0,
                DisplayName: "Title will be here",
                Heading: "Template title",
                LongDescription: "Body will set here",
                Image: imageName,
                Footer: "",
            };
    return (
        <React.Fragment>
            <div className="usrDefThree">
                <CardHeader
                    title="INFORMATION"
                    Data={props.Data}
                    showTool={props.showHeaderTool}
                    showPropIcon={props.showPropertyIcon}
                    onCardDelete={props.onCardDelete}
                    onProperty={props.onProperty}
                />
                <div className="title">{Heading}</div>
                <div className="listBox">
                    <div className="img" style={{ float: "left", }}>
                        <img src={Image} />
                    </div>
                    <div className="switchTableNew" style={{ height: `${CardHeight}px` }}>
                        {HtmlReacParser(LongDescription)}
                        <div className="sitchTableNew">
                            {props.new ? tBody : preview ? HtmlReacParser(ResourceData) :tableData ? tableData : tBody}
                        </div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                {!preview ? <div className="textDeco">Card with image left image table</div> : null}
            </div>
        </React.Fragment>
    );
};




  
  
export default {
    withImage,
    withText,
    withCardWithinCard,
    withTable,
    withTableHeader,
    withGrayBGFull,
    withGrayBG,
    withHorzImage,
    withRightImage,
    withLeftImage,
    withFullImage,
    withoutTitle,
    withNumberTable,
    withNumberTableHeader,
    withFullImageWithoutHeader,
    withLeftImageWithoutHeader,
    withRightImageWithoutHeader,
    withHorzImageWithoutHeader,
    withFullImageTable,
    withLeftImageBottomTable
};
