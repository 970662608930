import React from 'react';
import {connect} from 'react-redux';

class SystemAuthenticatedWrapper extends React.Component{
    render(){
        let {loading} = this.props.common;

        if(!loading) {
            return this.props.component;
        }

        return null;
    }
}

const mapDispatchToProps = (store) => ({
    common: store.common
});

export default connect(mapDispatchToProps)(SystemAuthenticatedWrapper)