import React from 'react';

export const AjaxLoader = ({fullScreen=false, position="", width, height})=>{
    if(position === "nextToElement"){
        return (
            <div style={{
                border: "10px solid #e6e6e6",
                borderTop: "10px solid var(--main-color-light)",
                borderRadius: "50%",
                width: width,
                height: height,
                animationName: "spin",
                animationIterationCount: "infinite",
                animationDirection: "linear",
                animationDuration: "1s"
            }}/>
        );
    }

    let className = "loader-conatiner text-center";
    if(fullScreen){
        className += " position-fixed";
    }
    else{
        className += " position-absolute";
    }
    return <div className={className}>
        <div className="loader margin-auto"/>
    </div>
};