import React, { useEffect, useState } from "react";
import ArrowUp from '../../../assets/images/ArrowUpNew.svg'
import ArrowDown from '../../../assets/images/ArrowDownNew.svg'
import ArrowUpNewKTM from '../../../assets/images/ArrowUpNewKTM.svg'
import ArrowDownKTM from '../../../assets/images/ArrowDownNewKTM.svg'
import ThumbUp from '../../../assets/images/smallThumbsUpNew.svg'
import ThumbDown from '../../../assets/images/smallThumbsDownNew.svg'
import { getQuestionsObjectAsPerKey, getAnswerImage, redirectToUrl, sortArrayObject } from './utils'
import checkboxFilledNew from "../../../assets/images/checkboxFilledNew.svg";
import CheckboxFilledKTMNew from "../../../assets/images/CheckboxFilledKTMNew.svg";
import checkboxEmptyNew from "../../../assets/images/checkboxEmptyNew.svg";
import { TERMS_CONDITION, DISCLAIMER, PRIVATE_POLICY } from './Constants'
import { ai } from '../appInsights';
import { AppName, logLinkClick, logQuestionHandler } from "./AppInsightLog";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const DriversQuestionsData = ({ questionsData = {}, isPromoters = true, onSubmitButtonClick, currentRating, parentSchemaName, isKTM = false, appName }) => {
  const [iconClick, setIconClick] = useState(false);
  const [suggetionText, setSuggetionText] = useState('')
  const [isCallbackCheckbox, setIsCallbackCheckbox] = useState(true)
  const [questionsDriversData, setQuestionsDriversData] = useState();
  const [feedbackSubQuestionDropDown, setFeedbackSubQuestionDropDown] = useState([]);

  useEffect(() => {
    const getQuestionsDriversData = getQuestionsObjectAsPerKey(questionsData.NPSFeedbackQuestions, isPromoters ? 'Promotors' : 'Detractors')
    setQuestionsDriversData(getQuestionsDriversData)
    setFeedbackSubQuestionDropDown([])
  }, [currentRating])


  const getFinalObj = (data) => {
    const { DocumentId, Code, DisplayName } = data || {}
    const finalObj = {
      QuestionId: DocumentId,
      QuestionCode: Code,
      QuestionName: DisplayName,
      CustomerRemarks: '',
    }
    return finalObj
  }

  const onSubmitClick = () => {
    const feedbackLines = []
    if (feedbackSubQuestionDropDown.length) {
      const obj = getFinalObj(questionsDriversData)
      obj.IsMasterQuestion = '1';
      obj.FeedbackSubQuestion = feedbackSubQuestionDropDown
      feedbackLines.push(obj)
    }
    const callBackObj = getQuestionsObjectAsPerKey(questionsData.NPSFeedbackQuestions, 'Callback')
    const finalCallbackObj = getFinalObj(callBackObj)
    finalCallbackObj.IsMasterQuestion = '0';
    const checkBoxCallBackData = callBackObj?.NPSFeedbackSubQuestion?.length &&
      callBackObj?.NPSFeedbackSubQuestion[0].NPSFeebackAnswers?.length &&
      callBackObj?.NPSFeedbackSubQuestion[0].NPSFeebackAnswers.find(o => o.DisplayName === (isCallbackCheckbox ? 'Yes' : 'No'))
    const { DisplayName, Code, DocumentId } = callBackObj?.NPSFeedbackSubQuestion[0] || {}
    finalCallbackObj.FeedbackSubQuestion = [
      {
        SubQuestionId: DocumentId,
        SubQuestionCode: Code,
        SubQuestionName: DisplayName,
        CustomerRemarks: '',
        QuestionId: finalCallbackObj?.QuestionId,
        QuestionCode: finalCallbackObj?.QuestionCode,
        FeedbackAnswers: [
          {
            AnswerId: checkBoxCallBackData?.DocumentId,
            AnswerCode: checkBoxCallBackData?.Code,
            AnswerName: checkBoxCallBackData?.DisplayName,
            ActionItemL3ID: '',
            SubQuestionId: DocumentId,
            SubQuestionCode: Code,
          }
        ]
      }
    ]
    feedbackLines.push(finalCallbackObj)
    onSubmitButtonClick(feedbackLines, suggetionText)
  }

  const updateDriversObject = (selectedAnswerObj, selectedProductName) => {
    const selectedQuestionName = selectedProductName.DisplayName
    const answerName = selectedAnswerObj.DisplayName
    logQuestionHandler(appName, parentSchemaName, selectedQuestionName, answerName)
    const answerObj = {
      AnswerId: selectedAnswerObj.DocumentId,
      AnswerCode: selectedAnswerObj.Code,
      AnswerName: answerName,
      ActionItemL3ID: selectedAnswerObj.Code,
      SubQuestionId: selectedProductName.DocumentId,
      SubQuestionCode: selectedProductName.Code,
    }
    const feedbackSubQuestionDummyArray = [...feedbackSubQuestionDropDown];
    const selectedQuestionNameAvailable = feedbackSubQuestionDummyArray.find(obj => obj.SubQuestionName === selectedQuestionName)
    if (selectedQuestionNameAvailable && selectedQuestionNameAvailable.SubQuestionName) {
      const feedbackAnswers = selectedQuestionNameAvailable.FeedbackAnswers
      const isAnswerAlreadyAddedindex = feedbackAnswers.findIndex(obj => obj.AnswerName === answerName)
      if (isAnswerAlreadyAddedindex > -1) {
        feedbackAnswers.splice(isAnswerAlreadyAddedindex, 1);
      } else {
        feedbackAnswers.push(answerObj)
      }
      if (!feedbackAnswers.length) {
        const selectedQuestionNameAvailableIndex = feedbackSubQuestionDummyArray.findIndex(obj => obj.SubQuestionName === selectedQuestionName)
        if (selectedQuestionNameAvailableIndex > -1) {
          feedbackSubQuestionDummyArray.splice(selectedQuestionNameAvailableIndex, 1);
        }
      }
    } else {
      const subQuestionObj = {
        SubQuestionId: selectedProductName.DocumentId,
        SubQuestionCode: selectedProductName.Code,
        SubQuestionName: selectedQuestionName,
        CustomerRemarks: '',
        FeedbackAnswers: [answerObj],
        QuestionId: questionsDriversData?.DocumentId,
        QuestionCode: questionsDriversData?.Code,
      }
      feedbackSubQuestionDummyArray.push(subQuestionObj)
    }
    setFeedbackSubQuestionDropDown(feedbackSubQuestionDummyArray)
  }

  const displayCard = () => {
    const { NPSFeedbackSubQuestion } = questionsDriversData || {}
    const sortedNPSFeedbackSubQuestion = NPSFeedbackSubQuestion?.length && sortArrayObject(NPSFeedbackSubQuestion, 'DisplayIndex') || []
    if (!NPSFeedbackSubQuestion || !NPSFeedbackSubQuestion.length) {
      return null
    }

    const isAnsweAlreadySelected = (answer, subQuestionName) => {
      if (feedbackSubQuestionDropDown.length) {
        const selectedQuestionObj = feedbackSubQuestionDropDown.find(obj => obj.SubQuestionName === subQuestionName) || {}
        const isAnswerAlreadySelected = selectedQuestionObj?.FeedbackAnswers?.length && selectedQuestionObj.FeedbackAnswers.find(obj => obj.AnswerName === answer) || null
        return isAnswerAlreadySelected;
      }
      return false
    }

    const getArrowIcon = (displayName) => {
      if (isKTM) {
        return iconClick === displayName ? ArrowUpNewKTM : ArrowDownKTM
      } else {
        return iconClick === displayName ? ArrowUp : ArrowDown
      }
    }

    return (
      <>
        {sortedNPSFeedbackSubQuestion.map((obj, keyIndex) => {
          const isAnswerSelected = feedbackSubQuestionDropDown.find(o => o.SubQuestionName === obj.DisplayName) || {}
          // const disableDriversDropDown = feedbackSubQuestionDropDown?.length < 4 || isAnswerSelected?.SubQuestionName // In future may be we need this
          // className={disableDriversDropDown ? 'card' : 'cardDisableTask'} > In future may be we need this */}
          return (
            <div
              key={keyIndex + obj.DisplayName}
              className='card' >
              <div className="container"
                onClick={() =>
                  setIconClick(iconClick !== obj.DisplayName ? obj.DisplayName : "")
                }>
                <img src={getAnswerImage(obj.IconName && obj.IconName.trim())[isKTM ? 'ktmLogo' : 'bajajLogo']} />
                <div className="header-container">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <div className='displayName'>{obj.DisplayName}</div> */}
                    <div className={isKTM ? 'displayNameKTM' : 'displayName'}>{obj.DisplayName}</div>
                    {
                      isAnswerSelected?.SubQuestionName && iconClick !== obj.DisplayName
                        ? <img style={{ width: 18, height: 18 }} src={isPromoters ? ThumbUp : ThumbDown} />
                        : null
                    }
                  </div>
                  <div className='displaySubheader'>{obj.DisplayDescription}</div>
                </div>
                <img
                  src={getArrowIcon(obj.DisplayName)}
                  style={{ marginLeft: "auto", cursor: 'pointer' }}
                />
              </div>
              <ReactCSSTransitionGroup transitionName="question-driver" transitionEnterTimeout={1000} transitionLeaveTimeout={1000}>
                {
                  iconClick === obj.DisplayName ?
                    <div>
                      <div className='liner' />
                      <div className="drivermainContainer">
                        {(!!obj?.NPSFeebackAnswers && !!obj.NPSFeebackAnswers.length) ?
                          sortArrayObject(obj.NPSFeebackAnswers, 'DisplayIndex').map((answer, index) => {
                            const isCurrentAnsweAlreadySelected = isAnsweAlreadySelected(answer.DisplayName, obj.DisplayName)
                            return (
                              <div
                                onClick={() => updateDriversObject(answer, obj)}
                                key={index + answer.DisplayName}
                                style={
                                  (isCurrentAnsweAlreadySelected && isKTM) ? { background: '#373f43' } :
                                    (isCurrentAnsweAlreadySelected) ? { background: '#0066b3' } : {}}
                                className={isCurrentAnsweAlreadySelected ? "driverMainBorderSelected" : 'driverMainBorder'}>
                                {answer.DisplayName}
                              </div>
                            )
                          }) : <div>No answers found</div>
                        }
                      </div>
                    </div> : null
                }
              </ReactCSSTransitionGroup>
            </div>
          )
        })
        }
      </>
    );
  };

  const onSuggetionTextChange = (value) => {
    let dummyValue = value
    if (!suggetionText.length) { dummyValue = value.trim() }
    setSuggetionText(dummyValue)
  }

  const getCheckboxIcon = () => {
    if (isCallbackCheckbox) {
      return isKTM ? CheckboxFilledKTMNew : checkboxFilledNew
    } else {
      return checkboxEmptyNew
    }
  }

  const suggetionObj = getQuestionsObjectAsPerKey(questionsData.NPSFeedbackQuestions, 'Suggestions')
  return (
    <div className='question-answer-main'>
      <div className="questionHeader">
        {questionsDriversData && questionsDriversData.DisplayName}
        <img style={{ margin: '0 0 2px 10px' }} src={isPromoters ? ThumbUp : ThumbDown} />
      </div>
      <div className="App">{displayCard()}</div>
      <div style={{ marginTop: '24px' }}>
        <div className='suggetions'>
          {suggetionObj?.DisplayName}
        </div>
        <div style={{ margin: '16px' }}>
          <textarea
            onChange={(e) => onSuggetionTextChange(e.target.value)}
            value={suggetionText}
            placeholder={suggetionObj?.DisplayDescription}
            rows="8"
            maxLength="300"
            className="textareaNew"
          ></textarea>
          <div className='countCharNew'>{suggetionText.length}/300</div>
        </div>
        <div className='callbackCheckbox'>
          <img
            style={{ cursor: 'pointer' }}
            src={getCheckboxIcon()}
            width='24px'
            height='24px'
            onClick={() => setIsCallbackCheckbox(!isCallbackCheckbox)}
          />
          <div className='suggetions'>
            {getQuestionsObjectAsPerKey(questionsData.NPSFeedbackQuestions, 'Callback')?.DisplayName}
          </div>
        </div>
      </div>
      <div className='submitbutton'>
        <button
          className={isKTM ? 'KTMButton' : 'MCButton'}
          disabled={!feedbackSubQuestionDropDown.length}
          style={!feedbackSubQuestionDropDown.length ? { background: '#BEBEBE', opacity: 0.5 } : {}}
          onClick={onSubmitClick}>
          {questionsData?.SubmitButtonText || 'SUBMIT'}
        </button>
      </div>
      <div className='footer'>
        <div className='privacyContainer'>
          <div onClick={() => redirectToUrl(isKTM, TERMS_CONDITION)}>Terms & Conditions</div>
          <div onClick={() => redirectToUrl(isKTM, DISCLAIMER)}>Disclaimer</div>
          <div onClick={() => redirectToUrl(isKTM, PRIVATE_POLICY)}>Privacy Policy</div>
        </div>
        <div className='copyrightNew'>Copyright © 2024 Bajaj Auto Ltd. All rights reserved</div>
      </div>
    </div >
  )
}

export default DriversQuestionsData