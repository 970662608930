import qs from "query-string";
import crypto from 'crypto'

export const decryptCrypto = async () => {
    try {
        const location = window.location.search;
        const cipher = qs.parse(location);
        // if (cipher.nps) { // kept commented code for non-encryption link
        const encryptedText = cipher.nps
        let bData = Buffer.from(encryptedText, 'Hex');
        var mykey = crypto.createDecipheriv('aes-192-ecb', 'mypassword12345678901234', '');
        var mystr = mykey.update(bData, 'utf8')
        mystr += mykey.final('utf8');
        if (mystr)
            return JSON.parse(mystr);
        return undefined
    } catch (e) { console.log("Err", e) }


}