export const CardTypes = {
      CardWithImage: "CardWithImage",
      CardWithText: "CardWithText",
      CardWithTable: "CardWithTable",
      CardwithGrayBGFull : "CardwithGrayBGFull",
      CardwithGrayBG : "CardwithGrayBG",
      CardwithHorzImage : "CardwithHorzImage",
      CardwithRightImage : "CardwithRightImage",
      CardwithLeftImage : "CardwithLeftImage",
      CardWithinCard : "CardWithinCard",
      CardwithTableHeader : "CardwithTableHeader",
      CardwithFullImage : "CardwithFullImage",
      CardWithoutTitle : "CardWithoutTitle",
      CardwithNumberTable : "CardwithNumberTable",
      CardwithNumberTableHeader : "CardwithNumberTableHeader",
      CardWithFullImageWithoutHeader : "CardWithFullImageWithoutHeader",
      CardwithLeftImageWithoutHeader : "CardwithLeftImageWithoutHeader",
      CardwithRightImageWithoutHeader : "CardwithRightImageWithoutHeader",
      CardwithHorzImageWithoutHeader : "CardwithHorzImageWithoutHeader",
      CardWithFullImageTable : "CardWithFullImageTable",
      CardWithLeftImageBottomTable : "CardWithLeftImageBottomTable"
  };

export const tableCards = [
      "CardWithTable", 
      "CardwithTableHeader", 
      "CardwithNumberTable", 
      "CardwithNumberTableHeader",
      "CardWithFullImageTable",
      "CardWithLeftImageBottomTable"
];

export const imageCards = [
    "CardWithImage", 
    "CardwithHorzImage",
    "CardwithRightImage", 
    "CardwithLeftImage", 
    "CardwithFullImage",
    "CardWithFullImageWithoutHeader",
    "CardwithLeftImageWithoutHeader",
    "CardwithRightImageWithoutHeader",
    "CardwithHorzImageWithoutHeader",
    "CardWithFullImageTable",
    "CardWithLeftImageBottomTable"
];
  