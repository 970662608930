import { logLinkClick } from "./AppInsightLog";

export const getQuestionsObjectAsPerKey = (data = [], key) => data.find(obj => obj.FrontEndCode === key)

export const redirectToUrl = (isKTM, url) => {
    logLinkClick(isKTM, 'privacyDisclaimer')
    window.open(url, '_blank')
}

export const sortArrayObject = (objs, key) => objs.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))

export const isBackspaceDeleteButton = (evt) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode === 8 || charCode === 46) return true;
}

export const detectCurrentDevice = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile ? 'MOB_APP' : 'WEB_APP'
}

const currentOperatingSystem = () => {
    let OSName = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
    if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
    return OSName
}

export const getUniqueIdFromBrowser = () => {
    let browserInfo = ''
    const test = regexp => regexp.test(window.navigator.userAgent)
    switch (true) {
        case test(/edg/i):
            browserInfo = "Microsoft_Edge";
            break;
        case test(/trident/i):
            browserInfo = "Microsoft Internet_Explorer";
            break;
        case test(/firefox|fxios/i):
            browserInfo = "Mozilla_Firefox";
            break;
        case test(/opr\//i):
            browserInfo = "Opera";
            break;
        case test(/ucbrowser/i):
            browserInfo = "UC_Browser";
            break;
        case test(/samsungbrowser/i):
            browserInfo = "Samsung_Browser";
            break;
        case test(/chrome|chromium|crios/i):
            browserInfo = "Google_Chrome";
            break;
        case test(/safari/i):
            browserInfo = "Apple_Safari";
            break;
        default:
            browserInfo = "Other";
    }
    return `${browserInfo}_${currentOperatingSystem()}`
}

export const getAnswerImage = (imageName) => {
    switch (imageName) {
        case "Bike":
            return {
                bajajLogo: require("../../../assets/images/bikeNew.svg"),
                ktmLogo: require("../../../assets/images/bikeNewKTM.svg"),
            }
        case "Exchange":
            return {
                bajajLogo: require("../../../assets/images/exchangeNew.svg"),
                ktmLogo: require("../../../assets/images/exchangeNewKTM.svg"),
            }
        case "Accessibility":
            return {
                bajajLogo: require("../../../assets/images/accessibilityNew.svg"),
                ktmLogo: require("../../../assets/images/accessibilityNewKTM.svg"),
            }
        case "Showroom":
            return {
                bajajLogo: require("../../../assets/images/showroomNew.svg"),
                ktmLogo: require("../../../assets/images/showroomNewKTM.svg"),
            }
        case "Purchase":
            return {
                bajajLogo: require("../../../assets/images/purchaseNew.svg"),
                ktmLogo: require("../../../assets/images/purchaseNewKTM.svg"),
            }
        case "Price":
            return {
                bajajLogo: require("../../../assets/images/priceNew.svg"),
                ktmLogo: require("../../../assets/images/priceNewKTM.svg"),
            }
        case "Person":
            return {
                bajajLogo: require("../../../assets/images/personNew.svg"),
                ktmLogo: require("../../../assets/images/personNewKTM.svg"),
            }
        case "Auto":
            return {
                bajajLogo: require("../../../assets/images/autoThreeWheel.svg")
            }
        case "Interaction":
            return {
                bajajLogo: require("../../../assets/images/interactionNew.svg"),
                ktmLogo: require("../../../assets/images/interactionNewKTM.svg"),
            }
        case "ServiceQuality":
            return {
                bajajLogo: require("../../../assets/images/serviceQualityNew.svg"),
                ktmLogo: require("../../../assets/images/serviceQualityNewKtm.svg"),
            }
        case "Time":
            return {
                bajajLogo: require("../../../assets/images/timeNew.svg"),
                ktmLogo: require("../../../assets/images/timeNewKtm.svg"),
            }
        case "ValueAddedService":
            return {
                bajajLogo: require("../../../assets/images/valueAddedServicesNew.svg"),
                ktmLogo: require("../../../assets/images/valueAddedServicesNewKtm.svg"),
            }
        case "Procedure":
            return {
                bajajLogo: require("../../../assets/images/procedureNew.svg"),
                ktmLogo: require("../../../assets/images/procedureNewKtm.svg"),
            }
        default:
            return {
                bajajLogo: require("../../../assets/images/accessibilityNew.svg"),
                ktmLogo: require("../../../assets/images/accessibilityNewKTM.svg"),
            }
    }
}