import React from 'react';
import { connect } from 'react-redux';
import { Utility } from "../../../utility";
import BrandLogo from '../../../../assets/img/brand-logo.png';
import { Collapse } from 'reactstrap';
import {
    setSelectedNavigationItem,
    setViewName,
    toggleNavigationWidth,
    toggleSubNavigationVisibility,
    setSelectedNonBusinessItem,
    showExpandedMenuOnClose,
    setActiveTabName,
    setHoverOnCollapsed

} from "../../commonActions";
import { getSchemaLocalizedStore } from "../../../dataStore";
import { AjaxLoader } from "../../AjaxLoader";
import { Icons } from '../../Icons';
import { withRouter } from "react-router-dom";
import { enumMenu } from '../../commonEnums';
const menuStyle = {
    overflowY: 'auto',
    height: '500px'
};
class ExpandedNavigation extends React.Component {
    constructor() {
        super();

        this.state = {
            isProcessing: false,
            isNotSubscriptionUser: false,
            activeMainNavIndex: -1,
            clickEvent: false
        };
    }

    componentDidMount() {
        this.setState({ isNotSubscriptionUser: Utility.getUserRole() !== "SubscriptionUser" });
    }

    async onBusinessItemClicked(documentId, e) {
        this.setState({ isProcessing: true });
        this.NavigatePage(documentId);
        // this.props.history.push(`/dashboard/${documentId}`);
        // this.setState({ isProcessing: false });
    }

    NavigatePage(documentId) {
        let { homeMetadata } = this.props.common;
        let item = homeMetadata.Metadata.find(x => x.DocumentId === documentId);
        if (item.HomeViewType === "Custom") {
            switch (item.SystemName) {
                case "UBDashBoard":
                    this.props.history.push(`/ubdashboard/${documentId}`);
                    break;
                case "IBDashBoard":
                    this.props.history.push(`/ibdashboard/${documentId}`);
                    break;
                case "DataDeletionLogs":
                    this.props.history.push(`/customForm/dataDeletionUtilityLogs/${documentId}`);
                    break;
                case "UniqueCustomer1":
                    this.props.history.push(`/callCenter/Customer360`);
                    break;
                default:
                    this.props.history.push(`/callCenter/Outbound`);
                    break;
            }
        } else {
            this.props.history.push(`/dashboard/${documentId}`);
        }
        this.setState({ isProcessing: false });
    }

    onNonBusinessItemClicked(item) {
        this.props.setSelectedNonBusinessItem(item);
        this.props.toggleNavigationWidth();
    }

    onToggleCollapse(index) {
        index = (index === this.state.activeMainNavIndex) ? -1 : index;
        let { subMenu } = this.props.common.navigationList.nonBusinessMenu;
        if (subMenu.visible)
            this.props.toggleSubNavigationVisibility();
        this.setState({ activeMainNavIndex: index });
    }

    getBusinessSectionItems() {
        let self = this;
        let { activeMainNavIndex } = this.state;
        let { homeMetadata } = this.props.common;
        if (Utility.isObjectEmpty(homeMetadata)) return null;

        return homeMetadata.HomeSections.filter(x => x.IsBusinessSection).map((item, index) => {
            // Show only those entites from metadata whose IsAuthorized is true and contain atleast one view with showInHome set to true
            let childItems = homeMetadata.Metadata.filter(x => x.HomeSectionSystemName === item.SystemName && x.IsAuthorized && x.View.filter(y => y.ShowInHome && y.IsAuthorized).length > 0);

            let path = window.location.pathname.split('/');
            let documentId = path[path.length - 1];
            let iconName = item.IconName ? item.IconName : "catalog.svg";
            let isDocumentOpen = !!childItems.find(x => x.DocumentId === documentId);
            let isActive = activeMainNavIndex === index || isDocumentOpen;
            let arrowIconType = isActive ? "upArrow.svg" : "downArrow.svg";
            if (childItems.length === 0) return null;

            return <li className="main-list-item" key={"main" + index}>
                <div id={"main" + index} className={isDocumentOpen ? "left-navigation-li-active left-navigation-parent-li-active" : ""} onClick={this.onToggleCollapse.bind(this, index)}>
                    <div className={isActive ? "main-div active" : "main-div"}>
                        <Icons type={iconName} className="left-navigation-icon" width="22px" height="22px" />
                        <span className="pl-14">{item.DisplayName}</span>
                        <span className="float-right"><Icons type={arrowIconType} width="12px" height="8px" /></span>
                    </div>
                </div>
                <Collapse isOpen={isActive}>
                    <ul className="pt-2">{childItems.map((childItem, childIndex) => {
                        let className = "left-navigation-child-menu";
                        className += (childItem.DocumentId === documentId) ? " left-navigation-li-active" : "";
                        return <li className={className}
                            title={childItem.PluralDisplayName.length < enumMenu.menuLength ? null : childItem.PluralDisplayName}
                            key={"child" + childItem.HomeSectionSystemName + childIndex}
                            onClick={this.onBusinessItemClicked.bind(this, childItem.DocumentId)}>
                            <span className="pr-2"><Icons type="circle.svg" width="8px" height="8px" /></span>
                            {childItem.PluralDisplayName}</li>
                    })}</ul>
                </Collapse>
            </li>;
        })
    }

    isNonBusinessItemVisible(item) {
        let self = this;
        for (let i = 0; i < item.ChildSections.length; i++) {
            let childItem = item.ChildSections[i];

            if (childItem.ChildSections) {
                for (let j = 0; j < childItem.ChildSections.length; j++) {
                    let nestedChildItem = childItem.ChildSections[j];

                    let schemaMetaData = self.props.common.homeMetadata.Metadata.find(x => x.HomeSectionSystemName === nestedChildItem.SystemName && x.IsAuthorized);
                    if (schemaMetaData) {
                        return true;
                    }
                }
            }
            else {
                let schemaMetaData = self.props.common.homeMetadata.Metadata.find(x => x.HomeSectionSystemName === childItem.SystemName && x.IsAuthorized);
                if (schemaMetaData) {
                    return true;
                }
            }
        }

        return false;
    }

    getNonBusinessSectionItems() {
        let self = this;
        let { homeMetadata } = this.props.common;
        if (Utility.isObjectEmpty(homeMetadata)) return null;

        let children = [];
        homeMetadata.HomeSections.filter(x => !x.IsBusinessSection).map((item, index) => {
            let isVisible = self.isNonBusinessItemVisible(item);

            let iconPath = "/homeicons/" + (item.IconName ? item.IconName : "ccm.svg");

            if (isVisible) {
                children.push((<li key={"bottom" + index} id={"bottom" + index}
                    onClick={this.onNonBusinessItemClicked.bind(this, item)}>
                    <span className="setting-icon"><Icons type={item.IconName} src={iconPath} width="22px"
                        height="22px" /></span></li>));
            }
        });

        return (children.length > 0) ? <ul>{children}</ul> : null;
    }

    hover() {
        if (!this.state.clickEvent) {
            if (this.props.common.navigationList.hoverOnCollapsed === true) {
                this.props.setHoverOnCollapsed(false)
                this.props.toggleSubNavigationVisibility();
                this.props.toggleNavigationWidth();
            }
        }
    }

    render() {
        let { isNotSubscriptionUser } = this.state;
        let nonBusinessSections = this.getNonBusinessSectionItems();
        return (<div className="h-100" >
            {/* <div className="h-100" onMouseLeave={this.hover.bind(this)} > */}
            {this.state.isProcessing ? <AjaxLoader fullScreen={true} /> : null}
            <div className="brand-div">
                <div className="brand-name">
                    <div className="brand-logo"> <img src={BrandLogo} alt="" />
                    </div>
                </div>
            </div>
            <ul className="business-menu-list" style={menuStyle}>
                {this.getBusinessSectionItems()}
            </ul>
            {(isNotSubscriptionUser && nonBusinessSections) ? <div className="setting">
                {nonBusinessSections}
            </div> : null}
        </div>);
    }
}

const mapStateToProp = (store) => ({
    common: store.common
});

const mapDispatchToProps = {
    setSelectedNavigationItem,
    setViewName,
    toggleSubNavigationVisibility,
    toggleNavigationWidth,
    setSelectedNonBusinessItem,
    showExpandedMenuOnClose,
    setActiveTabName,
    setHoverOnCollapsed
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(ExpandedNavigation));