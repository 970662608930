import Axios from 'axios';
import {AlertPopup} from "./AlertPopup";
import { Utility } from './utility';

export class APIClient{
    // notificationType: 0 - Toast, 1-Popup
    // showError: "yes" to show error message if something fails and "no" to not show anything
    static async get(url, params, showError = true) {
        return await Axios.get(url, params).catch((error)=>{
            if(showError){
                this.handleError(error);
            }
            else{
                throw error;
            }
        });
    }

    static async post(url, body, showError=true){
            return await Axios.post(url, body).catch((error) => {
                if(showError){
                    this.handleError(error);
                }
                else{
                    throw error;
                }
        });
    }

    static async put(url, body, showError=true){
            return await Axios.put(url, body).catch((error) => {
                if(showError){
                    this.handleError(error);
                }
                else{
                    throw error;
                }
        });
    }

    static async delete(url, showError=true){
            return await Axios.delete(url).catch ((error) => {
                if(showError){
                    this.handleError(error);
                }
                else{
                    throw error;
                }
        });
    }

    static handleError(error){
        if(error.response !== undefined){
            if((error.response.status === 401 || error.response.data.MessageCode ==="AuthorizationFailed") && (window.location.pathname.indexOf('login') === -1 )){
                Utility.removeAuthenticationToken();
                window.location = window.location.href;
                return;
            }
            AlertPopup.showPopup("exception",  error.response.data);
        }
        else{
            AlertPopup.showPopup("error",  error.message);
        }
    }
}