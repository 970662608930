export const commonConstants = {
  UI_SET_SHOW_CHANGE_PASSWORD: "UI_SET_SHOW_CHANGE_PASSWORD",
  UI_SET_SELECTED_NAVIGATION_ITEM: "UI_SET_SELECTED_NAVIGATION_ITEM",
  UI_SET_SELECTED_GRID_ITEM: "UI_SET_SELECTED_GRID_ITEM",
  UI_SET_LOGIN_ACTIVE_TAB: "UI_SET_LOGIN_ACTIVE_TAB",
  UI_SET_HOME_METADATA: "UI_SET_HOME_METADATA",
  UI_SET_VIEW_NAME: "UI_SET_VIEW_NAME",
  UI_TOGGLE_NAVIGATION_WIDTH: "UI_TOGGLE_NAVIGATION_WIDTH",
  UI_TOGGLE_SUB_NAVIGATION_VISIBILITY: "UI_TOGGLE_SUB_NAVIGATION_VISIBILITY",
  UI_SET_SELECTED_DOCUMENT_VIEW: "UI_SET_SELECTED_DOCUMENT_VIEW",
  UI_SET_SELECTED_NON_BUSINESS_ITEM: "UI_SET_SELECTED_NON_BUSINESS_ITEM",
  UI_SET_SELECTED_SUB_NAVIGATION_ITEM: "UI_SET_SELECTED_SUB_NAVIGATION_ITEM",
  UI_SET_SELECTED_SCHEMA: "UI_SET_SELECTED_SCHEMA",
  UI_SET_SHOW_EXPANDED_MENU_ON_CLOSE: "UI_SET_SHOW_EXPANDED_MENU_ON_CLOSE",
  UI_SET_ALL_SCHEMA_VISIBILITY: "UI_SET_ALL_SCHEMA_VISIBILITY",
  UI_SET_SELECTED_DOCUMENT: "UI_SET_SELECTED_DOCUMENT",
  UI_SHOW_CUSTOM_FORM: "UI_SHOW_CUSTOM_FORM",
  UI_SET_ACTIVE_TAB_NAME: "UI_SET_ACTIVE_TAB_NAME",
  UI_SET_FORM_OPTIONS: "UI_SET_FORM_OPTIONS",
  UI_SET_SUBSCRIPTION_DATA: "UI_SET_SUBSCRIPTION_DATA",
  UI_SET_CURRENT_GRID_DATA: "UI_SET_CURRENT_GRID_DATA",
  UI_UPDATE_SCHEMA_METADATA: "UI_UPDATE_SCHEMA_METADATA",
  UI_SET_IS_LOADING: "UI_SET_IS_LOADING",
  UI_SET_IS_EXPORTING: "UI_SET_IS_EXPORTING",
  UI_SET_HOVER_ON_COLLAPSED: "UI_SET_HOVER_ON_COLLAPSED",
  UI_SET_SUCESSS_MESSAGE: "UI_SET_SUCESSS_MESSAGE",
  UI_SET_POPUP_FORM_OPTIONS: "UI_SET_POPUP_FORM_OPTIONS",
  UI_UPDATE_GRID_DATA: "UI_UPDATE_GRID_DATA",
  UI_UPDATE_DOCUMENT_SPECIFICS: "UI_UPDATE_DOCUMENT_SPECIFICS",
  SET_ACTIVE_PROGRESS_TAB: "SET_ACTIVE_PROGRESS_TAB",
  DISABLE_IMPORT_BUTTON: "DISABLE_IMPORT_BUTTON",
  UI_SET_GO_TO_BACK: "UI_SET_GO_TO_BACK",
  UI_SET_ACTIVE_VIEW_DEV_CONSOLE: "UI_SET_ACTIVE_VIEW_DEV_CONSOLE",
  UI_SET_SHOW_SEARCH_OPTION: "UI_SET_SHOW_SEARCH_OPTION",
  UI_SET_SELECTED_SEARCH_DATA: "UI_SET_SELECTED_SEARCH_DATA",
  UI_SET_SELECTED_DASHBOARD_VIEW: "UI_SET_SELECTED_DASHBOARD_VIEW",
  UI_SET_IS_FORM_LOADING: "UI_SET_IS_FORM_LOADING",
  UI_SET_GRAPH_FILTER_STRING: "UI_SET_GRAPH_FILTER_STRING",
  UI_SET_HOMECHARGING_IMAGE_COUNT: "UI_SET_HOMECHARGING_IMAGE_COUNT",
  UI_UPLOADED_IMAGE_COUNT: "UI_UPLOADED_IMAGE_COUNT",
  UI_SET_SELECTED_FILTER: "UI_SET_SELECTED_FILTER"
};
