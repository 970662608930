import React, { Component } from "react";
import { Popup } from 'devextreme-react/popup';
import imgArrow from "../../../assets/images/arrow.svg";
import imgArrowBack from "../../../assets/images/arrowBack.svg";
import imgSad from "../../../assets/images/sadKTM.svg";
import imgsmileSmall from "../../../assets/images/smileSmallKTM.svg";


import Switch from "react-switch";

const KtmFinanceInteract = ({
    stars,
    rating,
    onRatingFinanceClick,
    remarks,
    question,
    onFinanceAnswerSelect,
    IsFinanceOther,
    onFinanceCommentChange,
    financeComments,
    showPopup,
    onHidePopup,
    onShownPopup,
    starGoldenYellow
}) => {
    
    let starClass = null;
    let smileClass = null;
    starGoldenYellow = {
        visibility: true
      }
    
      if (starGoldenYellow.visibility == true) {
        starClass = "rateBoxGoldenYellow";
      }
    
     else if (rating >= 0 && rating <= 6) {
        starClass = " rateBoxOrangeK";
        smileClass = "imgSadBig";
    } else if (rating >= 7 && rating <= 8) {
        starClass = " rateBoxActive";
        smileClass = "imgSmileAverage";
    } else if (rating >= 9 && rating <= 10) {
        starClass = " rateBoxGreen";
        smileClass = "imgSmileHappy";
    } else {
        smileClass = "imgSmileBig";
    }

    function onBlurHandler(){
        onHidePopup();
    }

    function onClickHandler(){
        onShownPopup();
    }

    return (
        <div>
            <div >
                <form>

                    <div style={{ position: "relative", height: "60vh" }}>
                        <div style={{ clear: "both" }}></div>

                        {!showPopup ?  <div>
                            <div style={{ borderTop: 1, borderBlockColor: "#cccccc" }}>{remarks}</div>

                            <div className="ratingContainer">
                                {stars.map(item => (
                                    <div
                                        onClick={() => onRatingFinanceClick(item)}
                                        className={
                                            rating == item ? "rateBox " + starClass : "rateBox"
                                        }
                                        key={item}
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div className="ratingLavels">
                                <div className="lowestRate">
                                    <div>
                                        <img src={imgSad} width={40} style={{ paddingLeft: 15 }} />
                                    </div>
                                    <div style={{ width: 50, textAlign: "center" }}>
                                        Not at all likely
                                </div>
                                </div>

                                <div className="highestRate">
                                    <div>
                                        <img
                                            src={imgsmileSmall}
                                            width={40}
                                            style={{ paddingRight: 15 }}
                                        />
                                    </div>
                                    <div style={{ width: 50, textAlign: "center", float: "right" }}>
                                        Extremely likely
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "6%" }}>{question.DisplayName}</div>
                        </div>
                        : null}

                        <div className={showPopup ? "container middleMainFinSelected" : "container middleMainFin" } >
                            <ul style={{ margin: 0, flex: 2 }}>
                                {question.FeebackAnswers.map(answer => {
                                    return <li style={{ color: "#ffffff" }} key={answer.id} onClick={() => onFinanceAnswerSelect(answer)}
                                        className={`contentBox ${answer.IsSelected ? ' selectedColK' : ''}`}>
                                        <div className="showroomAccessibili">
                                            <img src={answer.IsSelected ? answer.answerImages.imageSelected : answer.answerImages.imageSelected} width="30" />
                                        </div>
                                        <div style={{ width: "90%" }}>
                                            <div style={{ fontSize: 13, marginBottom: 5, fontWeight: 600, letterSpacing: 1 }} >
                                                {answer.DisplayHeader}
                                            </div>
                                            <div>{answer.DisplayDescription}</div>
                                        </div></li>;
                                })}
                                {IsFinanceOther ?
                                    <textarea 
                                        onChange={(event) => onFinanceCommentChange(event.target.value)} 
                                        placeholder="Enter your suggestions" 
                                        onBlur={onBlurHandler}
                                        onClick={onClickHandler}
                                        style={{color:"#ffffff"}}
                                        autoFocus
                                        value={financeComments} rows="3">
                                    </textarea> : 
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                </form>
                {/* <Popup
                    visible={showPopup}
                    onHiding={onHidePopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={false}
                    width={300}
                    height={243}
                >
                    <div className="ktmPop">
                        <div className="titleKtm">Others</div>
                        <textarea onChange={(event) => onFinanceCommentChange(event.target.value)} placeholder="Enter your suggestions" value={financeComments} rows="6"></textarea>
                        <button onClick={onHidePopup} > Ok</button>
                    </div>
                </Popup> */}
            </div>
        </div>
    );
};
export default KtmFinanceInteract;
