import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Popup } from 'devextreme-react/ui/popup';
import { Collapse, Button } from 'reactstrap';
import { ScrollView, Form } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';

export class AlertPopup {
    static showPopup(type, error, title = "", success = []) {
        console.log(type, error, title, success)
        let popover = '';
        switch (type) {
            case 'exception':
                popover = <AlertPopupException data={error} />
                break;
            case 'error':
                popover = <AlertPopupError message={error} />
                break;
            case 'message':
                popover = <AlertPopupMessage message={error} title={title} />
                break;
            case 'messagelist':
                popover = <AlertPopupMessageList title={title} errorList={error} successList={success} />
                break;
            default:
                break;
        }
        ReactDOM.render(popover, document.getElementById("popup"));
    }

    static async showConfirmation(message, title) {
        let result = await confirm(message, title);
        return result;
    }
}

const AlertPopupError = ({ message }) => {
    return (<Popup visible={true} width={600} height="200px" title="Something went wrong!"
        dragEnabled={false} showCloseButton={true} onHiding={() => ReactDOM.unmountComponentAtNode(document.getElementById("popup"))}
        closeOnOutsideClick={false}>
        {message}
        <div className="pt-3">Please contact support@excellonconnect.com</div>
    </Popup>);
};

const AlertPopupMessage = ({ message, title }) => {
    let self = this;
    return (<Popup visible={true} width={600} height="200px" title={title}
        dragEnabled={false} showCloseButton={true} onHidden={() => {
            ReactDOM.unmountComponentAtNode(document.getElementById("popup"));
        }}
        closeOnOutsideClick={false}>
        {message}
    </Popup>);
};


const AlertPopupMessageList = ({ title, errorList, successList }) => {
    let self = this;
    return (
        <Popup
            // ref={this.popup}
            visible={true}
            width={600}
            height="auto"
            title={title}
            dragEnabled={false}
            showCloseButton={true}
            onHidden={() => { ReactDOM.unmountComponentAtNode(document.getElementById("popup")); }}
            closeOnOutsideClick={false}>
            <div>
                {successList.length > 0 ?
                    <Fragment>
                        <span className="font-14 font-weight-bolder pb-2">Success : </span>
                        <ul>
                            {successList.map((item, index) => (
                                item !== "" ? <li className="font-12 pb-1" key={index}>{item}</li> : null
                            ))}
                        </ul>
                    </Fragment> : null}
                {errorList.length > 0 ?
                    <Fragment>
                        <span className="font-14 font-weight-bolder pb-2">Errors : </span>
                        <ul>
                            {errorList.map((item, index) => (
                                item !== "" ? <li className="font-12" key={index}>{item}</li> : null
                            ))}
                        </ul>
                    </Fragment> : null}
            </div>
        </Popup>);
};

class AlertPopupException extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false
        }
        this.popup = React.createRef();
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    togglePopup(e) {
        ReactDOM.unmountComponentAtNode(document.getElementById("popup"));
    }

    toggleCollapse() {
        this.setState({ show: !this.state.show });
    }

    getFormItems() {
        return [
            {
                label: { text: 'Error Code', location: 'top' },
                dataField: 'ErrorCode'
            },
            {
                label: { text: 'Exception Message', location: 'top' },
                dataField: 'ExceptionMessage'
            },
            {
                label: { text: 'Exception Type', location: 'top' },
                dataField: 'ExceptionType'
            },
            {
                label: { text: 'Message', location: 'top' },
                dataField: 'Message',
                editorType: 'dxTextArea'
            },
            {
                label: { text: 'StackTrace', location: 'top' },
                dataField: 'StackTrace',
                editorType: 'dxTextArea'
            },
            {
                itemType: "group",
                caption: "Inner Exception",
                label: { visible: false },
                items: [{
                    label: { text: 'Exception Message', location: 'top' },
                    dataField: 'InnerException.ExceptionMessage'
                }, {
                    label: { text: 'Exception Type', location: 'top' },
                    dataField: 'InnerException.ExceptionType'
                }, {
                    label: { text: 'Message', location: 'top' },
                    dataField: 'InnerException.Message'
                }, {
                    label: { text: 'StackTrace', location: 'top' },
                    dataField: 'InnerException.StackTrace',
                    editorType: 'dxTextArea'
                }]
            }
        ];
    }

    render() {
        let { data } = this.props;

        return (
            <Popup
                // ref={this.popup} 
                visible={true} width={600} height="auto" title="Something went wrong!"
                dragEnabled={false} showCloseButton={true} onHiding={this.togglePopup}
                closeOnOutsideClick={false}>
                <div className="font-lg">
                    An <b>Exception Id </b>{data.ExceptionId} has been generated.<br />
                    <div className="text-center p-4">
                        <Button color="secondary" onClick={this.toggleCollapse}>Show Details</Button>
                    </div>
                    <Collapse isOpen={this.state.show}>
                        <ScrollView height={200} direction="both">
                            <Form formData={data} items={this.getFormItems()} readOnly={true} />
                        </ScrollView>
                    </Collapse>
                </div>
            </Popup>
        );
    }
}