import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from './common/Components/Header';
import { Routes } from './Routes';
import NavigationList from "./common/Components/NavigationList";
import { BrowserRouter } from "react-router-dom";

class RightContainer extends React.Component {
    isHideHeader(pathName) {
        return (pathName.indexOf('login') >= 0
            || pathName.indexOf('preview') >= 0
            || pathName.indexOf('thankyou') >= 0
            || pathName.indexOf('terms-and-conditions') >= 0
            || pathName.indexOf('attachment') >= 0
            || pathName.indexOf('ktmfeedback') >= 0
            || pathName.indexOf('mcfeedback') >= 0
            || pathName.indexOf('mcfinance') >= 0
            || pathName.indexOf('ktmservice') >= 0
            || pathName.indexOf('mcservice') >= 0
            || pathName.indexOf('cvservice') >= 0
            || pathName.indexOf('cvfeedback') >= 0
            || pathName.indexOf('ktmfinance') >= 0);
    }

    checkIsNPSLink(pathName) {
        return (pathName.indexOf('ktmfeedback') >= 0
            || pathName.indexOf('mcfeedback') >= 0
            || pathName.indexOf('cvservice') >= 0
            || pathName.indexOf('cvfeedback') >= 0
            || pathName.indexOf('ktmservice') >= 0
            || pathName.indexOf('mcservice') >= 0);
    }

    render() {
        let hideNavigation = this.isHideHeader(window.location.pathname);

        let { isNavigationCollapsed, nonBusinessMenu } = this.props.common.navigationList;
        let className = "right-container ";
        className += (nonBusinessMenu.subMenu.visible) ? "expanded-subNavigation" : (isNavigationCollapsed) ? "collapsed " : "expanded ";
        className = hideNavigation ? "" : className;

        const isNPSLink = this.checkIsNPSLink(window.location.pathname)

        return (<BrowserRouter>
            <Fragment>
                {
                    !isNPSLink ?
                        <>
                            <Header />
                            <NavigationList />
                        </> :
                        null
                }

                <div className={className}>
                    <Routes />
                </div>
            </Fragment>
        </BrowserRouter>);
    }
}

const mapStateToProp = (store) => ({
    common: store.common
});

export default connect(mapStateToProp)(RightContainer)