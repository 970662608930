import React from 'react';
import shortBrandLogo from '../../../../assets/img/short-brand-logo.svg';
import {Utility} from "../../../utility";
import { Collapse} from 'reactstrap';
import SubNavigation from './SubNavigation';
import {getSchemaLocalizedStore} from "../../../dataStore";
import {
    setSelectedNavigationItem,
    setViewName,
    toggleSubNavigationVisibility,
    setSelectedNonBusinessItem,
    toggleNavigationWidth,
    showExpandedMenuOnClose,
    setHoverOnCollapsed
} from "../../commonActions";
import {connect} from "react-redux";
import {AjaxLoader} from "../../AjaxLoader";
import {Icons} from '../../Icons';
import {withRouter} from "react-router-dom";

class CollapsedNavigation extends React.Component{
    constructor(){
        super();

        this.state = {
            activeMainNavIndex: -1,
            isProcessing: false
        };
    }

    async onBusinessItemClicked(documentId, e){
        this.setState({isProcessing: true});
        this.setState({isProcessing: false});
        this.props.history.push(`/dashboard/${documentId}`);
    }

    onNonBusinessItemClicked(item){
        if(this.props.common.navigationList.nonBusinessMenu.selectedItem === item){
            if(this.props.common.navigationList.nonBusinessMenu.subMenu.showExpandedMenuOnClose){
                this.props.showExpandedMenuOnClose(false);
                this.props.toggleNavigationWidth();
            }
            this.props.toggleSubNavigationVisibility();
        }
        else{
            this.props.setSelectedNonBusinessItem(item);
        }

    }

    onToggleCollapse(index){
        index = (index === this.state.activeMainNavIndex) ? -1 : index;
        this.setState({activeMainNavIndex: index});
    }

    getBusinessSectionItems() {
        let {activeMainNavIndex} = this.state;
        let { homeMetadata } = this.props.common;
        if (Utility.isObjectEmpty(homeMetadata)) return null;

        return homeMetadata.HomeSections.filter(x => x.IsBusinessSection).map((item, index) => {
            
            let iconName = item.IconName ? item.IconName : "catalog.svg";
            let businessSection = homeMetadata.Metadata.filter(x => x.HomeSectionSystemName === item.SystemName && x.IsAuthorized && x.View.filter(y => y.ShowInHome && y.IsAuthorized).length > 0);
        
            if(businessSection.length === 0) return null;
            let path = window.location.pathname.split('/');
            let documentId = path[path.length - 1];
            let isDocumentOpen = !!businessSection.find(x => x.DocumentId === documentId);
            let isActive = activeMainNavIndex === index || isDocumentOpen;
            let childItems = businessSection.map((childItem, childIndex) => {
                let className = "left-navigation-child-menu";
                className += (childItem.DocumentId === documentId) ? " left-navigation-li-active" : "";
                return (
                    <li className={className}
                        key={"child" + childItem.HomeSectionSystemName + childIndex}
                        onClick={this.onBusinessItemClicked.bind(this, childItem.DocumentId)}>
                        <Icons type="circle.svg" width="11px" height="12px"/>
                    </li>
                );
            });

            let parentDivClass = "d-flex flex-column text-center";
            parentDivClass += isDocumentOpen ? " active" : "";

            return (
                <li className={isActive ? "main-list-item-collapsed active" : "main-list-item-collapsed"} key={"main" + index}>
                    <div id={"main" + index} className={parentDivClass} onClick={this.onToggleCollapse.bind(this, index)}>
                            <span style={{paddingBottom: "1px"}}><Icons type={iconName} className="left-navigation-icon" width="22px" height="22px"/></span>
                             <span className="font-12">{item.DisplayName}</span>
                    </div>
                    <Collapse isOpen={isActive}>
                        <ul>
                            {childItems}
                        </ul>
                    </Collapse>
                </li>
            );
        })
    }

    getNonBusinessSectionItems() {
        let { homeMetadata } = this.props.common;
        let {subMenu} = this.props.common.navigationList.nonBusinessMenu;
        if (Utility.isObjectEmpty(homeMetadata)) return null;

        return <ul> {homeMetadata.HomeSections.filter(x => !x.IsBusinessSection).map((item, index) => {

            let iconPath = "/homeicons/" + (item.IconName ? item.IconName : "ccm.svg");

            return (<li key={"bottom" + index} id={"bottom" + index} onClick={this.onNonBusinessItemClicked.bind(this, item)} className={(subMenu.visible ? "active" : "")}>
                 <span className="setting-icon"><Icons type={item.IconName} src={iconPath} width="22px" height="22px"/></span>
            </li>);
        })}</ul>
    }
    hover(){
     
        // this.props.toggleSubNavigationVisibility();
        this.props.toggleNavigationWidth();
        this.props.setHoverOnCollapsed(true);    
    }

    getNonBusinessSectionItems() {
        let self = this;
        let {homeMetadata} = this.props.common;
        if (Utility.isObjectEmpty(homeMetadata)) return null;

        let children = [];
        homeMetadata.HomeSections.filter(x => !x.IsBusinessSection).map((item, index) => {
            let isVisible = self.isNonBusinessItemVisible(item);

            let iconPath = "/homeicons/" + (item.IconName ? item.IconName : "ccm.svg");

            if(isVisible) {
                children.push((<li key={"bottom" + index} id={"bottom" + index}
                                   onClick={this.onNonBusinessItemClicked.bind(this, item)}>
                <span className="setting-icon"><Icons type={item.IconName} src={iconPath} width="22px"
                                                      height="22px"/></span></li>));
            }
        });

        return (children.length > 0) ? <ul>{children}</ul> : null;
    }

    isNonBusinessItemVisible(item){
        let self = this;
        for (let i = 0; i < item.ChildSections.length; i++) {
            let childItem = item.ChildSections[i];

            if(childItem.ChildSections){
                for (let j = 0; j < childItem.ChildSections.length; j++) {
                    let nestedChildItem = childItem.ChildSections[j];

                    let schemaMetaData = self.props.common.homeMetadata.Metadata.find(x => x.HomeSectionSystemName === nestedChildItem.SystemName && x.IsAuthorized);
                    if(schemaMetaData) {
                        return true;
                    }
                }
            }
            else{
                let schemaMetaData = self.props.common.homeMetadata.Metadata.find(x => x.HomeSectionSystemName === childItem.SystemName && x.IsAuthorized);
                if(schemaMetaData) {
                    return true;
                }
            }
        }

        return false;
    }

    render(){
        let {subMenu} = this.props.common.navigationList.nonBusinessMenu;
        let {isNotSubscriptionUser} = this.state;
        let nonBusinessSections = this.getNonBusinessSectionItems();

        return (<div className="h-100" >
                  <div className="h-100" onClick={this.hover.bind(this)}> 
                        {this.state.isProcessing ? <AjaxLoader fullScreen={true}/> : null}
                      <div className="brand-div">
                      <div className="brand-name text-center">
                       <img src={shortBrandLogo} alt=""/>
                      </div>
                      </div>
                      <ul className="business-menu-list-collapsed">
                    {this.getBusinessSectionItems()}
                     </ul>
                      {(isNotSubscriptionUser && nonBusinessSections) ? <div className="setting">
                          {nonBusinessSections}
                      </div> : null}
        </div>
        <div>{(subMenu.visible ) ? <SubNavigation/> : null}</div>
       
        </div>


        );
        
    }
}

const mapStateToProp = (store) => ({
    common: store.common
});

const mapDispatchToProps = { setSelectedNavigationItem, setViewName, toggleSubNavigationVisibility, setSelectedNonBusinessItem, toggleNavigationWidth, showExpandedMenuOnClose, setHoverOnCollapsed };

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(CollapsedNavigation));