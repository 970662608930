import { AlertPopup } from "./AlertPopup";

export class Utility {

    static isObjectEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static setAuthenticationDetails(token, identityId, Role, contactId, username) {
        sessionStorage.setItem("AuthenticationToken", token);
        sessionStorage.setItem("IdentityId", identityId);
        sessionStorage.setItem("Role", Role);
        sessionStorage.setItem("ContactId", contactId);
        sessionStorage.setItem("UserName", username);
    }

    static getAuthenticationToken() {
        return sessionStorage.getItem("AuthenticationToken");
    }

    static setAuthenticationToken(token) {
        sessionStorage.setItem("AuthenticationToken", token);
    }

    static removeAuthenticationToken() {
        return sessionStorage.clear();
    }

    static getIdentityId() {
        return sessionStorage.getItem("IdentityId");
    }

    static getUserRole() {
        return sessionStorage.getItem("Role");
    }

    static getContactId() {
        return sessionStorage.getItem("ContactId");
    }

    static getUserName() {
        return sessionStorage.getItem("UserName");
    }

    static isNullOrEmpty(text) {
        return (text === undefined || text === '' || text === null);
    }

    static parseNLPResponse(response) {
        if (!response) return false;

        if (!response.data) return false;

        if (!response.data.IsSuccessStatusCode) {
            AlertPopup.showPopup("error", response.data.Content);
            return false;
        }

        return true;
    }

    static convertDate(value) {
        let timeStamp = Date.parse(value);
        if (!isNaN(timeStamp)) {
            let date = new Date(timeStamp);
            date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            const month = date.getMonth() + 1;
            return date.getDate() + "/" + month + "/" + date.getFullYear();
        }

        return "";
    }

    static convertDatewithTime(value) {
        let timeStamp = Date.parse(value);
        if (!isNaN(timeStamp)) {
            let date = new Date(timeStamp);
            date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            const month = date.getMonth() + 1;

            var ampm = (date.getHours() >= 12) ? "PM" : "AM";
            var hours = (date.getHours() >= 12) ? date.getHours() - 12 : date.getHours();

            return date.getDate() + "/" + month + "/" + date.getFullYear() + ' ' + hours + ':' + date.getMinutes() + ' ' + ampm;
        }

        return "";
    }

    static getFormattedDate(date) {
        var month = date.getMonth() + 1;
        month = month < 10 ? '0' + month : month;
        var day = date.getDate();
        var year = date.getFullYear();
        return year + "-" + month + "-" + day + "T00:00:00";
    }

    static setDateBoxUTCDate(value) {
        let date = new Date(value);
        let newDate = new Date(date.getTime() - Math.abs(date.getTimezoneOffset() * 60000));
        //let newDate = new Date(date.getTime() + date.getTimezoneOffset()*60000); 
        console.log(newDate);
        return newDate;
    }

    static setDateBoxLocaleDate(value) {
        let date = new Date(value);
        let newDate = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
        //let newDate = new Date(date.getTime() + date.getTimezoneOffset()*60000); 
        console.log(newDate);
        return newDate;
    }

    static getDateBoxLocaleDate(value) {
        let date = new Date(value);
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        console.log(newDate);
        return newDate;
    }

    static setBotToken(botToken) {
        sessionStorage.setItem("BotTokenKey", botToken);
    }

    static getBotToken() {
        return sessionStorage.getItem("BotTokenKey");
    }

    static assignVisibleIndexAndSort(data) {
        data.items.forEach((item, index) => {
            item.visibleIndex = (item.visibleIndex) ? item.visibleIndex : index;
        });

        data.items.sort((a, b) => a.visibleIndex - b.visibleIndex);

        return data;
    }

    static parseGraphDataFromAnalyticsQuery(data) {
        return data.map(x => {
            let newObj = {};
            Object.keys(x).forEach(key => {
                let value = x[key];
                key = key.replace("[", "");
                key = key.replace("]", "");
                newObj[key] = value;
            });
            return newObj;
        });
    }
}

export const customSections = [
    "headerMenu",
    "progressControl",
    "tabbed",
    "footerMenu"
];

export const downloadFile = (data, exportFormat) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `file${getExtension(exportFormat)}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
};

function getExtension(exportFormat) {
    switch (exportFormat) {
        case 'ExportToDocx':
            return '.docx';
        case 'ExportToRtf':
            return '.rtf';
        case 'ExportToPdf':
            return '.pdf';
        case 'ExportToXlsx':
            return '.xlsx';
        case 'ExportToCsv':
            return '.csv';
        default:
            return '.xls';
    }
}