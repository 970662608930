import qs from "query-string";
import crypto from 'crypto'

const enums = {
    FeedbackId1: 2,
    FeedbackId2: 3,
    ParentSchemaName: 4,
    DocumentNo: 5,
    Phone: 6,
    Visit: 7,
    DelearName: 8,
    Location: 9
};

export const decryptCrypto = async () => {
    try {
        const location = window.location.search;
        const cipher = qs.parse(location);
        // if (cipher.nps) { // kept commented code for non-encryption link
        const encryptedText = cipher.nps
        let bData = Buffer.from(encryptedText, 'Hex');
        var mykey = crypto.createDecipheriv('aes-192-ecb', 'mypassword12345678901234', '');
        var mystr = mykey.update(bData, 'utf8')
        mystr += mykey.final('utf8');
        if (mystr)
            return JSON.parse(mystr);
        else return undefined
        // }
        // else {
        // const location = window.location.pathname;
        // const parms = location.split("/");
        // console.log(parms[enums.ParentSchemaName])
        // var myObj = {
        //     "parentSchemaName": parms[enums.ParentSchemaName],
        //     "documentNo": parms[enums.DocumentNo],
        //     "phone": parms[enums.Phone],
        //     "visit": "1",
        //     "feedbackId1": parms[enums.FeedbackId1],
        //     "feedbackId2": parms[enums.FeedbackId2],
        //     "location": parms[enums.Visit],
        //     "delearName": parms[enums.DelearName]
        // }
        // return myObj;

        // }
    } catch (e) { console.log("Err", e) }


}