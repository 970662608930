import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { store } from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/theme.css';
import 'devextreme/dist/css/dx.common.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import './assets/css/dx.material.custom-scheme.css';
import 'open-iconic/font/css/open-iconic-bootstrap.min.css' // Import for OPEN ICONIC css file used by Dev Express react grid
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';

import { Config } from "./config";
import NavigationList from './common/Components/NavigationList/index';
import RightContainer from './RightContainer';

let config = new Config();
global.config = config;

const index = (
    <Provider store={store}>
        <Fragment>
            <RightContainer />
            <div id="popup" />
        </Fragment>
    </Provider>
);

ReactDOM.render(<div>{index}</div>, document.getElementById('root'));
