import { APIClient } from '../../../common/APIClient';
import Axios from 'axios';

const headerConfig = {
    headers : {
        'X-API-Key' : 'e4b00e5c-dc95-49ab-925e-da2ee46ed24f',
        'X-APP-ID' : 'e4b00e5c-dc95-49ab-925e-da2ee46ed24f',
        'SubscriptionId' : '4de83fd8-5127-4c88-8e4e-226e9d61ef62',
        'Authorization' : 'ApiKeyAuth'
    }
};

const requestObject = {
    DocumentId: process.env.NODE_ENV === "production" ? "71daa959-d87b-4629-a9a7-9c2c3590481b" : "71daa959-d87b-4629-a9a7-9c2c3590481b",
    FeedbackSchemaId : process.env.NODE_ENV === "production" ? "6d3f7609-14e0-4e4b-b9fc-8e152695a051" : "6d3f7609-14e0-4e4b-b9fc-8e152695a051",
    documentValiditySchemaId : process.env.NODE_ENV === "production" ? '5eb9da7f-c010-e7eb-4deb-469bb18fa6c6/ce0628fb-8b12-445e-9f60-3a6430dcc0f9' : '5eb9da7f-c010-e7eb-4deb-469bb18fa6c6/ce0628fb-8b12-445e-9f60-3a6430dcc0f9',
  }


// Showroom quesions
export const getQuestionsByLegacyId = async (legacyId) => {
    console.log("Questions DocumentId", requestObject.DocumentId);
    const response = await GetData(`${global.config.documentView}${requestObject.DocumentId}/GetQuestionsByLegacyId?legacyid=${legacyId}`);
    return response.data;
}

export const saveFeedback = async (data) =>{
    let url = `${global.config.documentApiUrl}${requestObject.FeedbackSchemaId}`;
    const response = await PostData(url, data);
    console.log(response);
    return response.data;
}

export const isFeedbackLinkValidForNPS = async (data) =>{
    let url = `${global.config.customAction}${requestObject.documentValiditySchemaId}/IsFeedbackLinkValidForNPS`;
    const response = await PostData(url, data);
    console.log(response);
    return response.data;
 }
 

const PostData = async (url,data) =>{
    return await Axios.post(url,data,headerConfig).catch((error) => {
        console.log(error);
    })        
}

export const updateFeedbackNPS = async (documentId, data) => {
    let url = `${global.config.documentApiUrl}${requestObject.FeedbackSchemaId}/${documentId}`;
    const response = await PutData(url,data);
    console.log(response);
    return response.data;
}


const PutData = async (url,data) =>{
    return await Axios.put(url,data,headerConfig).catch((error) => {
        console.log(error);
    })        
}


export const getFeedbackByDocument = async (documentSchemaId , documentId) => {
    let url = `${global.config.documentApiUrl}${documentSchemaId}/${documentId}`;
    let response = await GetData(url);
    console.log(response);
    if(response){
        response.data['Data'] = {} //response.data;
        response.data.Data["IsLinkValid"] =  true;
        response.data.Data["FeedbackData"] = response.data
        return response.data;
    }else{
        response = {};
        response['Message'] = "Link is not valid";
        return response;
    }
    //response.data.FeedbackData = response.data.FeedbackLines;
    
}


const GetData = async (url) =>{
    return await Axios.get(url,headerConfig).catch((error) => {
        console.log(error);
    })        
}




