export class CommonOptionService {

    static changePasswordFormItems() {
        return [
            {
                editorType: "dxTextBox",
                dataField: 'OldPassword',
                label: {
                    visible: false
                },
                editorOptions: {
                    mode: "password",
                    placeholder: "Old Password",
                    height: 44
                },
                validationRules: [{
                    type: "required",
                    message: "Old Password is required"
                }]
            },
            {
                editorType: "dxTextBox",
                dataField: 'NewPassword',
                label: {
                    visible: false
                },
                editorOptions: {
                    mode: "password",
                    placeholder: "New Password",
                    height: 44,
                    name: "resetPassword"
                },
                validationRules: [{
                    type: "required",
                    message: "New Password is required"
                }]
            },
            {
                editorType: "dxTextBox",
                dataField: 'ConfirmNewPassword',
                label: {
                    visible: false
                },
                editorOptions: {
                    mode: "password",
                    placeholder: "Confirm New Password",
                    height: 44
                },
                validationRules: [{
                    type: "compare",
                    comparisonTarget: function () {
                        var password = document.getElementsByName("resetPassword");
                        if (password.length > 0) {
                            return password[0].value;
                        }
                    },
                    message: "'New Password' and 'Confirm New Password' do not match."
                },
                {
                    type: "required",
                    message: "Confirm New Password is required"
                }]
            }
        ];
    }
}