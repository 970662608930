import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
    ReactPlugin,
    withAITracking
} from "@microsoft/applicationinsights-react-js";
import { globalHistory } from "@reach/router";
import { NPS_INSTRUMENTATION_KEY } from "./common/Constants";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: NPS_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory }
        }
    }
});
appInsights.loadAppInsights().addTelemetryInitializer(envelop => {
    envelop.tags = envelop.tags || {}
    envelop.tags['ai.device.roleName'] = 'Front End'
    envelop.tags['ai.device.roleInstance'] = 'frontend'
});
appInsights.trackPageView()
appInsights.trackPageViewPerformance()
appInsights.trackMetric()
appInsights.trackEvent()

export default (Component) => withAITracking(reactPlugin, Component);
export const ai = appInsights.appInsights;
// export { reactPlugin };
