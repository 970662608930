import React from "react";

import imgsuccessIcon from "../../../assets/images/successIconKTM.svg";

import KtmHeader from "./KtmHeader";

const KTMFeedbackGiven = ({ message }) => {
  return (

    <div style={{ position: "relative",height:"92vh" }}>
      <KtmHeader />
      <div className="mainContainer" style={{ position: "relative" }}>
            
            <div className="middleRate">
              <img src={imgsuccessIcon} width={80} />
            </div>
            <div className="thanksTxtSmall" style={{color:"#ffffff"}}>{message}</div>
          </div>
    </div>
    
  );
};

export default KTMFeedbackGiven;
