import { createReducer } from '../../reducers/createReducer';
import { CallCenterConstants } from './callCenterConstants';
const initialState ={
    activeTab : 1,
    campaignData : {},
    metaData:{}, 
    dataSource : {},
    campaignFilters : [],
    customerData :{
        customerInfo : {},
        previousCalls:[],
        rowData : {}
    },
    callingStatus :{
        connectionStatus : null,
        DocumentId : null,
        CustomerId : null,
        MobileNumber : null
    },
    showDisposition : false,
    currentUserConnectionStatus : false,
    campaignsTypesData : []
}

export const callCenterReducer = createReducer(initialState,{
    [CallCenterConstants.UI_SET_CUSTOMER_INFO]: setCustomerInfo,
    [CallCenterConstants.UI_SET_CAMPAIGN_DATA]: setCampaignData,
    [CallCenterConstants.UI_SET_ACTIVE_TAB] : setActiveTab,
    [CallCenterConstants.UI_SET_DATA_SOURCE] : setDataSource,
    [CallCenterConstants.UI_SET_CAMPAIGN_FILTER] : setCampaignFilter,
    [CallCenterConstants.UI_SET_CALL_CONNECT] : setCallConnect,
    [CallCenterConstants.UI_SET_SHOW_DISPOSITION] : setShowDisposition,
    [CallCenterConstants.UI_SET_CURRENT_USER_CALL_STATUS] : setCurrentUserConnectionStatus,
    [CallCenterConstants.UI_SET_CAMPAIGNS_TYPES_DATA] : setCampaignsTypesData

});

function setCustomerInfo(state, action){
    return{
        ...state,
        customerData : {
            ...state.customerData,
            customerInfo : action.payload.customerInfo,
            rowData : action.payload.rowData,
            previousCalls : action.payload.previousCalls
        }
    }
}

function setCampaignData(state, action){
    return{
        ...state,
        metaData: action.payload
    }
}

function setActiveTab(state, action){
    return{
        ...state,
        activeTab: action.payload
    }
}

function setDataSource(state, action){
    return{
        ...state,
        dataSource: action.payload
    }
}

function setCampaignFilter(state, action){
    return {
        ...state,
        campaignFilters : action.payload
    }
}

function setCallConnect(state, action){
    return{
        ...state,
        callingStatus :{
            ...state.callingStatus,
            connectionStatus : action.payload.connectionStatus,
            DocumentId : action.payload.DocumentId,
            CustomerId : action.payload.CustomerId,
            MobileNumber : action.payload.MobileNumber
        }
    }
}

function setShowDisposition(state, action){
    return{
        ...state,
        showDisposition : action.payload
    }
}

function setCurrentUserConnectionStatus(state,action){
    return{
        ...state,
        currentUserConnectionStatus : action.payload
    }
}

function setCampaignsTypesData(state, action) {
    return{
        ...state,
        campaignsTypesData : action.payload
    }
}
