import React from "react";
import { Popup } from "devextreme-react/popup";
import imgArrow from "../../../assets/images/arrow.svg";
import imgArrowBack from "../../../assets/images/arrowBack.svg";
import { Redirect } from "react-router-dom";
import MCHeader from "./MCHeader";

const AnswerComponent = ({
  gotoNext,
  gotoBack,
  feedback,
  onSaleAnswerSelect,
  IsOther,
  onSaleCommentChange,
  salesComments,
  submitDisable,
  delearName,
  location,
  showPopup,
  onHidePopup,
  onShownPopup,
 }) => {
  
  function onBlurHandler() {
    onHidePopup();
  }
  function onClickHandler() {
    onShownPopup();
  }

  return (
    <div className="main1">
      <MCHeader delearName={delearName} location={location} />
      <main className="mainContainer ">
        <form>
          <div style={{ position: "relative" }}>
            <div style={{ marginBottom: 20 }}>{feedback.DisplayName}</div>

            <div className="container middleMain">
              <ul>
                {feedback.FeebackAnswers.map(answers => {
                  return (
                    <li
                      key={answers.id}
                      onClick={() => onSaleAnswerSelect(answers)}
                      className={`contentBox ${
                        answers.IsSelected ? " selectedCol" : ""
                      }`}
                    >
                      <div className="showroomAccessibili">
                        <img
                          src={
                            answers.IsSelected
                              ? answers.answerImages.imageSelected
                              : answers.answerImages.image
                          }
                          width="30"
                        />
                      </div>
                      <div style={{ width: "90%" }}>
                        <div
                          style={{
                            fontSize: 13,
                            marginBottom: 5,
                            fontWeight: 600,
                            letterSpacing: 1
                          }}
                        >
                          {answers.DisplayHeader}
                        </div>
                        <div>{answers.DisplayDescription}</div>
                      </div>
                    </li>
                  );
                })}
                {IsOther ? (
                  <textarea
                    onChange={event => onSaleCommentChange(event.target.value)}
                    placeholder="Enter your suggestions"
                    onBlur={onBlurHandler}
                    onClick={onClickHandler}
                    value={salesComments}
                    autoFocus
                    rows="3"
                  ></textarea>
                ) : null}
              </ul>
            </div>
          </div>
        </form>
      </main>

      {!showPopup ? (
        <footer>
          <div className="submitBtBack" onClick={() => gotoBack()}>
            <img
              src={imgArrowBack}
              width={25}
              style={{ paddingRight: 10, marginLeft: -25 }}
            />
            BACK
          </div>

          <div
            className={submitDisable ? "submitBt disableBt" : "submitBt"}
            style={{ position: "absolute", right: 0 }}
            onClick={() => gotoNext('partialSave')}
          >
            <img
              src={imgArrow}
              width={25}
              style={{ paddingRight: 10, marginLeft: 25 }}
            />
            NEXT
          </div>
        </footer>
      ) : null}

      {/* <Popup
        visible={showPopup}
        onHiding={onHidePopup}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={false}
        width={300}
        height={243}
      >
        <div className="MCPop">
        <div className="titleKtm">Others</div>
          <textarea onChange={(event) => onSaleCommentChange(event.target.value)} placeholder="Enter your suggestions" value={salesComments} rows="6"></textarea>
          <button onClick={onHidePopup} > OK </button>
        </div>
      </Popup> */}
    </div>
  );
};

export default AnswerComponent;
