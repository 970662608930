export const CallCenterConstants = ({
    UI_SET_CUSTOMER_INFO : 'UI_SET_CUSTOMER_INFO',
    UI_SET_CAMPAIGN_DATA : 'UI_SET_CAMPAIGN_DATA',
    UI_SET_ACTIVE_TAB : 'UI_SET_ACTIVE_TAB',
    UI_SET_DATA_SOURCE : 'UI_SET_DATA_SOURCE',
    UI_SET_CAMPAIGN_FILTER : 'UI_SET_CAMPAIGN_FILTER',
    UI_SET_CALL_CONNECT : 'UI_SET_CALL_CONNECT',
    UI_SET_SHOW_DISPOSITION : 'UI_SET_SHOW_DISPOSITION',
    UI_SET_CURRENT_USER_CALL_STATUS : 'UI_SET_CURRENT_USER_CALL_STATUS',
    UI_SET_CAMPAIGNS_TYPES_DATA : 'UI_SET_CAMPAIGNS_TYPES_DATA'
});