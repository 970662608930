import React from "react";

import imgIcon from "../../../assets/images/remove.svg";

import KtmHeader from "./KtmHeader";

export const KTMLinkInvalid = ({ message }) => {
    return (

        <div style={{ position: "relative", height: "92vh" }}>
            <KtmHeader />
            <div className="mainContainer" style={{ position: "relative" }}>

                <div className="middleRate">
                    <img src={imgIcon} width={80} />
                </div>
                <div className="thanksTxtSmall" style={{ color: "#ffffff" }}>{message}</div>
            </div>
        </div>

    );
};

export default KTMLinkInvalid;
