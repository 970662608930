import React from "react";

import imgIcon from "../../../assets/images/remove.svg";

import MCHeader from "./MCHeader";

export const MCLinkInvalid = ({ message }) => {
    return (

        <div style={{ position: "relative", height: "92vh" }}>
            <MCHeader />
            <div className="mainContainer" style={{ position: "relative" }}>

                <div className="middleRate">
                    <img src={imgIcon} width={80} />
                </div>
                <div className="thanksTxtSmall" style={{ color: "#000000" }}>{message}</div>
            </div>
        </div>

    );
};

export default MCLinkInvalid;
