import React, { useState } from "react";
import CrossNew from '../../../assets/images/crossNew.svg'
import { logLinkClick } from "./AppInsightLog";

const ThankComponent = ({
  title,
  logo,
  huskarnaLogo,
  parentSchemaName,
  isKTM,
  color,
  borderColor,
  msg,
  className,
  link,
  showDownloadHeaderProp = true,
  appName,
}) => {
  const [showDownloadHeader, setShowDownloadHeader] = useState(showDownloadHeaderProp)

  const onDownloadClick = () => {
    logLinkClick(appName, 'appClick')
    window.open(link, '_blank')
  }

  return (
    <div
      className={className}>
      {
        showDownloadHeader ? <div
          style={{
            justifyContent: parentSchemaName == "Lead" ? 'end' : 'unset',
            padding: parentSchemaName !== "Lead" ? '0 15px' : 'unset',
            background: isKTM ? '#373f43' : '#fff'
          }}
          className='headerThanks'>
          {parentSchemaName !== "Lead" &&
            <img
              src={CrossNew} style={{ cursor: 'pointer' }}
              onClick={() => setShowDownloadHeader(false)} />
          }
          <img
            style={{
              marginLeft: 30,
              height: isKTM && parentSchemaName === "Lead" ? 55 : 40,
              marginRight: 3
            }}
            src={logo}
            className='headerThanksbajajLogo' />

          {huskarnaLogo &&
            <img
              style={{
                height: isKTM && parentSchemaName === "Lead" ? 55 : 40,
                marginRight: 3
              }}
              src={huskarnaLogo}
              className='headerThanksbajajLogo' />
          }

          {parentSchemaName !== "Lead" &&
            <>
              <div
                style={{ color: color }}
                className="headerText">
                {title}
              </div>

              <button
                className='button'
                style={{
                  color,
                  borderColor,
                  background: '#373f43'
                }}
                onClick={onDownloadClick}>
                Download
              </button>
            </>
          }
        </div> : <div />
      }
      <div className='thanksText'>{msg || 'THANK YOU'}</div>
    </div>
  );
};
export default ThankComponent;
