import React from "react";
import { Popup } from 'devextreme-react/popup';
import imgArrow from "../../../assets/images/arrow.svg";
import imgArrowBack from "../../../assets/images/arrowBack.svg";
import { Redirect } from "react-router-dom";
import KtmHeader from "./KtmHeader";

const KtmAnswerComponent = ({ gotoNext, gotoBack, feedback, onSaleAnswerSelect,
  IsOther, onSaleCommentChange, salesComments, submitDisable, delearName, location, 
  showPopup, onHidePopup, onShownPopup }) => {

    function onBlurHandler(){
      onHidePopup();
    }

    function onClickHandler(){
      onShownPopup();
    }

  return (
    <div className="main1">
       <KtmHeader delearName={delearName} location={location} />
      <main className="mainContainer">
        <form>
          <div style={{ position: "relative"}}>
            <div style={{ marginBottom: 20 }}>{feedback.DisplayName}</div>

            <div className="container middleMain">
              <ul>
                {feedback.FeebackAnswers.map(answers => {
                    return <li style={{color:"#ffffff"}} key={answers.id} onClick={() => onSaleAnswerSelect(answers)}
                      className={`contentBox ${answers.IsSelected ? ' selectedColK' : ''}`}>
                        <div className="showroomAccessibili">
                          <img src={answers.IsSelected? answers.answerImages.imageSelected : answers.answerImages.imageSelected} width="30" />
                        </div>
                        <div style={{width:"90%"}}>
                        <div style={{fontSize:13, marginBottom:5, fontWeight:600, letterSpacing:1}} >{answers.DisplayHeader}</div>
                        <div>{answers.DisplayDescription}</div>
                        </div></li>;
                    })}
                     {
                    IsOther ? 
                    <textarea 
                        onChange={(event) => onSaleCommentChange(event.target.value)} 
                        placeholder="Enter your suggestions"
                        onBlur={onBlurHandler} 
                        value={salesComments} 
                        onClick={onClickHandler}
                        style={{color:"#ffffff"}}
                        rows="3"
                        autoFocus
                    >
                  </textarea> 
                    : null
                }
                  </ul>
              </div>
            </div>
          </form>
        </main>
        {!showPopup ? <footer >
        
                <div className="submitBtBack"
                
                  onClick={() => gotoBack()} >
                  <img
                    src={imgArrowBack}
                    width={25}
                    style={{ paddingRight: 10, marginLeft: -25 }}
                  />
                  BACK
                </div> 

                <div
                  className={submitDisable ? "submitBtKTM disableBt" : "submitBtKTM"}
                  style={{ position: "absolute" , right:0}}
                  onClick={() => gotoNext('partialSave')}
                >
                  <img src={imgArrow} width={25} style={{ paddingRight: 10, marginLeft: 25 }}
                  />
                  NEXT
                </div></footer> : null }
       {/* <Popup
          visible={showPopup}
          onHiding={onHidePopup}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={false}
          width={300}
          height={243}
        >
          <div className="ktmPop">
            <div className="titleKtm">Others</div>
          <textarea onChange={(event) => onSaleCommentChange(event.target.value)} placeholder="Enter your suggestions" value={salesComments} rows="6"></textarea>
          <button onClick={onHidePopup} > OK</button>
          </div>
        </Popup> */}
      </div>
    );
                    };

  export default KtmAnswerComponent;
