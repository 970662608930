import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { PrivateRoute } from './common/Components/PrivateRoute';
import SystemAuthenticatedWrapper from "./common/Components/SystemAuthenticatedWrapper";
import ExternalPreview from "./routes/Designer/Components/Preview/ExternalPreview";
import ReferralThankYou from "./routes/Referral/index";
import Attachment from "./routes/Attachment/index";
import KTMFeedback from './routes/ExternalPages/KtmFeedback';
import KTMFinance from './routes/ExternalPages/KtmFinance';
import KTMMainContainer from './routes/NPS-2.0/KTMMainContainer';
import MCFeedback from './routes/ExternalPages/MCFeedback';
import MCFinance from './routes/ExternalPages/MCFinance';
import MCMainContainer from './routes/NPS-2.0/MCMainContainer';
import CVMainContainer from './routes/NPS-2.0/CVMainContainer';

const loadingComponent = ({ isLoading, error }) => {
    if (error) {
        console.log(error);
        return <div>Sorry, there was a problem loading the page.</div>;
    }
    else return null;
};

const AsyncLogin = Loadable({
    loader: () => import('./routes/Login/index'),
    loading: loadingComponent
});

const AsyncHome = Loadable({
    loader: () => import('./routes/Home/index'),
    loading: loadingComponent
});

const AsyncDashboard = Loadable({
    loader: () => import('./routes/Home/Components/Document/Components/Dashboard/index'),
    loading: loadingComponent
});

const AsyncTable = Loadable({
    loader: () => import('./routes/Home/Components/Document/Components/Table'),
    loading: loadingComponent
});

const AsyncDashboardCard = Loadable({
    loader: () => import('./routes/Home/Components/Document/Components/Dashboard/Components/DashboardTableCard'),
    loading: loadingComponent
});

const AsyncForm = Loadable({
    loader: () => import('./routes/Home/Components/Document/Components/CustomForm'),
    loading: loadingComponent
});

const AsyncCustomerForm = Loadable({
    loader: () => import('./routes/CustomForms/CustomerForm'),
    loading: loadingComponent
});

const AsyncDesigner = Loadable({
    loader: () => import('./routes/Designer/index'),
    loading: loadingComponent
});

const AsyncDeveloperConsole = Loadable({
    loader: () => import('./routes/DeveloperConsole/index'),
    loading: loadingComponent
});

const AsyncSchema = Loadable({
    loader: () => import('./routes/DeveloperConsole/Component/Schema/index'),
    loading: loadingComponent
});

const AsyncSubscription = Loadable({
    loader: () => import('./routes/DeveloperConsole/Component/Subscription/index'),
    loading: loadingComponent
});

const AsyncNLPConfiguration = Loadable({
    loader: () => import('./routes/DeveloperConsole/Component/NLPConfiguration/index'),
    loading: loadingComponent
});

const AsyncIbDashboard = Loadable({
    loader: () => import('./routes/BALIB/index'),
    loading: loadingComponent
})

const AsyncUbDashboard = Loadable({
    loader: () => import('./routes/Urbanite/index'),
    loading: loadingComponent
})
const AsyncUbReports = Loadable({
    loader: () => import('./routes/Urbanite/reports'),
    loading: loadingComponent
})
const AsyncThankYou = () => {
    return <SystemAuthenticatedWrapper component={< ReferralThankYou />} />
};

const AsyncDesignerPreview = () => {
    return <SystemAuthenticatedWrapper component={< ExternalPreview />} />
};

const AttachmentDownload = () => {
    return <SystemAuthenticatedWrapper component={<Attachment />} />
};

const AsyncPrivacyPolicy = Loadable({
    loader: () => import('./routes/PrivacyPolicy/index'),
    loading: loadingComponent
});

const AsyncReport = Loadable({
    loader: () => import('./routes/Report/index'),
    loading: loadingComponent
});

const AsyncCallCenter = Loadable({
    loader: () => import('./routes/CallCenter/index'),
    loading: loadingComponent
});

const AsyncCampaignForm = Loadable({
    loader: () => import('./routes/CustomForms/CampaignForm'),
    loading: loadingComponent
});

const AsyncDataDeletionUtilityForm = Loadable({
    loader: () => import('./routes/CustomForms/DataDeletionUtility'),
    loading: loadingComponent
});
const AsyncDataDeletionUtilityLogs = Loadable({
    loader: () => import('./routes/CustomForms/DataDeletionUtilityLogs'),
    loading: loadingComponent
});
const AsyncKtmFeedBack = () => {
    return <KTMFeedback />
};
const AsyncKtmFinance = () => {
    return <KTMFinance />
};
const AsyncKtmService = () => {
    return <KTMMainContainer />
}
const AsyncMCFeedBack = () => {
    return <MCFeedback />
};
const AsyncMCFinanceFeedBack = () => {
    return <MCFinance />
};
const AsyncMCService = () => {
    return <MCMainContainer />
}
const AsyncIBUContainer = () => {
    return <CVMainContainer />
}

export const Routes = () => {
    return (<Fragment>
        <PrivateRoute exact path={"/"} component={AsyncHome} />
        <PrivateRoute exact path={"/dashboard/:documentId"} component={AsyncDashboard} />
        <PrivateRoute exact path={"/table/:documentId/:viewId"} component={AsyncTable} />
        <PrivateRoute path={"/DashboardCard/:schemaId/:formId/:documentId?"} component={AsyncDashboardCard} />
        <PrivateRoute path={"/form/:schemaId/:formId/:documentId?"} component={AsyncForm} />
        <PrivateRoute path={"/customForm/customer/:schemaId/:documentId"} component={AsyncCustomerForm} />
        <PrivateRoute path={"/customForm/campaign/:schemaId/:documentId?"} component={AsyncCampaignForm} />
        <PrivateRoute path={"/customForm/dataDeletionUtility/:parentSchemaId/:schemaId/:documentId?"} component={AsyncDataDeletionUtilityForm} />
        <PrivateRoute path={"/customForm/dataDeletionUtilityLogs/:schemaId"} component={AsyncDataDeletionUtilityLogs} />
        <PrivateRoute exact path={"/ubdashboard/:documentId"} component={AsyncUbDashboard} />
        <PrivateRoute exact path={"/ibdashboard/:documentId"} component={AsyncIbDashboard} />
        <PrivateRoute exact path={"/ubreport"} component={AsyncUbReports} />
        <Route exact path={"/login"} component={AsyncLogin} />
        <PrivateRoute exact path={"/designer"} component={AsyncDesigner} />
        <Route exact path={"/preview"} component={AsyncDesignerPreview} />
        <Route exact path={"/thankyou"} component={AsyncThankYou} />
        <Route exact path={"/terms-and-conditions"} component={AsyncPrivacyPolicy} />
        <PrivateRoute exact path={"/developerConsole"} component={AsyncDeveloperConsole} />
        <PrivateRoute exact path={"/developerConsole/schema"} component={AsyncSchema} />
        <PrivateRoute exact path={"/developerConsole/subscription"} component={AsyncSubscription} />
        <PrivateRoute exact path={"/developerConsole/nlp"} component={AsyncNLPConfiguration} />
        <PrivateRoute exact path={"/ReportView/:schemaId/:viewId"} component={AsyncReport} />
        <PrivateRoute exact path={"/callCenter/:viewName"} component={AsyncCallCenter} />
        <Route exact path={"/attachment/:schemaId/:documentId/:attachmentId"} component={AttachmentDownload} require="" />
        <Route path={"/ktmfeedback"} component={AsyncKtmService} />
        <Route path={"/mcfeedback"} component={AsyncMCService} />
        <Route path={"/mcfinance"} component={AsyncMCFinanceFeedBack} />
        <Route path={"/ktmfinance"} component={AsyncKtmFinance} />
        <Route path={"/ktmservice"} component={AsyncKtmService} />
        <Route path={"/cvservice"} component={AsyncIBUContainer} />
        <Route path={"/cvfeedback"} component={AsyncIBUContainer} />
        <Route path={"/mcservice"} component={AsyncMCService} />
    </Fragment>);
};