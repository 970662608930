export const headersConfig = {
    MC: {
        'X-App-Id': '732cbaf3-a2fd-4a2e-9367-09e1b02f5764',
        'X-Api-Key': 'XkOwjPMHFL@OU1',
        'Subscription': 'aa3e1ce8-0fc8-4598-9a1c-4d4fca10f796',
        'Authorization': 'ApiKeyAuth'

    },
    KTM: {
        'X-App-Id': '732cbaf3-a2fd-4a2e-9367-09e1b02f5764',
        'X-Api-Key': 'XkOwjPMHFL@OU1',
        'Subscription': 'aa3e1ce8-0fc8-4598-9a1c-4d4fca10f796',
        'Authorization': 'ApiKeyAuth'

    },
    CV: {
        'X-App-Id': '732cbaf3-a2fd-4a2e-9367-09e1b02f5764',
        'X-Api-Key': 'XkOwjPMHFL@OU1',
        'Subscription': 'aa3e1ce8-0fc8-4598-9a1c-4d4fca10f796',
        'Authorization': 'ApiKeyAuth'

    }
}

export const requestObj = {
    MC: {
        SCHEMA_ID: '3027f977-db83-4d24-87fa-42505afc70c3',
        DOCUMENT_ID: 'a65ef66b-0138-4c79-a45e-eb775df69dd1',
    },
    KTM: {
        SCHEMA_ID: "3027f977-db83-4d24-87fa-42505afc70c3",
        DOCUMENT_ID: "2d60fc5d-f6d1-41e2-b0a5-2037f55c40aa",
    },
    CV: {
        SCHEMA_ID: '3027f977-db83-4d24-87fa-42505afc70c3',
        DOCUMENT_ID: '89e1d9b2-ae39-4d18-bbc7-41f4adb1b463',
    }
}

export const NPS_INSTRUMENTATION_KEY = "c18ffcd7-d03e-4306-8203-4facfa3ddf1c"

export const languageFields = [
    {
        "Name": "English",
        "Code": "EN",
        "LegacyId": "7890",
        "Index": "1"
    },
    {
        "Name": "हिन्दी",
        "Code": "HN",
        "LegacyId": "2344",
        "Index": "2"
    },
    {
        "Name": "मराठी",
        "Code": "MH",
        "LegacyId": "3453",
        "Index": "3"
    },
    {
        "Name": "ગુજરાતી",
        "Code": "GJ",
        "LegacyId": "2344",
        "Index": "4"
    },
    {
        "Name": "বাংলা",
        "Code": "EN",
        "LegacyId": "7890",
        "Index": "1"
    },
    {
        "Name": "తెలుగు",
        "Code": "HN",
        "LegacyId": "2344",
        "Index": "2"
    },
    {
        "Name": "தமிழ்",
        "Code": "MH",
        "LegacyId": "3453",
        "Index": "3"
    },
    {
        "Name": "ಕನ್ನಡ",
        "Code": "GJ",
        "LegacyId": "2344",
        "Index": "4"
    },
    {
        "Name": "മലയാളം",
        "Code": "MH",
        "LegacyId": "3453",
        "Index": "3"
    },
    {
        "Name": "ਪੰਜਾਬੀ",
        "Code": "GJ",
        "LegacyId": "2344",
        "Index": "4"
    }
]

export const TERMS_CONDITION = 'https://www.bajajauto.com/onlinebooking-tnc'
export const DISCLAIMER = 'https://www.bajajauto.com/disclaimer'
export const PRIVATE_POLICY = 'https://www.bajajauto.com/privacy-policy'
// export const BAJAJ_APP_LINK = 'https://play.google.com/store/apps/details?id=com.excellonsoft.bajajconnect&hl=en_IN'
export const BAJAJ_APP_LINK = 'https://www.bajajauto.com/mobile-apps'
// export const KTM_APP_LINK = 'https://play.google.com/store/apps/details?id=com.excellonsoft.bajajconnect&hl=en_IN'
export const KTM_APP_LINK = 'https://play.google.com/store/apps/details?id=com.excellonsoft.bajajcarektm&hl=en_IN'
export const SOMETHING_WENT_WRONG = 'Something went wrong'
export const CV = 'CV'
export const KTM = 'KTM'
export const MC = 'MC'
