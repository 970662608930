export const ReportConstant = ({
    UI_SET_REPORT_TAB_INDEX: 'UI_SET_REPORT_TAB_INDEX',
    UI_SET_DATE_TYPE: 'UI_SET_DATE_TYPE',
    UI_SET_FROM_DATE: 'UI_SET_FROM_DATE',
    UI_SET_TO_DATE: 'UI_SET_TO_DATE',
    UI_SET_DATE_FILTER: 'UI_SET_DATE_FILTER',
    UI_SET_DISTRIBUTOR: 'UI_SET_DISTRIBUTOR',
    UI_SET_COUNTRY: 'UI_SET_COUNTRY',
    UI_SET_DATA: 'UI_SET_DATA',
    UI_SHOW_VIEW: 'UI_SHOW_VIEW',
    UI_SET_NAVIGATION: 'UI_SET_NAVIGATION'
});