import notify from 'devextreme/ui/notify';

const alertOptions = (message) => ({
    closeOnClick: true,
    message: message,
    displayTime: 3000,
    position: {
        at: "right bottom",
        of: "#header",
       offset: '-155 30'
    },
    width: "300px"
});

export class AlertToast{
    static success(message){
        notify(alertOptions(message), "success");
    }

    static info(message){
        notify(alertOptions(message), "info");
    }

    static error(message){
        notify(alertOptions(message), "error");
    }

    static warning(message){
        notify(alertOptions(message), "warning");
    }
}