import { APIClient } from '../../../common/APIClient';
import Axios from 'axios';

const headerConfig = {
    headers : {
        'X-API-Key' : 'fced522d-5189-418f-832c-385c255465f2',
        'X-APP-ID' : 'fced522d-5189-418f-832c-385c255465f2',
        'SubscriptionId' : 'f7a8f1a9-6df1-4bbf-a04e-872f2d79269b',
        'Authorization' : 'ApiKeyAuth'
    }
};

const requestObject = {
    DocumentId: process.env.NODE_ENV === "production" ? "4cf15247-1cda-40ce-8b63-e9af0de82031" : "4cf15247-1cda-40ce-8b63-e9af0de82031",
    FeedbackSchemaId : process.env.NODE_ENV === "production" ? "1f83c827-96a6-471d-9201-e8bf2802e4a0" : "1f83c827-96a6-471d-9201-e8bf2802e4a0",
    documentValiditySchemaId : process.env.NODE_ENV === "production" ? '30d98baa-b1dd-4227-a0ca-a21f9af27b8d/30cc2187-d2e5-4b1b-ba54-cf3b4ab01da6' : '30d98baa-b1dd-4227-a0ca-a21f9af27b8d/30cc2187-d2e5-4b1b-ba54-cf3b4ab01da6',
}


// Showroom quesions
export const getQuestionsByLegacyId = async (legacyId) => {
    console.log("Questions DocumentId", requestObject.DocumentId);
    const response = await APIClient.get(`${global.config.documentView}${requestObject.DocumentId}/GetQuestionsByLegacyId?legacyid=${legacyId}`,headerConfig);
    return response.data;
}

export const saveFeedback = async (data) =>{
    let url = `${global.config.documentApiUrl}${requestObject.FeedbackSchemaId}`;
    const response = await PostData(url,data);
    //const response = await APIClient.post(`${global.config.documentApiUrl}${requestObject.FeedbackSchemaId}`,data, headerConfig);
    return response.data;
}

export const updateFeedbackNPS = async (documentId, data) => {
    let url = `${global.config.documentApiUrl}${requestObject.FeedbackSchemaId}/${documentId}`;
    const response = await PutData(url,data);
    return response.data;
}

export const isFeedbackLinkValidForNPS = async (data) =>{
   let url = `${global.config.customAction}${requestObject.documentValiditySchemaId}/IsFeedbackLinkValidForNPS`;
   const response = await PostData(url, data);
   return response.data;
}

export const getFeedbackByDocument = async (documentSchemaId , documentId) => {
    let url = `${global.config.documentApiUrl}${documentSchemaId}/${documentId}`;
    let response = await GetData(url);
    if(response){
        response.data['Data'] = {} //response.data;
        response.data.Data["IsLinkValid"] =  true;
        response.data.Data["FeedbackData"] = response.data;
        return response.data;
    }else{
        response = {};
        response['Message'] = "Link is not valid";
        return response;
    }
    //response.data.FeedbackData = response.data.FeedbackLines;
    
}

const PostData = async (url,data) =>{
    return await Axios.post(url,data,headerConfig).catch((error) => {
        console.log(error);
    })        
}

const PutData = async (url,data) =>{
    return await Axios.put(url,data,headerConfig).catch((error) => {
        console.log(error);
    })        
}

const GetData = async (url) =>{
    return await Axios.get(url,headerConfig).catch((error) => {
        console.log(error);
    })        
}




