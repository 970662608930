import React from "react";

import imgsuccessIcon from "../../../assets/images/successIcon.svg";

import imgbajajLogo from "../../../assets/images/bajajLogo.svg";

import imgecheck from "../../../assets/images/echeck.svg";

import MCHeader from "./MCHeader";

const ThankComponent = ({ gotoOk, thankyou , delearName, location }) => {
  return (

    <div style={{ position: "relative",height:"92vh" }}>
      <MCHeader  delearName={delearName} location={location} />
      <div className="mainContainer" style={{ position: "relative" }}>
            
            <div className="middleRate">
              <img src={imgsuccessIcon} width={80} />
            </div>

            <div className="thanksTxt">{thankyou && thankyou.DisplayName ? thankyou.DisplayName : null}</div>
            <div className="thanksTxtSmall">{thankyou && thankyou.DisplayHeader ? thankyou.DisplayHeader : null }</div>
            <div className="thanksTxtBajaj">{thankyou && thankyou.DisplayDescription ?thankyou.DisplayDescription : null }</div>
          </div>
    </div>
    
  );
};

export default ThankComponent;
