export const enumMenu = ({
    menuLength: 16
});

export const enumCallType =({
    Call : 1,
    Hold : 2,
    End : 3,
    Transfer : 4 
})

export const enumCallStatus = ({
    Disconnected : 0,
    Connected : 1,
    Incoming : 2,
    Dialing : 3,
    Offhook : 4,
    Missed : 5
})