import React from 'react';
import {LoadPanel} from "devextreme-react";
import {getAttachmentStore} from '../../common/dataStore';
import {withRouter} from "react-router-dom";

class Attachment extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isProcessing: true
        }
    }

    async componentDidMount(){
        await this.downloadAttachment();
        this.setState({isProcessing: false});
    }

    async downloadAttachment(){
        let {schemaId, documentId, attachmentId} = this.props.match.params;
        let response = await getAttachmentStore(schemaId, documentId).byKey(attachmentId);

        if(response && response.data) {
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `file.${response.data.type.split('/')[1]}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render(){
        return <div className="position-absolute attachment-container">
            <LoadPanel visible={this.state.isProcessing} />
        </div>
    }
}

export default withRouter(Attachment);