import React from 'react';

const MCHeader = ({delearName, location}) => {

    return(
        <header style={{ marginTop: 15 , textAlign:'center' }}>
        <div className="bajajLogo" style={{float:"left"}}>
        </div>
          <div style={{float:"right", paddingRight:15, textAlign:"right" }}>
            <div style={{fontSize:14, color :"#005dab" , textTransform:"uppercase", fontWeight:"600",letterSpacing:1}}>{delearName && delearName.length > 0 ? delearName.replace(/%20/g, ' ') : null}</div>
            <div style={{fontSize:12, color :"#005dab",fontWeight:"600", letterSpacing:1}}>{location && location.length > 0? location.replace(/%20/g, ' ') : null}</div>
          </div>
          <div style={{clear:"both"}}></div>
      </header>
    )

}

export default MCHeader;