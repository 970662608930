import React, { Component } from "react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai, getAppInsights } from '../../common/Components/TelemetryService';
import { withRouter } from 'react-router-dom';
import StarComponent from "./MCComponents/MCStars";
import AnswerComponent from "./MCComponents/MCAnswer";
import SuggestionsComponent from "./MCComponents/McSuggestions";
import Remarks from "./MCComponents/MCRemarks";
import FeedbackGiven from './MCComponents/MCFeedbackGiven';
import { AjaxLoader } from "../../common/Components/AjaxLoader";
import { decryptCrypto } from "./Crypto";
import { MCLinkInvalid } from "./MCComponents/MCInvalidLink";

import { AppName, ActionType, PageName, FeedbackType, logSubmitHandler, logErrorHandler, logRatingHandler, logQuestionHandler, logFreeTextHandler } from './services/AppInsightLog';

import {
  getQuestionsByLegacyId,
  saveFeedback,
  isFeedbackLinkValidForNPS,
  updateFeedbackNPS,
  getFeedbackByDocument
} from "./services/MCFeedbackService";

import ThankComponent from "./MCComponents/MCThankyou";

import "../../assets/css/feedback.css";
import NPSTelemetryProvider from "./NPSTelemetryProvider";
const FIRST_PAGE = 1;
const LAST_PAGE = 5;
const enums = {
  FeedbackId1: 2,
  FeedbackId2: 3,
  ParentSchemaName: 4,
  DocumentNo: 5,
  Phone: 6,
  Visit: 7,
  DelearName: 8,
  Location: 9
};

const DocumentSchemaId = "1f83c827-96a6-471d-9201-e8bf2802e4a0";
const _partDocumentId = "307bfe49-c91c-4a1b-b205-c8931b520ec4";

class MCFinance extends Component {
  appInsights = null;
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      feedbackQuestions: [],
      selectedFeedback: null,
      remarks: null,
      FinanceQuestions: [],
      FinanceRemarks: null,
      IsOther: false,
      salesComments: "",
      rating: null,
      finalComments: "",
      feedbackId1: null,
      feedbackId2: null,
      parentSchemaName: null,
      documentNo: null,
      phone: null,
      visit: null,
      delearName: null,
      location: null,
      answerSubmitDisable: true,
      loading: true,
      tempFinance: null,
      salesChecked: true,
      suggestion: null,
      thankyou: null,
      ratingQuestion: null,
      showPopup: false,
      feedbackGivenMessage: null,
      partialFeedbackResponse: null,
      updateFeedback: false,
      partialFeedbackData: null,
      partialDocumentId: null,
      partialDocumentNo: null,
      initialized: false,
      currentTime: new Date(),
      invalidLink: false,
      noQuestions: false
    };

    this.onRatingClick = this.onRatingClick.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoBack = this.gotoBack.bind(this);
    this.onSaleCommentChange = this.onSaleCommentChange.bind(this);
    this.onFinalCommentChange = this.onFinalCommentChange.bind(this);
    this.onSalesSwitchChange = this.onSalesSwitchChange.bind(this);
  }

  async componentDidMount() {
    await this.setQueryParms();
    this.setupBeforeUnloadListener();
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      this.logBeforeUnload();
    });
  };

  logBeforeUnload = () => {
    const pageName = this.getPageName(this.state.pageIndex);
    logSubmitHandler(this.appInsights, AppName.MC, FeedbackType.Sales, pageName, this.state.currentTime, ActionType.PageDropped);
  }

  setQueryParms = async () => {
    const decrypt = await decryptCrypto();
    if (decrypt) {
      const feedbackId1 = decrypt.feedbackId1;
      const feedbackId2 = decrypt.feedbackId2;
      const parentSchemaName = decrypt.parentSchemaName;
      const documentNo = decrypt.documentNo;
      const phone = decrypt.phone;
      const visit = decrypt.visit;
      if (feedbackId1 && feedbackId2 && parentSchemaName && documentNo) {
        this.setState({
          feedbackId1: decrypt.feedbackId1,
          feedbackId2: decrypt.feedbackId2,
          parentSchemaName: decrypt.parentSchemaName,
          documentNo: decrypt.documentNo,
          phone: decrypt.phone,
          visit: decrypt.visit,
          delearName: decrypt.delearName,
          location: decrypt.location,
        });
        await this.isFeedbackGiven(parentSchemaName, documentNo, phone, visit);
      }
    } else {
      this.setState({ invalidLink: true, loading: false });
    }
  };

  async getFinanceQuestions() {
    const response = await getQuestionsByLegacyId(this.state.feedbackId1);
    if (response && response.IsRequestSuccessfull) {
      if (response.Data.length > 0) {
        const feedbackQuestions = response.Data[0].FeedbackQuestions;
        const suggestion = feedbackQuestions.find(x => x.FrontEndCode === "Suggestion");
        const thankyou = feedbackQuestions.find(x => x.FrontEndCode === "ThankYou");
        const ratingQuestion = feedbackQuestions.find(x => x.FrontEndCode === "CallBack");
        this.setPartialFeedbackData(feedbackQuestions);

        this.setState({
          feedbackQuestions: feedbackQuestions,
          remarks: response.Data[0].Remarks,
          suggestion: suggestion,
          thankyou: thankyou,
          ratingQuestion: ratingQuestion,
          loading: false
        });
      }
      else { this.setState({ noQuestions: true, loading: false }); }
    }
  }

  async isFeedbackGiven(parentSchemaName, documentNo, phone, visit) {
    const data = {
      ParentSchemaName: parentSchemaName,
      ParentDocumentName: documentNo,
      MobileNo: phone,
      Source: "NPS",
      Visit: visit,
      DocumentSchemaId
    }
    const response = await isFeedbackLinkValidForNPS(data);
    //const response = await getFeedbackByDocument(DocumentSchemaId, _partDocumentId); 
    console.log(response);
    if (response && response.Data) {
      if (response.Data.IsLinkValid) {
        this.getFinanceQuestions();
        this.setGivenFeedbackData(response.Data);
      } else {
        this.setState({ feedbackGivenMessage: response.Message, pageIndex: 6, loading: false });
      }

    } else {
      this.setState({ feedbackGivenMessage: response.Message, pageIndex: 6, loading: false });
    }
  }

  setGivenFeedbackData(feedbackResponse) {
    if (feedbackResponse && feedbackResponse.FeedbackData) {
      this.setState({
        partialFeedbackData: feedbackResponse.FeedbackData,
        rating: feedbackResponse.FeedbackData.FeedbackStarRating,
        partialDocumentId: feedbackResponse.FeedbackData.DocumentId,
        partialDocumentNo: feedbackResponse.FeedbackData.DocumentNo
      });
    }
  }

  setPartialFeedbackData(feedbackQuestions) {
    const { rating, partialFeedbackData } = this.state;
    if (rating && partialFeedbackData) {

      let answers = [];
      if (rating >= 0 && rating <= 6)
        answers = feedbackQuestions.find(x => x.FrontEndCode === "Detractors");
      else if (rating >= 7 && rating <= 8)
        answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
      else if (rating >= 9 && rating <= 10)
        answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
      const feedbackAnswers = answers.FeebackAnswers.sort(
        (a, b) => a.DisplayIndex - b.DisplayIndex
      );
      answers.FeebackAnswers = feedbackAnswers;
      const answersLength = answers.FeebackAnswers.length;
      answers.FeebackAnswers.map((a, index) => {
        let item = partialFeedbackData.FeedbackLines.find(
          x => x.AnswerId === a.DocumentId,
        );
        a.IsSelected = item ? true : false;
        a.answerImages = this.getAnswerImage(a.DisplayIndex);
        if (answersLength === index + 1) {
          a.IsOthersRow = true;
          if (item) {
            this.setState({ IsOther: true, salesComments: item.CustomerRemarks });
          }
        }
      });
      const selectedLength = answers.FeebackAnswers.filter(
        o => o.IsSelected === true
      );
      this.setState({
        selectedFeedback: answers,
        answerSubmitDisable: selectedLength.length > 0 ? false : true,
        updateFeedback: true,
        pageIndex: 3
      });
    }
  }

  onRatingClick = item => {
    const feedback = this.getFeedbackAnswers(item);
    this.setState({ selectedFeedback: feedback, rating: item, IsOther: false, salesComments: null });
    logRatingHandler(this.appInsights, AppName.MC, FeedbackType.Sales, item)
  };

  gotoNext = async (type, financeData) => {
    let { pageIndex } = this.state;
    let partialSave = true;
    if (type === "save") {
      partialSave = false;
      await this.saveFeedbacks(partialSave);
    } else {
      if (this.state.pageIndex === LAST_PAGE) return;
      const value = parseInt(this.state.pageIndex) + 1;
      if (type === "partialSave")
        await this.saveFeedbacks(partialSave);
      else
        this.setState({ pageIndex: value });
    }
    this.logUserEvents(pageIndex);
  };

  gotoBack = () => {
    const backClick = true;
    const { pageIndex } = this.state;
    if (pageIndex === FIRST_PAGE) return;
    const value = parseInt(pageIndex) - 1;
    this.setState({ pageIndex: value });
    this.logUserEvents(pageIndex, backClick)
  };

  onSaleAnswerSelect = item => {
    const { selectedFeedback } = this.state;
    const answers = selectedFeedback.FeebackAnswers;
    const index = answers.findIndex(a => a.id === item.id);
    answers[index].IsSelected = !answers[index].IsSelected;
    if (answers.length === index + 1) {
      answers[index].IsOthersRow = true;
      this.setState({ IsOther: !this.state.IsOther, showPopup: answers[index].IsSelected });
    }
    this.setState([...this.state.selectedFeedback.FeebackAnswers, answers]);
    const selectedLength = answers.filter(o => o.IsSelected === true);
    if (selectedLength.length > 0) {
      this.setState({ answerSubmitDisable: false });
    } else {
      this.setState({ answerSubmitDisable: true });
    }
    this.logQuestionEvent(answers[index]);
  };

  logQuestionEvent(Answer) {
    const { selectedFeedback } = this.state;
    logQuestionHandler(this.appInsights,
      AppName.MC,
      FeedbackType.Sales,
      selectedFeedback.DisplayName,
      Answer.DisplayHeader,
      Answer.DisplayDescription,
      Answer.IsSelected
    )
  }

  gotoOk = () => { };
  onSaleCommentChange = value => {
    this.setState({ salesComments: value, showPopup: true });
  };
  onFinalCommentChange = value => {
    this.setState({ finalComments: value, showPopup: true });
  };

  getFeedbackAnswers(item) {
    const { feedbackQuestions } = this.state;
    let answers = [];
    if (item >= 0 && item <= 6)
      answers = feedbackQuestions.find(x => x.FrontEndCode === "Detractors");
    else if (item >= 7 && item <= 8)
      answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
    else if (item >= 9 && item <= 10)
      answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
    const feedbackAnswers = answers.FeebackAnswers.sort((a, b) => a.DisplayIndex - b.DisplayIndex)
    answers.FeebackAnswers = feedbackAnswers;
    answers.FeebackAnswers.map((a, index) => {
      a.IsSelected = false;
      a.answerImages = this.getAnswerImage(a.DisplayIndex);
    });
    return answers;
  }

  getAnswerImage(index) {
    switch (index) {
      case 1:
        return {
          image: require("../../assets/images/financingIco.svg"),
          imageSelected: require("../../assets/images/financingIco_Selected.svg")
        };
      case 2:
        return {
          image: require("../../assets/images/financeIco.svg"),
          imageSelected: require("../../assets/images/financeIco_Selected.svg")
        };
      case 3:
        return {
          image: require("../../assets/images/financeTermIco.svg"),
          imageSelected: require("../../assets/images/financeTermIco_Selected.svg")
        };
      case 4:
        return {
          image: require("../../assets/images/loanIco.svg"),
          imageSelected: require("../../assets/images/loanIco_Selected.svg")
        };
      case 5:
        return {
          image: require("../../assets/images/loanPayment.svg"),
          imageSelected: require("../../assets/images/loanPayment_Selected.svg")
        };
      case 6:
        return {
          image: require("../../assets/images/loanClousre.svg"),
          imageSelected: require("../../assets/images/loanClousre_Selected.svg")
        };
      case 7:
        return {
          image: require("../../assets/images/othersIco.svg"),
          imageSelected: require("../../assets/images/othersIco_Selected.svg")
        };
      default:
        return {
          image: require("../../assets/images/othersIco.svg"),
          imageSelected: require("../../assets/images/othersIco_Selected.svg")
        };
    }
  }

  saveFeedbacks = async (partialSave) => {
    //console.log("financeData", financeData);
    this.setState({ loading: true });
    try {
      const response = await this.savefinanceData(partialSave);
      console.log("finance save response", response);
      if (response.IsRequestSuccessfull) {
        if (partialSave) {
          const value = parseInt(this.state.pageIndex) + 1;
          const { partialDocumentId, partialDocumentNo } = this.state;
          this.setState({
            pageIndex: value, loading: false, updateFeedback: true,
            partialDocumentId: response.DocumentId ? response.DocumentId : partialDocumentId,
            partialDocumentNo: response.DocumentNo ? response.DocumentNo : partialDocumentNo
          });
        } else {
          this.setState({ pageIndex: 4, loading: false });
          logFreeTextHandler(this.appInsights, AppName.MC, FeedbackType.Sales, this.state.finalComments)
        }
      }
    } catch (ex) {
      this.setState({ loading: false });
      logErrorHandler(this.appInsights, ex);
      console.log(ex);
    }

  };

  logUserEvents(pageIndex, backClick = false) {
    let actionType = null;
    const { currentTime } = this.state;
    let pageName = this.getPageName(pageIndex);
    if (backClick) {
      actionType = ActionType.BackBtn;
    } else {
      actionType = pageIndex === 3 ? ActionType.SubmitBtn : ActionType.NextBtn;
    }
    logSubmitHandler(this.appInsights, AppName.MC, FeedbackType.Sales, pageName, currentTime, actionType);
    this.setState({ currentTime: new Date() });
  }

  getPageName(pageIndex) {
    switch (pageIndex) {
      case 1:
        return PageName.Rating;
      case 2:
        return PageName.Questions;
      case 3:
        return PageName.Feedback;
      case 4:
        return PageName.Thanks;
      default:
        return PageName.Rating;
    }
  }

  savefinanceData = async (partialSave) => {
    const { finalComments, rating, updateFeedback } = this.state;
    const showroomFeedbacks = this.getFinanceAnswers();
    const data = {
      IsFinalFeedbackSubmitted: partialSave ? false : true,
      Remarks: finalComments,
      FeedbackStarRating: rating,
      ParentSchemaName: this.state.parentSchemaName,
      DocumentNo: this.state.documentNo,
      ParentDocumentName: this.state.documentNo,
      MobileNo: this.state.phone,
      Recipient: this.state.phone,
      FeedBackID: this.state.feedbackId1,
      Source: "NPS",
      Visit: this.state.visit,
      FeedbackLines: showroomFeedbacks
    };

    if (updateFeedback) {
      const { partialDocumentId, partialDocumentNo } = this.state;
      data['DocumentNo'] = partialDocumentNo;
      console.log(data);
      return await updateFeedbackNPS(partialDocumentId, data);
    } else {
      return await saveFeedback(data);
    }
  };

  getFinanceAnswers = () => {
    let feedbackAnswers = [];
    const { selectedFeedback, salesComments } = this.state;
    const answers = selectedFeedback.FeebackAnswers.filter(x => x.IsSelected);
    answers.map(answer => {
      let remarks = answer.IsOthersRow ? salesComments : "";
      feedbackAnswers.push({
        QuestionId: selectedFeedback.DocumentId,
        QuestionCode: selectedFeedback.Code,
        QuestionName: selectedFeedback.DisplayName,
        AnswerId: answer.DocumentId,
        AnswerCode: answer.Code,
        AnswerName: answer.DisplayName,
        id: answer.id,
        DocumentId: answer.DocumentId,
        SchemaId: answer.SchemaId,
        CustomerRemarks: remarks
      });
    });
    const callBackAnswer = this.getCallBackOption();
    feedbackAnswers.push(callBackAnswer);
    return feedbackAnswers;
  };

  getCallBackOption() {
    const { ratingQuestion, salesChecked } = this.state;
    const questionCode = salesChecked === true ? 'Yes' : 'No';
    const ratingAnswer = ratingQuestion.FeebackAnswers.find(x => x.Code === questionCode);
    return {
      QuestionId: ratingQuestion.DocumentId,
      QuestionCode: ratingQuestion.Code,
      QuestionName: ratingQuestion.DisplayName,
      AnswerId: ratingAnswer.DocumentId,
      AnswerCode: ratingAnswer.Code,
      id: ratingAnswer.id,
      DocumentId: ratingAnswer.DocumentId,
      SchemaId: ratingAnswer.SchemaId,
    }
  }

  onSalesSwitchChange = checked => {
    this.setState({ salesChecked: checked });
  };

  onHidePopup = () => {
    this.setState({ showPopup: false })
  }

  onShownPopup = () => {
    this.setState({ showPopup: true });
  }

  render() {
    const { pageIndex, invalidLink, noQuestions } = this.state;
    // if (this.state.loading) return <AjaxLoader />;
    return (
      <NPSTelemetryProvider after={() => { this.appInsights = getAppInsights() }}>
        <div style={{ fontFamily: "arial", color: "#000000", margin: "auto", maxWidth: 450 }}>
          {invalidLink ? (<MCLinkInvalid message="Link is invalid." />) : null}
          {noQuestions ? (<MCLinkInvalid message="Something went wrong." />) : null}
          {pageIndex === 1 && invalidLink == false && noQuestions == false ? (
            <StarComponent
              stars={this.state.list}
              onRatingClick={this.onRatingClick}
              gotoNext={this.gotoNext}
              remarks={this.state.remarks}
              rating={this.state.rating}
              delearName={this.state.delearName}
              location={this.state.location}
            />
          ) : null}

          {this.state.loading ? <AjaxLoader /> : null}

          {pageIndex === 2 ? (
            <AnswerComponent
              gotoNext={this.gotoNext}
              gotoBack={this.gotoBack}
              feedback={this.state.selectedFeedback}
              onSaleAnswerSelect={this.onSaleAnswerSelect}
              IsOther={this.state.IsOther}
              onSaleCommentChange={this.onSaleCommentChange}
              salesComments={this.state.salesComments}
              submitDisable={this.state.answerSubmitDisable}
              delearName={this.state.delearName}
              location={this.state.location}
              onHidePopup={this.onHidePopup}
              showPopup={this.state.showPopup}
              onShownPopup={this.onShownPopup}
            />
          ) : null}
          {pageIndex === 3 ? (
            <Remarks
              gotoNext={this.gotoNext}
              gotoBack={this.gotoBack}
              onSalesSwitchChange={this.onSalesSwitchChange}
              salesChecked={this.state.salesChecked}
              onFinalCommentChange={this.onFinalCommentChange}
              finalComments={this.state.finalComments}
              suggestion={this.state.suggestion}
              ratingQuestion={this.state.ratingQuestion}
              delearName={this.state.delearName}
              location={this.state.location}
              showPopup={this.state.showPopup}
              onHidePopup={this.onHidePopup}
              onShownPopup={this.onShownPopup}
            />
          ) : null}

          {
            pageIndex === 4 ?
              <ThankComponent
                gotoOk={this.gotoOk}
                thankyou={this.state.thankyou}
                delearName={this.state.delearName}
                location={this.state.location}
              /> : null}

          {pageIndex === 6 ? <FeedbackGiven message={this.state.feedbackGivenMessage} /> : null}

        </div>
      </NPSTelemetryProvider>
    );
  }
}
export default withRouter(withAITracking(ai.reactPlugin, MCFinance));