import Axios from 'axios';
import { requestObj, MC, headersConfig } from './Constants'

export const checkNPSLinkIsValid = async (dataObj, currentReq = MC) => {
    const requestObject = requestObj[currentReq];
    const url = `${global.config.npscustomAction}${requestObject.SCHEMA_ID}/CheckNPSLinkIsValid`
    const response = await PostData(url, dataObj, currentReq);
    return response?.data;
}

export const fetchQuestionsData = async (data, currentReq = MC) => {
    const requestObject = requestObj[currentReq];
    const url = `${global.config.npscustomAction}${requestObject.SCHEMA_ID}/GetNPSQuestionByLegacyId`
    const response = await PostData(url, data, currentReq);
    return response?.data?.Data;
}

export const saveQuestionsData = async (data, currentReq = MC) => {
    const requestObject = requestObj[currentReq];
    const url = `${global.config.npscustomAction}${requestObject.SCHEMA_ID}/CreateNPSFeedback`
    const response = await PostData(url, data, currentReq);
    return response?.data;

}
const PostData = async (url, data, type = MC) => {
    const headerConfig = { headers: headersConfig[type] }
    return await Axios.post(url, data, headerConfig).catch((error) => {
        console.log(error);
    })
}

const PutData = async (url, data) => {
    return await Axios.put(url, data).catch((error) => {
        console.log(error);
    })
}

const GetData = async (url) => {
    return await Axios.get(url).catch((error) => {
        console.log(error);
    })
}
