
import {SeverityLevel} from '@microsoft/applicationinsights-web';

export const AppName = {
    KTM: "KTM",
    MC: "MC"
}
export const FeedbackType = {
    Sales: "SALES",
    Service: "SERVICE",
    Lead: "LEAD",
    Finance: "Finance"
}
export const PageName = {
    Rating: "RATING",
    Questions: "QUESTIONS",
    FinanceQuestions: "FINANCEQUESTIONS",
    Feedback: "FEEDBACK",
    Thanks: "THANKS"
}
export const ActionType = {
    NextBtn: "NEXTBUTTON",
    SubmitBtn: "SUBMITBUTTON",
    BackBtn: "BACKBUTTON",
    PageDropped : "PAGE DROPPED" 
}
/**
     * Initialize the Application Insights class
     * @param {string} appName - Appliction name like "MC","PB","KTM"
     * @param {string} fbType -  Type of feedback "Sales,Service,Finance"
     * @param {string} pageName -  Name of the current page or View"
     * @param {date} startTime -  Start time of the View"
     * @param {date} endTime -  End time of the View"
     * @param {string} actionName -  Type of action perform"
     * @return {void}
     */
export const logSubmitHandler = async (appInsight, appName, fbType, pageName, startTime, actionName) => {
    let endTime = new Date();
    let duration = endTime.getTime() - startTime.getTime();
    let logData = {
        feedbackType: fbType,
        pageName: pageName,
        duration: duration,
        actionName: actionName
    }

    appInsight.trackEvent({name : appName, properties : logData});
};

export const logFreeTextHandler = async (appInsight, appName, fbType,Comments) => {
    let event="Final Comments"
    let logData = {
        feedbackType: fbType,
        actionName:event,
        Comments:Comments
    }
    appInsight.trackEvent({name : appName, properties : logData});
};

export const logRatingHandler = async (appInsight, appName, fbType, ratingValue) => {
    let event="Rating Click"
    let logData = {
        feedbackType: fbType,
        actionName:event,
        ratingValue:ratingValue
    }
    appInsight.trackEvent({name : appName, properties : logData});
};

export const logQuestionHandler = async (appInsight, appName, fbType, Questions,Answer,AnswerDescription,actionType) => {
    let event="Answer Selection"
    let logData = {
        feedbackType: fbType,
        actionName:event,
        Questions:Questions,
        Answer:Answer,
        AnswerDescription: AnswerDescription,
        actionType:actionType,
    }
    appInsight.trackEvent({name : appName, properties : logData});
};

export const logTraceHandler = async (appInsight, message) => {
    appInsight.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
};

/**
     * Initialize the Application Insights class
     * @param {string} appName - Appliction name like "MC","PB","KTM"
     * @param {string} fbType -  Type of feedback "Sales,Service,Finance"
     * @param {string} pageName -  Name of the current page or View"
     * @param {date} startTime -  Start time of the View"
     * @param {date} endTime -  End time of the View"
     * @param {string} actionName -  Type of action perform"
     * @param {object} error -  Error detatil object"
     * @param {enum} errorLevel -  Error SeverityLevel"
     * @return {void}
     */
export const logErrorHandler = async (appInsight, error) => {
    appInsight.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
};

