import React, { Component, Fragment} from 'react';
import { Autocomplete ,DataGrid, TextBox } from 'devextreme-react';
import {
    connect
} from "react-redux";

import serachmy from '../../assets/img/serachmyellow.svg';
import {
    setSelectedSearchData
} from './commonActions';

import {
    getAutoSearchStore,
} from '../dataStore';

import './search.css';

let heaerText = "";

class Seacrh extends Component {
    constructor() {
        super();
        this.state = {
            dataSource: {}
        }
        this.autoCompleteChange = this.autoCompleteValueChange.bind(this);
        this.autoCompleteEnter = this.autoCompleteEnter.bind(this);
        this.autoCompleteClick = this.autoCompleteClick.bind(this);

        this.clearTextClick = this.clearTextClick.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
        
    }

    async componentDidMount() {
        let dataSource = {};
        let {
            documentId,
            viewDocumentId
        } = this.props.common.searchBarOptions;
        dataSource = getAutoSearchStore(documentId, viewDocumentId);
        this.setState({
            dataSource,
        });
    }

    async autoCompleteValueChange(e) {
        heaerText = e.value ? e.value : '';
        this.setState({displayName : e.value});
    }

    async autoCompleteEnter(e){
        this.props.setSelectedSearchData(e.component._options.text);
    }

    async autoCompleteClick(e) {
        this.setState({
            displayName: e.itemData.FirstName
        });
        this.props.setSelectedSearchData(e.itemData.FirstName);
        //this.props.setSelectedSearchData(e.itemData);
    }

    async clearTextClick() {
        this.setState({displayName: ""});
        this.props.setSelectedSearchData('',true);
    }

    dataGridRender(data) {
        if (data["RowType"]){
            return ( <div className="hdColumn"> 
                    <div className='divRound' >Pulsar</div>
                    <div className='divRound' >Platina</div>
                    <div className='divRound' >KTM</div>
                    <div style={{clear:'both'}}></div>
                    <div className="headerTitle"> Name </div> 
                    <div className="headerTitle"> Email </div> 
                    <div className="headerTitle"> Mobile </div> 
                    <div style={{clear:"both"}}></div>
                </div>
        )}
        else{
            return <div className="dataContainer" >
                    <div className="searchData">{data.FirstName} </div> 
                    <div className="searchData">{data.Email} </div> 
                    <div className="searchData">{data.MobilePhone} </div> 
            </div> 
        }
    }

    openedPopup = (e) =>{
        let element = e.component._list._$container[0];
        element.classList.add("custom-container");
    }

    render() {
        let {
            dataSource
        } = this.state;
        return ( 
            <Fragment>
                <div className = "customeSearch d-inline-block headerSearch" >
                    <button type = "submit" >
                    <img src = {serachmy} alt = "" />
                    </button> 
                   
                    <Autocomplete 
                        id='searchHeader'
                        dataSource = {dataSource}
                        itemComponent = {this.dataGridRender}
                        value = {this.state.displayName}
                        width = {500}
                        onValueChanged = {this.autoCompleteChange}
                        onEnterKey = {this.autoCompleteEnter}
                        onItemClick = {this.autoCompleteClick}
                        showClearButton = {false}
                        searchExpr = {'FirstName'}
                        onOpened = {(e) => {this.openedPopup(e)}}
                    /> 
                    <span className = "closebtn" onClick = {this.clearTextClick}title = "Close Overlay" > × </span>      
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    common: store.common
});

const mapDispatchToProps = {
    setSelectedSearchData
}

export default connect(mapStateToProps, mapDispatchToProps)(Seacrh);