import React from 'react';
import { connect } from 'react-redux';
import ExpandedNavigation from './Components/ExpandedNavigation';
import CollapsedNavigation from './Components/CollapsedNavigation';

class NavigationList extends React.Component {
    isHideHeader(pathName) {
        return (pathName.indexOf('login') >= 0
            || pathName.indexOf('preview') >= 0
            || pathName.indexOf('thankyou') >= 0
            || pathName.indexOf('terms-and-conditions') >= 0
            || pathName.indexOf('attachment') >= 0
            || pathName.indexOf('ktmfeedback') >= 0
            || pathName.indexOf('ktmfinance') >= 0
            || pathName.indexOf('mcfeedback') >= 0
            || pathName.indexOf('ktmservice') >= 0
            || pathName.indexOf('mcservice') >= 0
            || pathName.indexOf('mcfinance') >= 0);
    }
    render() {
        let hideNavigation = this.isHideHeader(window.location.pathname);

        if (hideNavigation) return null;

        let { isNavigationCollapsed } = this.props.common.navigationList;
        let className = "left-navigation";
        className += (isNavigationCollapsed) ? " collapsed" : " expanded";
        let navigation = !isNavigationCollapsed ? <ExpandedNavigation /> : <CollapsedNavigation />;

        return (
            <div className={className}>
                {navigation}
            </div>
        );

    }
}



const mapStateToProp = (store) => ({
    common: store.common
});

export default connect(mapStateToProp, null)(NavigationList);