import {createStore, applyMiddleware, compose} from 'redux';
import {rootReducer} from './reducers/rootReducer';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

const enhancers = [];
const middleware = [thunk,promise()];
if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export const store = (
    createStore(
        rootReducer,
        composedEnhancers,
    ));