import React from "react";
import imgSad from "../../../assets/images/sadKTM.svg";
import imgsmileBig from "../../../assets/images/smileBig.svg";
import imgsmileSmall from "../../../assets/images/smileSmallKTM.svg";
import imgArrow from "../../../assets/images/arrow.svg";
import { Redirect } from "react-router-dom";
import KtmHeader from "./KtmHeader";

const KtmStarComponent = ({ stars, onRatingClick, gotoNext, rating, remarks, delearName, location, showEmoji }) => {
  let starClass = null;
  let smileClass = "imgSmileBigGreyK";
  showEmoji = {
    visibility: true
  }

  if (showEmoji.visibility == true) {
    smileClass = "null";
    starClass = "rateBoxGoldenYellow";
  }
  else if (rating === null) {
    smileClass = "imgSmileBigGreyK";
  } else if (rating >= 0 && rating <= 6) {
    starClass = " rateBoxOrangeK";
    smileClass = "imgSadBigK";
  } else if (rating >= 7 && rating <= 8) {
    starClass = " rateBoxActive";
    smileClass = "imgSmileAverageK";
  } else if (rating >= 9 && rating <= 10) {
    starClass = " rateBoxGreen";
    smileClass = "imgSmileHappyK";
  } else {
    smileClass = "imgSmileBigGreyK";
  }

  return (
    <div style={{ position: "relative", height: "92vh", maxWidth: 450, margin: "auto", }} >
      <KtmHeader delearName={delearName} location={location} />
      <main className="mainContainer" style={{ flex: "1", display: "flex", height: "calc(100vh - 25%)" }}>
        <form>
          <div>
            <div>{remarks}</div>


            <div className="mainscreen" >
              <div className={"middleRate " + smileClass}></div>

              <div className="ratingContainer">
                {stars.map(item => (
                  <div
                    onClick={() => onRatingClick(item)}
                    className={
                      rating == item ? "rateBox " + starClass : "rateBox"
                    }
                    key={item}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="ratingLavels">
                <div className="lowestRate">
                  <div>
                    <img src={imgSad} width={40} style={{ paddingLeft: 15 }} />
                  </div>
                  <div style={{ width: 50, textAlign: "center", marginTop: 8 }}>
                    Not at all likely
                </div>
                </div>

                <div className="highestRate">
                  <div>
                    <img
                      src={imgsmileSmall}
                      width={40}
                      style={{ paddingRight: 15 }}
                    />
                  </div>
                  <div
                    style={{
                      width: 50,
                      textAlign: "center",
                      float: "right",
                      marginTop: 8
                    }}
                  >
                    Extremely likely
                </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "30%",
                marginRight: -15,
                marginLeft: -15,
                position: "absolute",
                bottom: 40,
                width: "100%"
              }}
            >
              {/* <div className="submitBtBack" onClick={() => this.gotoBack()} >
                                    <img src={imgArrow} width={18} style={{ paddingRight: 10, marginLeft: 25 }} />
                                     Back
                                </div> */}


            </div>
          </div>
        </form>
      </main>


      <footer>

        <div className={rating !== null ? "submitBtKTM" : "submitBtKTM disableBt"}
          onClick={() => gotoNext()}
          style={{
            width: "49%", position: "absolute",
            right: 0,
            bottom: 21
          }}
        >
          <img
            src={imgArrow}
            width={25}
            style={{ paddingRight: 10, marginLeft: 25 }}
          />
                NEXT
              </div>
      </footer>
    </div>
  );
};

export default KtmStarComponent;

