import React from 'react';
import {customAction} from "../../common/dataStore";
import queryString from "querystring";
import {withRouter} from "react-router-dom";
import {LoadPanel} from "devextreme-react";

class Referral extends React.Component {
    constructor(){
        super();

        this.state = {
            isProcessing: true
        }
    }
    async componentDidMount() {
        let {SchemaId, DocumentId} = queryString.parse(this.props.location.search.substr(1));
        let response = await customAction(SchemaId, DocumentId, "PleaseContactMe");
        this.setState({isProcessing: false});
    }

    render() {
        return <div className="position-absolute thank-you-div-container">
            <LoadPanel visible={this.state.isProcessing} />
            <div className="thank-you-div h-100 w-100"/>
        </div>
    }
}

export default withRouter(Referral);