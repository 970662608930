import CustomStore from 'devextreme/data/custom_store';
import DataSource from "devextreme/data/data_source";
import { APIClient } from './APIClient';
import { store } from '../store';
import { setSelectedSchema } from "./Components/commonActions";
import Axios from "axios";
import { Utility } from "./utility";
import { AlertPopup } from "./AlertPopup";

export const getSchemaDataSourceStore = (subscriptionId, allSchemaVisibility = false) => {
    return new CustomStore({
        load: async function (loadOptions) {
            let filter = loadOptions.filter ? loadOptions.filter : [];
            let customFilter = [];
            customFilter.push(["SubscriptionId", "=", subscriptionId]);
            if (!allSchemaVisibility) {
                customFilter.push("and");
                customFilter.push(["IsRootDocument", "=", "true"]);
            }
            filter = (filter.length > 0) ? [filter, "and", customFilter] : customFilter;
            let parameters = {
                filter: JSON.stringify(filter),
                sort: loadOptions.sort ? JSON.stringify(loadOptions.sort) : "",
                skip: loadOptions.skip,
                take: loadOptions.take,
                requireTotalCount: true,
                group: loadOptions.group ? JSON.stringify(loadOptions.group) : "",
                totalSummary: loadOptions.totalSummary,
                groupSummary: loadOptions.groupSummary
            };
            let response = await APIClient.get(global.config.schemaDataSourceApiUrl, {
                params: parameters
            });

            if (response && response.data) {
                //response.data.data = response.data.data.filter(x=>x.IsRootDocument);
                return {
                    data: (response.data.data) ? response.data.data : [],
                    totalCount: response.data.totalCount
                };
            }

        },
        remove: function (document) {
            return APIClient.delete(global.config.schemaApiUrl + document.DocumentId);
        },
    });
};

export const getSchemaStore = (documentId = "") => {
    return new CustomStore({
        load: async function () {
            let response = await APIClient.get(global.config.schemaApiUrl + documentId);

            if (response) {
                store.dispatch(setSelectedSchema(response.data));
                return {
                    data: response.data,
                };
            }
        },

        insert: async function (document) {
            return await APIClient.post(global.config.schemaApiUrl, document);
        },
        remove: async function (key) {
            return await APIClient.delete(global.config.schemaApiUrl + key);
        },
        update: async function (key, document) {
            return await APIClient.put(global.config.schemaApiUrl + key, document);
        }
    });
};

export const getSchemaLocalizedStore = (documentId = "") => {
    return new CustomStore({
        load: async function (loadOptions, language = "") {
            let parameters = {
                LanguageCode: language
            };
            let response = await APIClient.get(global.config.schemaLocalizedApiUrl + documentId, {
                params: parameters
            });

            if (response) {
                //store.dispatch(setSelectedSchema(response.data));
                return {
                    data: response.data,
                };
            }
        },

        insert: async function (document) {
            return await APIClient.post(global.config.schemaLocalizedApiUrl, document);
        },
        remove: async function (key) {
            return await APIClient.delete(global.config.schemaLocalizedApiUrl + key);
        },
        update: async function (key, document) {
            return await APIClient.put(global.config.schemaLocalizedApiUrl + key, document);
        }
    });
};

export const getDocumentStore = (schemaId) => {
    return new CustomStore({
        load: async function (documentId) {
            let response = await APIClient.get(global.config.documentApiUrl + schemaId + "/" + documentId);

            if (response) {
                return response.data;
            }
        },

        insert: async function (document) {
            let response = await APIClient.post(global.config.documentApiUrl + schemaId, document);
            if (response) {
                return response.data;
            }
        },
        remove: async function (documentId) {
            return await APIClient.delete(global.config.documentApiUrl + schemaId + "/" + documentId);
        },
        update: async function (document) {
            let response = await APIClient.put(global.config.documentApiUrl + schemaId + "/" + document.DocumentId, document);
            if (response) {
                return response.data;
            }
        }
    });
};

export const getDocumentDataStore = (schemaId, actionName, data) => {
    return new CustomStore({
        load: async function (loadOptions) {
            let parameters = {
                filter: loadOptions.filter ? JSON.stringify(loadOptions.filter) : "",
                sort: loadOptions.sort ? JSON.stringify(loadOptions.sort) : "",
                skip: loadOptions.skip,
                take: loadOptions.take,
                group: loadOptions.group ? JSON.stringify(loadOptions.group) : "",
                totalSummary: loadOptions.totalSummary,
                groupSummary: loadOptions.groupSummary
            };

            if (loadOptions.searchValue) {
                parameters.filter = (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [];
            }

            let response = await APIClient.get(global.config.documentView + schemaId + "/" + actionName, { params: data });

            if (response) {
                return {
                    data: (response.data.Data) ? response.data.Data : [],
                    totalCount: (response.data.TotalCount) ? response.data.TotalCount : 0,
                    summary: response.data.summary
                };
            }
        }
    });
};

export const getDocumenViewtDataSourceStore = (schemaId, viewId) => {
    return new CustomStore({
        load: async function (loadOptions) {
            let parameters = {
                filter: loadOptions.filter ? JSON.stringify(loadOptions.filter) : "",
                sort: loadOptions.sort ? JSON.stringify(loadOptions.sort) : "",
                skip: loadOptions.skip,
                take: loadOptions.take,
                group: loadOptions.group ? JSON.stringify(loadOptions.group) : "",
                totalSummary: loadOptions.totalSummary,
                groupSummary: loadOptions.groupSummary
            };

            if (loadOptions.searchValue) {
                parameters.filter = (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [];
            }

            let response = await APIClient.get(global.config.documentViewApiUrl + schemaId + "/" + viewId,
                { params: parameters });
            if (response) {
                return {
                    data: (response.data.Data) ? response.data.Data : [],
                    totalCount: (response.data.TotalCount) ? response.data.TotalCount : 0,
                    summary: response.data.summary
                };
            }
        },
        insert: function (document) {
            return APIClient.post(global.config.documentApiUrl + schemaId, document);
        },
        remove: function ({ DocumentId }) {
            return APIClient.delete(global.config.documentApiUrl + schemaId + "/" + DocumentId);
        },
        update: async function (document) {
            return await APIClient.put(global.config.documentApiUrl + schemaId + "/" + document.DocumentId, document);
        }
    });
};
export const getAutoSearchStore = (schemaId, viewId) => {
    return new CustomStore({
        load: async function (loadOptions) {
            let parameters = {
                filter: loadOptions.filter ? JSON.stringify(loadOptions.filter) : "",
                sort: loadOptions.sort ? JSON.stringify(loadOptions.sort) : "",
                skip: loadOptions.skip,
                take: loadOptions.take,
                group: loadOptions.group ? JSON.stringify(loadOptions.group) : "",
                totalSummary: loadOptions.totalSummary,
                groupSummary: loadOptions.groupSummary
            };

            if (loadOptions.searchValue) {
                parameters.filter = (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [];
            }

            let response = await APIClient.get(global.config.documentViewApiUrl + schemaId + "/" + viewId,
                { params: parameters });
            if (response) {
                if (response.data.Data) {
                    response.data.Data.unshift({ RowType: "Header" })
                }
                return {
                    data: (response.data.Data) ? response.data.Data : [],
                    totalCount: (response.data.TotalCount) ? response.data.TotalCount : 0,
                    summary: response.data.summary
                };
            }
        }
    });
};
export const getDocumentViewStore = (schemaId, viewId, data = {}) => {
    let url = global.config.documentView + schemaId + "/" + viewId;

    return new CustomStore({
        load: async function (loadOptions) {
            let parameters = {
                filter: loadOptions.filter ? JSON.stringify(loadOptions.filter) : "",
                sort: loadOptions.sort ? JSON.stringify(loadOptions.sort) : "",
                skip: loadOptions.skip,
                take: loadOptions.take,
                group: loadOptions.group ? JSON.stringify(loadOptions.group) : "",
                totalSummary: loadOptions.totalSummary,
                groupSummary: loadOptions.groupSummary,
                ...data
            };

            if (loadOptions.searchValue) {
                parameters.filter = (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [];
            }

            let response = await APIClient.get(url,
                { params: parameters });
            if (response) {
                return {
                    data: (response.data.Data) ? response.data.Data : [],
                    totalCount: (response.data.TotalCount) ? response.data.TotalCount : 0,
                    summary: response.data.summary
                };
            }
        },
        insert: function (document) {
            Object.keys(data).map(key => {
                document[key] = data[key];
            });
            return APIClient.post(global.config.documentApiUrl + schemaId, document);
        },
        remove: function ({ DocumentId }) {
            Object.keys(data).map(key => {
                document[key] = data[key];
            });
            return APIClient.delete(global.config.documentApiUrl + schemaId + "/" + DocumentId);
        },
        update: async function (document) {
            return await APIClient.put(global.config.documentApiUrl + schemaId + "/" + document.DocumentId, document);
        }
    });
};

export const getDocumentGridDataSource = (schemaId, viewId, data = {}) => {
    let url = global.config.documentView + schemaId + "/" + viewId;

    return new CustomStore({
        load: async function (loadOptions) {
            let parameters = {
                filter: loadOptions.filter ? JSON.stringify(loadOptions.filter) : "",
                sort: loadOptions.sort ? JSON.stringify(loadOptions.sort) : "",
                skip: loadOptions.skip,
                take: loadOptions.take,
                group: loadOptions.group ? JSON.stringify(loadOptions.group) : "",
                totalSummary: loadOptions.totalSummary,
                groupSummary: loadOptions.groupSummary,
                ...data
            };

            if (loadOptions.searchValue) {
                parameters.filter = (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [];
            }

            let response = await APIClient.get(url,
                { params: parameters });
            if (response) {
                return {
                    data: (response.data.Data) ? response.data.Data : [],
                    totalCount: -1,
                    summary: response.data.summary
                };
            }
        },
        insert: function (document) {
            Object.keys(data).map(key => {
                document[key] = data[key];
            });
            return APIClient.post(global.config.documentApiUrl + schemaId, document);
        },
        remove: function ({ DocumentId }) {
            Object.keys(data).map(key => {
                document[key] = data[key];
            });
            return APIClient.delete(global.config.documentApiUrl + schemaId + "/" + DocumentId);
        },
        update: async function (document) {
            return await APIClient.put(global.config.documentApiUrl + schemaId + "/" + document.DocumentId, document);
        }
    });
};

export const getSchemaFieldDataSourceStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.schemaApiUrl + state.DocumentId);
        if (getResponse && getResponse.data) {
            store.dispatch(setSelectedSchema(getResponse.data));
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Fields = state.Fields ? state.Fields : [];
            return {
                data: state.Fields,
                totalCount: state.Fields.length
            }
        },

        insert: async (document) => {
            state.Fields.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.schemaApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Fields = state.Fields.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let index = state.Fields.findIndex(x => x.DocumentId === document.DocumentId);
            state.Fields[index] = document;
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        }
    });
};

export const getSchemaViewDataSourceStore = (data) => {
    let state = data;

    async function updateData(document) {
        let getResponse = await APIClient.get(global.config.schemaApiUrl + state.DocumentId);
        if (getResponse && getResponse.data) {
            store.dispatch(setSelectedSchema(getResponse.data));
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Views = state.Views ? state.Views : [];
            return {
                data: state.Views,
                totalCount: state.Views.length
            }
        },
        insert: async (document) => {
            state.Views.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.schemaApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Views = state.Views.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        },
        update: async (document, cellInfo) => {
            let index = state.Views.findIndex(x => x.DocumentId === document.DocumentId);
            state.Views[index] = document;
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData(document);
            return response;
        }
    });
};

export const getSchemaActionDataSourceStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.schemaApiUrl + state.DocumentId);
        if (getResponse && getResponse.data) {
            store.dispatch(setSelectedSchema(getResponse.data));
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Actions = state.Actions ? state.Actions : [];
            return {
                data: state.Actions,
                totalCount: state.Actions.length
            }
        },

        insert: async (document) => {
            state.Actions.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.schemaApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Actions = state.Actions.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        }
    });
};

export const getSchemaFormDataSourceStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.schemaApiUrl + state.DocumentId);
        if (getResponse && getResponse.data) {
            store.dispatch(setSelectedSchema(getResponse.data));
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Forms = state.Forms ? state.Forms : [];
            return {
                data: state.Forms,
                totalCount: state.Forms.length
            }
        },

        insert: async (document) => {
            state.Forms.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.schemaApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Forms = state.Forms.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        }
    });
};

export const getSchemaPropertiesDataSourceStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.schemaApiUrl + state.DocumentId);
        if (getResponse && getResponse.data) {
            store.dispatch(setSelectedSchema(getResponse.data));
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Properties = state.Properties ? state.Properties : [];
            return {
                data: state.Properties,
                totalCount: state.Properties.length
            }
        },

        insert: async (document) => {
            state.Properties.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.schemaApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Properties = state.Properties.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        }
    });
};

export const getSchemaLanguagesDataSourceStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.schemaApiUrl + state.DocumentId);
        if (getResponse && getResponse.data) {
            store.dispatch(setSelectedSchema(getResponse.data));
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Languages = state.Languages ? state.Languages : [];
            return {
                data: state.Languages,
                totalCount: state.Languages.length
            }
        },

        insert: async (document) => {
            state.Languages.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.schemaApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Languages = state.Languages.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.schemaApiUrl + state.DocumentId, state);
            await updateData();
            return response;
        }
    });
};

export const addDocument = async (schemaId, document) => {
    let response = await APIClient.post(global.config.documentApiUrl + schemaId, document);
    if (response) {
        return response.data;
    }
};

export const updateDocument = async (schemaId, document) => {
    let response = await APIClient.put(global.config.documentApiUrl + schemaId + "/" + document.DocumentId, document);
    if (response) {
        return response.data;
    }
};

export const getSchemaViewsMetaData = async () => {
    let response = await APIClient.get(global.config.schemaViewApiUrl);
    if (response) {
        return response.data;
    }
};

export const getReferenceDocumentDataSource = (schemaId, viewId) => {
    let subscriptionSchema = store.getState().common.homeMetadata.Metadata.find(x => x.SystemName === "Subscription");
    let subscriptionSchemaIds = subscriptionSchema.DocumentId;
    const SubscriptionViewIds = subscriptionSchema.View.find(x => x.SystemName === "GetUserRoles").DocumentId;
    return new CustomStore({
        load: async function (loadOptions) {
            let parameters = {
                filter: (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [],
                skip: loadOptions.skip,
                take: loadOptions.take,
            };


            if (subscriptionSchemaIds.includes(schemaId) && SubscriptionViewIds.includes(viewId)) {
                let res = await APIClient.get(global.config.subscriptionApiUrl);
                if (res && res.data) {
                    return (res.data.Roles) ? res.data.Roles : []
                }
            }

            let response = await APIClient.get(global.config.documentViewApiUrl + schemaId + "/" + viewId,
                { params: parameters });
            if (response) {
                return (response.data.Data) ? response.data.Data : [];
            }
        },
        byKey: async function (key) {

            if (subscriptionSchemaIds.includes(schemaId) && SubscriptionViewIds.includes(viewId)) {
                let res = await APIClient.get(global.config.subscriptionApiUrl);
                if (res && res.data) {
                    let data = res.data.Roles.find(x => x.DocumentId === key);
                    return (data) ? data : [];
                }
            }

            let response = await APIClient.get(global.config.documentViewApiUrl + schemaId + "/" + viewId, { params: { filter: JSON.stringify(["DocumentId", "contains", key]) } });
            if (response && response.data) {
                return (response.data.Data) ? response.data.Data : [];
            }
        }
    });
};

export const saveSchema = async (document) => {
    let response = await APIClient.post(global.config.schemaApiUrl, document);
    if (response && response.data && response.data.IsRequestSuccessfull) {
        let refreshSchema = await APIClient.get(global.config.schemaApiUrl + response.data.DocumentId);
        if (refreshSchema && refreshSchema.data) {
            store.dispatch(setSelectedSchema(refreshSchema.data));
        }
    }
};

export const getAttachmentStore = (schemaId, documentId) => {

    return new CustomStore({
        load: async function () {
            //alert("=====Onload====");
            console.log('global.config.attachmentApiUrl + schemaId + "/" + documentId', global.config.attachmentApiUrl + schemaId + "/" + documentId);
            let response = await APIClient.get(global.config.attachmentApiUrl + schemaId + "/" + documentId);
            console.log('response', response);
            if (response && response.data) {
                return {
                    data: response.data,
                    totalCount: response.data.length,
                };
            }
        },
        byKey: async function (attachmentId) {
            console.log("=====byKey===attachmentId=", attachmentId);
            const test = await APIClient.get(global.config.resourceApiUrl + schemaId + "/" + documentId + "/" + attachmentId, { responseType: 'blob' });
            return test
        }
    });
};
export const getcdnAttachmentStore = (schemaId, documentId) => {
    //alert("=====CDNOnload====",global.config.cdnattachmentApiUrl + schemaId + "/" + documentId);
    return new CustomStore({
        load: async function () {


            let response = await APIClient.get(global.config.cdnattachmentApiUrl + schemaId + "/" + documentId);

            if (response && response.data) {
                return {
                    data: response.data,
                    totalCount: response.data.length,
                };
            }
        },
        byKey: async function (attachmentId) {
            //   alert("=====byKey====");
            return await APIClient.get(global.config.cndresourceApiUrl + schemaId + "/" + documentId + "/" + attachmentId, { responseType: 'blob' });
        }
    });
};

function setAttachmentHeader(DisplayName, SystemName, AssetType, AssetData) {
    if (!Utility.isNullOrEmpty(DisplayName)) Axios.defaults.headers.common['DisplayName'] = DisplayName;
    if (!Utility.isNullOrEmpty(SystemName)) Axios.defaults.headers.common['SystemName'] = SystemName;
    if (!Utility.isNullOrEmpty(AssetType)) Axios.defaults.headers.common['AssetType'] = AssetType;
    if (!Utility.isNullOrEmpty(AssetData)) Axios.defaults.headers.common['AssetData'] = AssetData;
}

function unsetAttachmentHeaders() {
    delete Axios.defaults.headers.common['DisplayName'];
    delete Axios.defaults.headers.common['SystemName'];
    delete Axios.defaults.headers.common['AssetType'];
    delete Axios.defaults.headers.common['AssetData'];
}

export const saveAttachment = async (schemaId, documentId, document, DisplayName, SystemName, AssetType, AssetData) => {
    // alert(" ===== on Save=====");
    let formData = new FormData();
    formData.append('Data', document);

    setAttachmentHeader(DisplayName, SystemName, AssetType, AssetData);
    let result = await APIClient.post(global.config.resourceApiUrl + schemaId + "/" + documentId, formData);
    unsetAttachmentHeaders();
    return result;
};

export const cdnsaveAttachment = async (schemaId, documentId, document, DisplayName, SystemName, AssetType, AssetData) => {
    // alert(" ===== cdnsaveAttachment=====");
    let formData = new FormData();
    formData.append('Data', document);

    setAttachmentHeader(DisplayName, SystemName, AssetType, AssetData);
    // alert("*******URL and data******", global.config.cndresourceApiUrl + schemaId + "/" + documentId, formData);
    let result = await APIClient.post(global.config.cndresourceApiUrl + schemaId + "/" + documentId, formData);
    console.log("*******result******", result);
    unsetAttachmentHeaders();
    return result;
};

export const updateAttachment = async (schemaId, documentId, attachmentId, document, DisplayName, SystemName, AssetType, AssetData) => {
    let formData = new FormData();

    setAttachmentHeader(DisplayName, SystemName, AssetType, AssetData);
    let result = await APIClient.put(global.config.resourceApiUrl + schemaId + "/" + documentId + "/" + attachmentId, formData);
    unsetAttachmentHeaders();
    return result;
};

export const deleteAttachment = async (schemaId, documentId, attachmentId, isFromCdn = false) => {
    console.log("<<<<<<<<<<<<<<<<isFromCdn", isFromCdn)
    const URL = isFromCdn ? global.config.cdnattachmentApiUrl : global.config.attachmentApiUrl;
    console.log("<<<<<<<<<<<<<<<<<<URL", URL)
    return await APIClient.delete(URL + schemaId + "/" + documentId + "/" + attachmentId);
};

export const customAction = async (schemaId, documentId, actionName, data = {}) => {
    return await APIClient.post(global.config.customAction + schemaId + "/" + documentId + "/" + actionName, data);
};

export const DownloadViewData = async (schemaId, viewName, exportFormat = "ExportToXls", data = {}) => {
    let params = {
        ...data
    };
    let response = await APIClient.get(global.config.downloadViewData + schemaId + "/" + viewName + "?ExportFormat=" + exportFormat, { responseType: 'blob', params });
    let responseText = await new Response(new Blob([response.data])).text();
    let responseData = null;
    try {
        responseData = JSON.parse(responseText);
    }
    catch (e) {
        // skip on err
    }
    if (responseData && responseData.IsRequestSuccessfull === false) {
        AlertPopup.showPopup("message", responseData.Message);
        return;
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `file${getExtension(exportFormat)}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
};

function getExtension(exportFormat) {
    switch (exportFormat) {
        case 'ExportToDocx':
            return '.docx';
        case 'ExportToRtf':
            return '.rtf';
        case 'ExportToPdf':
            return '.pdf';
        case 'ExportToXlsx':
            return '.xlsx';
        case 'ExportToCsv':
            return '.csv';
        default:
            return '.xls';
    }
}

export const getSubscriptionLanguangeDataStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.subscriptionApiUrl);
        if (getResponse && getResponse.data) {
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.LangaugeTables = state.LangaugeTables ? state.LangaugeTables : [];
            return {
                data: state.LangaugeTables,
                totalCount: state.LangaugeTables.length
            }
        },

        insert: async (document) => {
            state.LangaugeTables.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.subscriptionApiUrl, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.subscriptionApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.LangaugeTables = state.LangaugeTables.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        }
    });
};

export const getSubscriptionHomeSectionDataStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.subscriptionApiUrl);
        if (getResponse && getResponse.data) {
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.HomeSections = state.HomeSections ? state.HomeSections : [];
            return {
                data: state.HomeSections,
                totalCount: state.HomeSections.length
            }
        },

        insert: async (document) => {
            state.HomeSections.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.subscriptionApiUrl, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.subscriptionApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.HomeSections = state.HomeSections.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        }
    });
};

export const getSubscriptionSystemPropertiesDataStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.subscriptionApiUrl);
        if (getResponse && getResponse.data) {
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.SystemProperties = state.SystemProperties ? state.SystemProperties : [];
            return {
                data: state.SystemProperties,
                totalCount: state.SystemProperties.length
            }
        },

        insert: async (document) => {
            state.SystemProperties.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.subscriptionApiUrl, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.subscriptionApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.SystemProperties = state.SystemProperties.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        }
    });
};

export const getSubscriptionRoleDataStore = (data) => {
    let state = data;

    async function updateData() {
        let getResponse = await APIClient.get(global.config.subscriptionApiUrl);
        if (getResponse && getResponse.data) {
            state = getResponse.data;
        }
    }

    return new CustomStore({
        load: async function (loadOptions) {
            state.Roles = state.Roles ? state.Roles : [];
            return {
                data: state.Roles,
                totalCount: state.Roles.length
            }
        },

        insert: async (document) => {
            state.Roles.push(document);
            let response = {};
            if ('DocumentId' in state) {
                response = await APIClient.put(global.config.subscriptionApiUrl, state);
            }
            else {
                delete state.isNew;
                response = await APIClient.post(global.config.subscriptionApiUrl, state);
                state.DocumentId = response.data.DocumentId;
            }
            await updateData();
            return response;
        },
        remove: async (document) => {
            state.Roles = state.Roles.filter(x => x.DocumentId !== document.DocumentId);
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        },
        update: async (document) => {
            let response = await APIClient.put(global.config.subscriptionApiUrl, state);
            await updateData();
            return response;
        }
    });
};

export const getSubscription = async () => {
    let response = await APIClient.get(global.config.subscriptionApiUrl);
    if (response) {
        return response.data;
    }
};

export const getSubscriptionStore = () => {
    return new CustomStore({
        load: async function () {
            let response = await APIClient.get(global.config.subscriptionApiUrl);
            if (response) {
                return response.data;
            }
        },

        insert: async function (document) {
            let response = await APIClient.post(global.config.subscriptionApiUrl, document);
            if (response) {
                return response.data;
            }
        },
        update: async function (document) {
            let response = await APIClient.put(global.config.subscriptionApiUrl, document);
            if (response) {
                return response.data;
            }
        }
    });
};

export const getAuthorizedMetaData = async () => {
    return await APIClient.get(global.config.schemaViewApiUrl);
};

export const getLocalizedForm = async (schemaId, formId) => {
    let params = { formId: formId };
    let response = await APIClient.get(global.config.formApiUrl + "/" + schemaId, { params: params });
    if (response && response.data) {
        return response.data
    }
};

export const getIntentDataSource = (SchemaId = "", DocumentId = "") => {
    return new CustomStore({
        load: async function () {
            let response = await customAction(SchemaId, DocumentId, "GetIntentList");

            if (response && response.data) {
                return {
                    data: response.data
                };
            }
        },

        insert: async function (document) {
            return await customAction(SchemaId, DocumentId, "CreateIntent", document)
        },
        remove: async function (document) {
            return await customAction(SchemaId, DocumentId, "DeleteIntent", { id: document.id })
        },
        update: async function (document) {
            return await customAction(SchemaId, DocumentId, "RenameIntent", { id: document.id, name: document.name });
        }
    });
};

export const getEntityDataSource = (SchemaId = "", DocumentId = "") => {
    return new CustomStore({
        load: async function () {
            let response = await customAction(SchemaId, DocumentId, "GetEntityList");
            let prebuildEntityResponse = await customAction(SchemaId, DocumentId, "GetPrebuiltEntityList");
            let closedListEntityResponse = await customAction(SchemaId, DocumentId, "GetClosedListEntityList");
            let data = [];
            if (response && response.data && Array.isArray(response.data)) {
                data = response.data;
            }

            if (prebuildEntityResponse && prebuildEntityResponse.data && Array.isArray(prebuildEntityResponse.data)) {
                data = data.concat(prebuildEntityResponse.data);
            }

            if (closedListEntityResponse && closedListEntityResponse.data && Array.isArray(closedListEntityResponse.data)) {
                data = data.concat(closedListEntityResponse.data);
            }

            if (response && response.data) {
                return {
                    data: data
                };
            }
        },

        insert: async function (document) {
            if (document.type === "Simple") {
                return await customAction(SchemaId, DocumentId, "CreateEntity", document);
            }
            else if (document.type === "List") {
                return await customAction(SchemaId, DocumentId, "CreateListEntity", { name: document.name, sublists: [] });
            }
        },
        remove: async function (document) {
            return await customAction(SchemaId, DocumentId, "DeleteEntity", { id: document.id });
        },
        update: async function (document) {
            //return await customAction(SchemaId, DocumentId, "RenameEntity", {id: document.id, name: document.name});
        }
    });
};

export const getUtteranceDataSource = (SchemaId = "", DocumentId = "") => {
    return new CustomStore({
        load: async function () {
            let response = await customAction(SchemaId, DocumentId, "GetUtteranceList", { take: 500 });
            let { selectedIntent } = store.getState().nlpConfig;
            if (response && response.data) {
                response.data.forEach(item => {
                    item.entityLabels = item.entityLabels.map(entity => {
                        let word = "";

                        for (let i = entity.startTokenIndex; i <= entity.endTokenIndex; i++) {
                            word += item.tokenizedText[i];
                        }

                        return {
                            word,
                            entityName: entity.entityName
                        }
                    });
                    item.entityPredictions = item.entityPredictions.map(entity => {
                        let word = "";

                        for (let i = entity.startTokenIndex; i <= entity.endTokenIndex; i++) {
                            word += item.tokenizedText[i];
                        }

                        return {
                            word,
                            entityName: entity.entityName
                        }
                    });
                })
            }
            return {
                data: response.data.filter(x => x.intentLabel === selectedIntent.name)
            };
        },

        insert: async function (document) {
            let data = {
                intentName: store.getState().nlpConfig.selectedIntent.name,
                text: document.text,
                entityLabels: document.entityLabels.map(item => {
                    let startIndex = document.text.indexOf(item.word);
                    return {
                        entityName: item.entityName,
                        startCharIndex: startIndex,
                        endCharIndex: startIndex + item.word.length
                    }
                })
            };
            return await customAction(SchemaId, DocumentId, "CreateUtterance", data);
        },
        remove: async function (document) {
            return await customAction(SchemaId, DocumentId, "DeleteUtterance", { id: document.id });
        },
        update: async function (document) {

        }
    });
};

export const getReferenceDataSource = (schemaId, viewId, searchExpr) => {
    let subscriptionSchemaIds = ["c6fdd6f0-e23b-4f23-9b2f-a16f4063e0f4", "b2ff84ee-18b0-4c89-ade9-c1738c46c8ac", "f7a8f1a9-6df1-4bbf-a04e-872f2d79269b", "e36bff80-cc5e-47e2-8106-ed323743e837"];
    const SubscriptionViewIds = ["f2c2ac16-2676-40a6-a6e8-77ac18f5d72e", "4bd633c6-7d1d-4840-8a1a-bb327188036d", "31b629d1-6bc0-4850-b3d4-27f47cff2cdf", "c6a23751-cf9d-4dab-bd75-bf5e33f1366a"];
    return new DataSource({
        pageSize: 25,
        paginate: true,
        store: new CustomStore({
            load: async function (loadOptions) {
                if (!loadOptions.searchExpr && searchExpr) loadOptions.searchExpr = searchExpr;

                let parameters = {
                    filter: (loadOptions.searchValue) ? JSON.stringify([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]) : [],
                    skip: loadOptions.skip,
                    take: loadOptions.take,
                };

                let response = await APIClient.get(global.config.documentViewApiUrl + schemaId + "/" + viewId,
                    { params: parameters });

                if (response) {
                    return {
                        data: (response.data.Data) ? response.data.Data : [],
                        totalCount: (response.data.TotalCount) ? response.data.TotalCount : 0,
                    };
                }
            },
            byKey: async function (key) {
                if (subscriptionSchemaIds.includes(schemaId) && SubscriptionViewIds.includes(viewId)) {
                    let res = await APIClient.get(global.config.subscriptionApiUrl);
                    if (res && res.data) {
                        let data = res.data.Roles.find(x => x.DocumentId === key);
                        return (data) ? data : [];
                    }
                }

                let response = await APIClient.get(global.config.documentViewApiUrl + schemaId + "/" + viewId, { params: { filter: JSON.stringify(["DocumentId", "contains", key]) } });
                if (response && response.data) {
                    return (response.data.Data) ? response.data.Data : [];
                }
            },
        }),

    })

};