import React, { useState, useEffect } from 'react';
import './css/styles.css'
import MCHeader from "./common/Header";
import StarRating from "./common/StarRating";
import DriversQuestionsData from "./common/DriversQuestionsData";
import bajajLogo from '../../assets/images/bajajLogoNew.png'
import { decryptCrypto } from "./common/Crypto";
import { checkNPSLinkIsValid, fetchQuestionsData, saveQuestionsData } from './common/services';
import LinkInvalid from './common/InvalidLink';
import { AjaxLoader } from "../../common/Components/AjaxLoader";
import ThankComponent from './common/ThankyouScreen';
import BajajThankyouScreenLogo from '../../assets/images/bajajThankyouScreenLogo.svg'
import { detectCurrentDevice, getUniqueIdFromBrowser } from './common/utils'
import { AppName, logSubmitOrBrowserCloseLogHandler, logErrorHandler, logRatingHandler, logFreeTextHandler, logLanguageClick } from './common/AppInsightLog';
import withAppInsights from "./appInsights";
import FeedbackExpiredMCNew from '../../assets/images/FeedbackExpiredMCNew.svg'
import { CV, SOMETHING_WENT_WRONG } from './common/Constants';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load()

const CVMainContainer = () => {
    const currentTime = new Date()
    const [cryptoData, setCryptoData] = useState('')
    const [currentRating, setCurrentRating] = useState(null);
    const [questionsData, setQuestionsData] = useState(null)
    const [customerName, setCustomerName] = useState('')
    const [isLinkValid, setIsLinkValid] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(null)
    const [isFetchApiCalled, setIsFetchApiCalled] = useState(false)
    const [showThankyouScreen, setShowThankyouScreen] = useState(false)

    const onSubmitButtonClick = async (feedbackLines, suggetionText = '') => {
        const { parentSchemaName, docId, feedbackId1, visit, phone, documentNo } = cryptoData
        logFreeTextHandler(AppName.CV, parentSchemaName, suggetionText)
        const finalCVSubmitObj = {
            FeedbackLines: feedbackLines,
            IsFinalFeedbackSubmitted: true,
            SourceOfFeedback: "NPS",
            Remarks: suggetionText,
            FeedbackStarRating: currentRating,
            ParentSchemaName: parentSchemaName,
            DocumentNo: documentNo,
            ParentDocumentName: documentNo,
            MobileNo: phone,
            ProductType: "CommercialVehicle",
            DocID: docId,
            Recipient: phone,
            FeedbackID: feedbackId1,
            Visit: visit,
            SourceDevice: detectCurrentDevice() || '',
            NPS: "2.0",
        }
        setIsFetchApiCalled(true)
        const postDataResponse = await saveQuestionsData(finalCVSubmitObj, CV)
        logSubmitOrBrowserCloseLogHandler(AppName.CV, parentSchemaName, currentTime)
        setIsFetchApiCalled(false)
        if (postDataResponse && postDataResponse.IsRequestSuccessfull) {
            setShowThankyouScreen(postDataResponse)
        } else {
            setIsLinkValid(false)
            setErrorMessage(SOMETHING_WENT_WRONG)
            logErrorHandler(SOMETHING_WENT_WRONG)
        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    const alertUser = e => {
        logSubmitOrBrowserCloseLogHandler(AppName.CV, cryptoData?.parentSchemaName, currentTime)
    }

    useEffect(() => {
        async function fetchMyAPI() {
            const decryptData = await decryptCrypto();
            console.log('DECRYPT DATA: ', decryptData)
            if (!decryptData) {
                setErrorMessage(SOMETHING_WENT_WRONG)
                setIsLinkValid(false)
                return
            }
            setCryptoData(decryptData)
            const fp = await fpPromise
            const result = await fp.get()
            const visitorId = result.visitorId
            if (decryptData && decryptData.delearName) {
                const { feedbackId1, phone, linkSentDate, documentNo, parentSchemaName, customerName } = decryptData
                setCustomerName(customerName)
                const DeviceType = detectCurrentDevice() || ''
                const DeviceId = `${getUniqueIdFromBrowser()}_${DeviceType}_${visitorId}_${parentSchemaName}_${documentNo}`
                const obj = {
                    MobileNo: phone,
                    DeviceId,
                    DeviceType,
                    FeedbackID: feedbackId1,
                    DocumentNo: documentNo,
                    LinkSentDate: linkSentDate,
                    ParentSchemaName: parentSchemaName,
                    BU: "CV",
					ProductType: "CommercialVehicle",
                }
                const validLinkData = await checkNPSLinkIsValid(obj, CV)
                const { isNPSValidDevice, npsLinkIsValidResponse, Message, DeviceMessage, IsAlreadySubmitted } = validLinkData?.Data || {}
                if (!validLinkData || !validLinkData?.IsRequestSuccessfull) {
                    setIsFetchApiCalled(false)
                    setErrorMessage(SOMETHING_WENT_WRONG)
                    setIsLinkValid(false)
                    logErrorHandler(SOMETHING_WENT_WRONG)
                } else if (isNPSValidDevice === true && npsLinkIsValidResponse?.IsLinkValid === true) {
                    getQuestionsData(Number(feedbackId1))
                    setIsLinkValid(true)
                    setErrorMessage('')
                } else if (IsAlreadySubmitted === true) {
                    setErrorMessage(Message)
                    setIsAlreadySubmitted(true)
                    setIsLinkValid(false)
                    logErrorHandler(Message)
                } else if (npsLinkIsValidResponse?.IsLinkValid === false) {
                    setErrorMessage(Message)
                    setIsLinkValid(false)
                    logErrorHandler(Message)
                } else if (isNPSValidDevice === false) {
                    setErrorMessage(DeviceMessage)
                    setIsLinkValid(false)
                    logErrorHandler(DeviceMessage)
                } else {
                    setIsLinkValid(false)
                    setErrorMessage(SOMETHING_WENT_WRONG)
                    logErrorHandler(SOMETHING_WENT_WRONG)
                }
            }
        }
        fetchMyAPI()
    }, [])

    const getQuestionsData = async (LegacyId) => {
        if (!LegacyId) return
        setCurrentRating(null)
        window.scrollTo({ top: 0 });
        setIsFetchApiCalled(true)
        logLanguageClick(AppName.CV, LegacyId)
        const questionsArray = await fetchQuestionsData({ LegacyId: Number(LegacyId) }, CV)
        if (!questionsArray || !questionsArray.result) {
            setErrorMessage(SOMETHING_WENT_WRONG)
            logErrorHandler(SOMETHING_WENT_WRONG)
            setIsFetchApiCalled(false)
        } else {
            setQuestionsData(questionsArray)
            setIsFetchApiCalled(false)
        }
    }

    const onRatingClick = (ratingValue) => {
        logRatingHandler(AppName.CV, cryptoData?.parentSchemaName, ratingValue)
        setCurrentRating(ratingValue)
    }

    const showPage = isLinkValid === true && (questionsData && questionsData.result)
    const showLoader = isLinkValid === null && questionsData === null || isFetchApiCalled

    if (showThankyouScreen)
        return (
            <ThankComponent
                title='Get the Bajaj Auto app'
                color='#0052a1'
                borderColor='#0052a1'
                logo={BajajThankyouScreenLogo}
                appName={AppName.CV}
                className='thanksContainerIBU'
                msg={questionsData && questionsData.result && questionsData.result.ThankYouMsg}
                showDownloadHeaderProp={false}
            />
        )
    return (
        <div
            className='mainServiceContainer'
            id='mainServiceContainerId'
        >
            <MCHeader
                delearName={cryptoData?.delearName}
                location={cryptoData?.location}
                isKTM={false}
                logo={bajajLogo}
            />
            {showLoader ? <AjaxLoader fullScreen /> : null}
            {
                (isLinkValid === false || isAlreadySubmitted === true) ?
                    <LinkInvalid
                        message={errorMessage} //"“Feeback is already submitted”"
                        isKTM={false}
                        isLinkExpire={isAlreadySubmitted === true ? false : true}
                        inValidLinkImage={FeedbackExpiredMCNew}
                        alreadySubmittedImage='feedbackSubmitImageCV'
                    />
                    :
                    null
            }
            {showPage &&
                <>
                    <StarRating
                        questionsData={questionsData}
                        cryptoData={cryptoData}
                        customerName={customerName}
                        getQuestionsData={getQuestionsData}
                        setCurrentRating={onRatingClick}
                        currentRating={currentRating}
                    />
                    <div id='questionsMainContainer'>
                        {
                            currentRating !== null ?
                                <DriversQuestionsData
                                    questionsData={questionsData && questionsData.result}
                                    onSubmitButtonClick={onSubmitButtonClick}
                                    currentRating={currentRating}
                                    appName={AppName.CV}
                                    isPromoters={currentRating > 6 ? true : false}
                                    parentSchemaName={cryptoData?.parentSchemaName || ''}
                                /> : null
                        }
                    </div>
                </>
            }
        </div>
    )
}

function MainService() {
    return <CVMainContainer />
}

export default withAppInsights(MainService)