import React, { Component } from "react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai, getAppInsights } from '../../common/Components/TelemetryService';
import { withRouter } from 'react-router-dom';
import StarComponent from "./MCComponents/MCStars";
import AnswerComponent from "./MCComponents/MCAnswer";
import SuggestionsComponent from "./MCComponents/McSuggestions";
import Remarks from "./MCComponents/MCRemarks";
import FeedbackGiven from './MCComponents/MCFeedbackGiven';
import { AjaxLoader } from "../../common/Components/AjaxLoader";
import { decryptCrypto } from "./Crypto";
import { MCLinkInvalid } from "./MCComponents/MCInvalidLink";

import { AppName, ActionType, PageName, FeedbackType, logSubmitHandler, logErrorHandler, logRatingHandler, logQuestionHandler, logFreeTextHandler } from './services/AppInsightLog';

import {
  getQuestionsByLegacyId,
  saveFeedback,
  isFeedbackLinkValidForNPS,
  updateFeedbackNPS,
  getFeedbackByDocument
} from "./services/MCFeedbackService";

import ThankComponent from "./MCComponents/MCThankyou";
import "../../assets/css/feedback_MC.css";
import NPSTelemetryProvider from "./NPSTelemetryProvider";

const FIRST_PAGE = 1;
const LAST_PAGE = 5;
const enums = {
  FeedbackId1: 2,
  FeedbackId2: 3,
  ParentSchemaName: 4,
  DocumentNo: 5,
  Phone: 6,
  Visit: 7,
  DelearName: 8,
  Location: 9
};

const DocumentSchemaId = "1f83c827-96a6-471d-9201-e8bf2802e4a0";
const _partDocumentId = "e9bf69f7-1843-47ee-a126-22ea29c019ce";
const _partFinanceDocumentId = "bda58299-b001-4a82-ac2b-5af3bfefbd20"; //"d7bde40a-d664-4b32-a92c-47aef1ebe9c4";

class MCFeedback extends Component {
  appInsights = null;
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      feedbackQuestions: [],
      selectedFeedback: null,
      remarks: null,
      FinanceQuestions: [],
      FinanceRemarks: null,
      IsOther: false,
      salesComments: "",
      rating: null,
      finalComments: "",
      feedbackId1: null,
      feedbackId2: null,
      parentSchemaName: null,
      documentNo: null,
      phone: null,
      visit: null,
      delearName: null,
      location: null,
      answerSubmitDisable: true,
      loading: true,
      tempFinance: null,
      salesChecked: true,
      suggestion: null,
      thankyou: null,
      ratingQuestion: null,
      showPopup: false,
      feedbackGivenMessage: null,
      partialFeedbackResponse: null,
      updateFeedback: false,
      partialFeedbackData: null,
      partialDocumentId: null,
      partialDocumentNo: null,
      partialFinanceDocId: null,
      partialFinanceDocNo: null,
      initialized: false,
      currentTime: new Date(),
      invalidLink: false,
      noQuestions: false,
      docId:null,
      linkSentDate:null
    };

    this.onRatingClick = this.onRatingClick.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoBack = this.gotoBack.bind(this);
    this.onSaleCommentChange = this.onSaleCommentChange.bind(this);
    this.onFinalCommentChange = this.onFinalCommentChange.bind(this);
    this.onSalesSwitchChange = this.onSalesSwitchChange.bind(this);
  }

  async componentDidMount() {
    await this.setQueryParms();
    this.setupBeforeUnloadListener();
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      this.logBeforeUnload();
    });
  };

  logBeforeUnload = () => {
    const pageName = this.getPageName(this.state.pageIndex);
    logSubmitHandler(this.appInsights, AppName.MC, FeedbackType.Lead, pageName, this.state.currentTime, ActionType.PageDropped);
  }

  setQueryParms = async () => {
    //    const location = window.location.pathname;
    // const parms = location.split("/");
    const decrypt = await decryptCrypto();
    if (decrypt) {
      const feedbackId1 = decrypt.feedbackId1;
      const feedbackId2 = decrypt.feedbackId2;
      const parentSchemaName = decrypt.parentSchemaName;
      const documentNo = decrypt.documentNo;
      const phone = decrypt.phone;
      const visit = decrypt.visit;
      const linkSentDate = decrypt.linkSentDate;
      const docId = decrypt.docId;
      if (feedbackId1 && feedbackId2 && parentSchemaName && documentNo) {
        this.setState({
          feedbackId1: decrypt.feedbackId1,
          feedbackId2: decrypt.feedbackId2,
          parentSchemaName: decrypt.parentSchemaName,
          documentNo: decrypt.documentNo,
          phone: decrypt.phone,
          visit: decrypt.visit,
          delearName: decrypt.delearName,
          location: decrypt.location,
          docId:docId,
          linkSentDate:linkSentDate
        });
        await this.isFeedbackGiven(parentSchemaName, documentNo, phone, visit);
      }
    } else {
      this.setState({ invalidLink: true, loading: false });
    }
  };

  async getSalesQuestions() {
    const response = await getQuestionsByLegacyId(this.state.feedbackId1);
    console.log("question", response);
    if (response && response.IsRequestSuccessfull) {
      if (response.Data.length > 0) {
        const feedbackQuestions = response.Data[0].FeedbackQuestions;
        const suggestion = feedbackQuestions.find(x => x.FrontEndCode === "Suggestion");
        const thankyou = feedbackQuestions.find(x => x.FrontEndCode === "ThankYou");
        const ratingQuestion = feedbackQuestions.find(x => x.FrontEndCode === "CallBack");

        this.setPartialFeedbackData(feedbackQuestions);

        this.setState({
          feedbackQuestions: feedbackQuestions,
          remarks: response.Data[0].Remarks,
          suggestion: suggestion,
          thankyou: thankyou,
          ratingQuestion: ratingQuestion,
          loading: false
        });
      } else { this.setState({ noQuestions: true, loading: false }); }
    }
  }

  async getFinanceQuestions() {
    const response = await getQuestionsByLegacyId(this.state.feedbackId2);
    if (response && response.IsRequestSuccessfull) {
      if (response.Data.length > 0) {
        this.setState({
          FinanceQuestions: response.Data[0].FeedbackQuestions,
          FinanceRemarks: response.Data[0].Remarks
        });
      } else {
        this.setState({ noQuestions: true, loading: false });
      }
    }
  }

  async isFeedbackGiven(parentSchemaName, documentNo, phone, visit) {
    const data = {
      ParentSchemaName: parentSchemaName,
      ParentDocumentName: documentNo,
      MobileNo: phone,
      Source: "NPS",
      Visit: visit,
      DocumentSchemaId: DocumentSchemaId//"1f83c827-96a6-471d-9201-e8bf2802e4a0"
    }
    //const response = await getFeedbackByDocument(DocumentSchemaId, _partDocumentId); 
    const response = await isFeedbackLinkValidForNPS(data);
    console.log(response);
    if (response && response.Data) {
      if (response.Data.IsLinkValid) {
        await this.getFinanceQuestions();
        this.isFeedbackGivenFinance(documentNo, phone, visit);
        this.getSalesQuestions();
        this.setGivenFeedbackData(response.Data);
      } else {
        this.setState({ feedbackGivenMessage: response.Message, pageIndex: 6, loading: false });
      }
    } else {
      this.setState({ feedbackGivenMessage: response.Message, pageIndex: 6, loading: false });
    }
  };

  async isFeedbackGivenFinance(documentNo, phone, visit) {
    const data = {
      ParentSchemaName: "Finance",
      ParentDocumentName: documentNo,
      MobileNo: phone,
      Source: "NPS",
      Visit: visit,
      DocumentSchemaId: DocumentSchemaId
    }
    //const response = await getFeedbackByDocument(DocumentSchemaId,_partFinanceDocumentId);  
    const response = await isFeedbackLinkValidForNPS(data);
    console.log(response);
    if (response && response.Data && response.Data.IsLinkValid) {
      this.setGivenFinanceFeefbackData(response.Data);
    }
  }

  setGivenFeedbackData(feedbackResponse) {
    if (feedbackResponse && feedbackResponse.FeedbackData) {
      this.setState({
        partialFeedbackData: feedbackResponse.FeedbackData,
        rating: feedbackResponse.FeedbackData.FeedbackStarRating,
        partialDocumentId: feedbackResponse.FeedbackData.DocumentId,
        partialDocumentNo: feedbackResponse.FeedbackData.DocumentNo
      });
    }
  }

  setGivenFinanceFeefbackData(feedbackResponse) {
    if (feedbackResponse && feedbackResponse.FeedbackData) {
      const { FinanceQuestions } = this.state;

      let answers = [];
      let financeComments = null;
      let IsFinanceOther = false;
      const fininceRate = feedbackResponse.FeedbackData.FeedbackStarRating;
      const partialFeedbackLines = feedbackResponse.FeedbackData.FeedbackLines;

      if (fininceRate && partialFeedbackLines) {
        if (fininceRate >= 0 && fininceRate <= 6)
          answers = FinanceQuestions.find(x => x.FrontEndCode === "Detractors");
        else if (fininceRate >= 7 && fininceRate <= 8)
          answers = FinanceQuestions.find(x => x.FrontEndCode === "Promotors");
        else if (fininceRate >= 9 && fininceRate <= 10)
          answers = FinanceQuestions.find(x => x.FrontEndCode === "Promotors");
        const feedbackAnswers = answers.FeebackAnswers.sort((a, b) => a.DisplayIndex - b.DisplayIndex)
        answers.FeebackAnswers = feedbackAnswers;
        const answersLength = answers.FeebackAnswers.length;
        answers.FeebackAnswers.map((a, index) => {
          let item = partialFeedbackLines.find(x => x.AnswerId === a.DocumentId);
          a.IsSelected = item ? true : false;
          a.answerImages = this.getAnswerImage(a.DisplayIndex);
          if (answersLength === index + 1) {
            a.IsOthersRow = true;
            if (item) { financeComments = item.CustomerRemarks; IsFinanceOther = true }
          }
        });
        const tempFinance = { fininceRate, FrontEndCode: answers.FrontEndCode, answers, financeComments, IsFinanceOther };
        this.setState({
          tempFinance, pageIndex: 4,
          partialFinanceDocId: feedbackResponse.FeedbackData.DocumentId,
          partialFinanceDocNo: feedbackResponse.FeedbackData.DocumentNo
        });
      }
    }
  }

  setPartialFeedbackData(feedbackQuestions) {
    const { rating, partialFeedbackData, pageIndex } = this.state;
    if (rating && partialFeedbackData) {
      let answers = [];
      if (rating >= 0 && rating <= 6)
        answers = feedbackQuestions.find(x => x.FrontEndCode === "Detractors");
      else if (rating >= 7 && rating <= 8)
        answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
      else if (rating >= 9 && rating <= 10)
        answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
      const feedbackAnswers = answers.FeebackAnswers.sort(
        (a, b) => a.DisplayIndex - b.DisplayIndex
      );
      answers.FeebackAnswers = feedbackAnswers;
      const answersLength = answers.FeebackAnswers.length;
      answers.FeebackAnswers.map((a, index) => {
        let item = partialFeedbackData.FeedbackLines.find(
          x => x.AnswerId === a.DocumentId,
        );
        a.IsSelected = item ? true : false;
        a.answerImages = this.getAnswerImage(a.DisplayIndex);
        if (answersLength === index + 1) {
          a.IsOthersRow = true;
          if (item) {
            this.setState({ IsOther: true, salesComments: item.CustomerRemarks });
          }
        }
      });
      const selectedLength = answers.FeebackAnswers.filter(
        o => o.IsSelected === true
      );
      this.setState({
        selectedFeedback: answers,
        answerSubmitDisable: selectedLength.length > 0 ? false : true,
        updateFeedback: true,
        pageIndex: pageIndex === 1 ? 3 : pageIndex
      });
    }
  }

  onRatingClick = item => {
    const feedback = this.getFeedbackAnswers(item);
    this.setState({ selectedFeedback: feedback, rating: item, IsOther: false, salesComments: null });
    logRatingHandler(this.appInsights, AppName.MC, FeedbackType.Lead, item)
  };

  gotoNext = async (type, financeData) => {
    let { pageIndex } = this.state;
    let partialSave = true;
    let financeSave = false;
    if (type === "save") {
      partialSave = false;
      const { tempFinance } = this.state;
      await this.saveFeedbacks(tempFinance, partialSave);
    } else {
      if (this.state.pageIndex === LAST_PAGE) return;
      const value = parseInt(this.state.pageIndex) + 1;
      if (type === "tempsave") {
        financeSave = true;
        this.savePartialFinanceData(financeData, partialSave);
        //this.setState({ pageIndex: value, tempFinance: financeData });
      }
      else if (type === "partialSave") {
        const { tempFinance } = this.state;
        await this.saveFeedbacks(tempFinance, partialSave);
      }
      else this.setState({ pageIndex: value });
    }
    this.logUserEvents(pageIndex, financeSave);
  };

  gotoBack = () => {
    const backClick = true;
    const financeSave = false;
    const { pageIndex } = this.state;
    if (pageIndex === FIRST_PAGE) return;
    const value = parseInt(pageIndex) - 1;
    this.setState({ pageIndex: value });
    this.logUserEvents(pageIndex, financeSave, backClick)
  };

  onSaleAnswerSelect = item => {
    const { selectedFeedback } = this.state;
    const answers = selectedFeedback.FeebackAnswers;
    const index = answers.findIndex(a => a.id === item.id);
    answers[index].IsSelected = !answers[index].IsSelected;
    if (answers.length === index + 1) {
      answers[index].IsOthersRow = true;
      this.setState({ IsOther: !this.state.IsOther, showPopup: answers[index].IsSelected });
    }
    this.setState([...this.state.selectedFeedback.FeebackAnswers, answers]);
    const selectedLength = answers.filter(o => o.IsSelected === true);
    if (selectedLength.length > 0) {
      this.setState({ answerSubmitDisable: false });
    } else {
      this.setState({ answerSubmitDisable: true });
    }
    this.logQuestionEvent(answers[index]);
  };

  logQuestionEvent(Answer) {
    const { selectedFeedback } = this.state;
    logQuestionHandler(this.appInsights,
      AppName.MC,
      FeedbackType.Lead,
      selectedFeedback.DisplayName,
      Answer.DisplayHeader,
      Answer.DisplayDescription,
      Answer.IsSelected
    )
  }

  gotoOk = () => { };
  onSaleCommentChange = value => {
    this.setState({ salesComments: value, showPopup: true });
  };
  onFinalCommentChange = value => {
    this.setState({ finalComments: value, showPopup: true });
  };

  getFeedbackAnswers(item) {
    const { feedbackQuestions } = this.state;
    let answers = [];
    if (item >= 0 && item <= 6)
      answers = feedbackQuestions.find(x => x.FrontEndCode === "Detractors");
    else if (item >= 7 && item <= 8)
      answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
    else if (item >= 9 && item <= 10)
      answers = feedbackQuestions.find(x => x.FrontEndCode === "Promotors");
    const feedbackAnswers = answers.FeebackAnswers.sort((a, b) => a.DisplayIndex - b.DisplayIndex)
    answers.FeebackAnswers = feedbackAnswers;
    answers.FeebackAnswers.map((a, index) => {
      a.IsSelected = false;
      a.answerImages = this.getAnswerImage(a.DisplayIndex);
    });
    return answers;
  }

  getAnswerImage(index) {
    switch (index) {
      case 1:
        return {
          image: require("../../assets/images/ShowroomAcceIco.svg"),
          imageSelected: require("../../assets/images/ShowroomAcceIco_Selected.svg")
        };
      case 2:
        return {
          image: require("../../assets/images/showroomIco.svg"),
          imageSelected: require("../../assets/images/showroomIco_Selected.svg")
        };
      case 3:
        return {
          image: require("../../assets/images/salesIco.svg"),
          imageSelected: require("../../assets/images/salesIco_Selected.svg")
        };
      case 4:
        return {
          image: require("../../assets/images/testRide.svg"),
          imageSelected: require("../../assets/images/testRide_Selected.svg")
        }
      case 5:
        return {
          image: require("../../assets/images/products.svg"),
          imageSelected: require("../../assets/images/products_Selected.svg")
        }
      case 6:
        return {
          image: require("../../assets/images/prepurchaseIco.svg"),
          imageSelected: require("../../assets/images/prepurchaseIco_Selected.svg")
        }
      case 7:
        return {
          image: require("../../assets/images/exchangeIco.svg"),
          imageSelected: require("../../assets/images/exchangeIco_Selected.svg")
        }
      case 8:
        return {
          image: require("../../assets/images/pricesIco.svg"),
          imageSelected: require("../../assets/images/pricesIco_Selected.svg")
        }
      case 9:
        return {
          image: require("../../assets/images/othersIco.svg"),
          imageSelected: require("../../assets/images/othersIco_Selected.svg")
        }
      default:
        return {
          image: require("../../assets/images/othersIco.svg"),
          imageSelected: require("../../assets/images/othersIco_Selected.svg")
        }
    }
  }

  saveFeedbacks = async (financeData, partialSave) => {
    console.log("financeData", financeData);
    this.setState({ loading: true });
    try {
      const response = await this.saveShowRoomFeedback(partialSave);
      console.log("showroom save response", response);
      if (response.IsRequestSuccessfull) {
        if (partialSave) {
          const value = parseInt(this.state.pageIndex) + 1;
          const { partialDocumentId, partialDocumentNo } = this.state;
          this.setState({
            pageIndex: value,
            loading: false,
            updateFeedback: true,
            partialDocumentId: response.DocumentId ? response.DocumentId : partialDocumentId,
            partialDocumentNo: response.DocumentNo ? response.DocumentNo : partialDocumentNo
          });
        } else {
          if (financeData && financeData.fininceRate > 0) {
            const financeResponse = await this.savefinanceData(financeData, partialSave);
            console.log("finance save response", financeResponse);
            if (financeResponse.IsRequestSuccessfull) {
              this.setState({ pageIndex: 5, loading: false });
            }
          } else {
            this.setState({ pageIndex: 5, loading: false });
          }
          logFreeTextHandler(this.appInsights, AppName.MC, FeedbackType.Lead, this.state.finalComments)
        }
      }
    } catch (ex) {
      this.setState({ loading: false });
      logErrorHandler(this.appInsights, ex);
      console.log(ex);
    }
  };

  logUserEvents(pageIndex, financeSave, backClick = false) {
    let actionType = null;
    const { currentTime } = this.state;
    let pageName = this.getPageName(pageIndex, financeSave);
    if (backClick) {
      actionType = ActionType.BackBtn;
    } else {
      actionType = ActionType.NextBtn;
      if (pageIndex === 4)
        actionType = ActionType.SubmitBtn;
      if (financeSave && pageIndex === 3)
        actionType = ActionType.NextBtn;
      if (!financeSave && pageIndex === 3)
        actionType = ActionType.SubmitBtn;
    }
    logSubmitHandler(this.appInsights, AppName.MC, FeedbackType.Lead, pageName, currentTime, actionType);
    this.setState({ currentTime: new Date() });
  }

  getPageName(pageIndex, financeSave) {
    switch (pageIndex) {
      case 1:
        return PageName.Rating;
      case 2:
        return PageName.Questions;
      case 3:
        return financeSave ? PageName.FinanceQuestions : PageName.Feedback;
      case 4:
        return PageName.Feedback;
      case 5:
        return PageName.Thanks;
      default:
        return PageName.Rating;
    }
  }

  saveShowRoomFeedback = async (partialSave) => {
    const { IsOther, salesComments, finalComments, rating, updateFeedback } = this.state;
    const showroomFeedbacks = this.getShowRoomAnswers();
    const data = {
      IsFinalFeedbackSubmitted: partialSave ? false : true,
      Remarks: finalComments,
      FeedbackStarRating: rating,
      ParentSchemaName: this.state.parentSchemaName,
      DocumentNo: this.state.documentNo,
      ParentDocumentName: this.state.documentNo,
      MobileNo: this.state.phone,
      Recipient: this.state.phone,
      FeedBackID: this.state.feedbackId1,
      Source: "NPS",
      Visit: this.state.visit,
      FeedbackLines: showroomFeedbacks
    };
    if (updateFeedback) {
      const { partialDocumentId, partialDocumentNo } = this.state;
      data['DocumentNo'] = partialDocumentNo;
      return await updateFeedbackNPS(partialDocumentId, data);
    } else {
      return await saveFeedback(data);
    }
  };

  getShowRoomAnswers = () => {
    let feedbackAnswers = [];
    const { selectedFeedback, salesComments } = this.state;
    const answers = selectedFeedback.FeebackAnswers.filter(x => x.IsSelected);
    const answersLength = answers.length - 1;
    answers.map(answer => {
      let remarks = answer.IsOthersRow ? salesComments : "";
      feedbackAnswers.push({
        QuestionId: selectedFeedback.DocumentId,
        QuestionCode: selectedFeedback.Code,
        QuestionName: selectedFeedback.DisplayName,
        AnswerId: answer.DocumentId,
        AnswerCode: answer.Code,
        AnswerName: answer.DisplayName,
        id: answer.id,
        DocumentId: answer.DocumentId,
        SchemaId: answer.SchemaId,
        CustomerRemarks: remarks
      });
    });
    const callBackAnswer = this.getCallBackOption();
    feedbackAnswers.push(callBackAnswer);
    return feedbackAnswers;
  };

  getCallBackOption() {
    const { ratingQuestion, salesChecked } = this.state;
    const questionCode = salesChecked === true ? 'Yes' : 'No';
    const ratingAnswer = ratingQuestion.FeebackAnswers.find(x => x.Code === questionCode);
    return {
      QuestionId: ratingQuestion.DocumentId,
      QuestionCode: ratingQuestion.Code,
      QuestionName: ratingQuestion.DisplayName,
      AnswerId: ratingAnswer.DocumentId,
      AnswerCode: ratingAnswer.Code,
      AnswerName: ratingAnswer.DisplayName,
      id: ratingAnswer.id,
      DocumentId: ratingAnswer.DocumentId,
      SchemaId: ratingAnswer.SchemaId,
    }
  }

  savePartialFinanceData = async (financeData, partialSave) => {
    const value = parseInt(this.state.pageIndex) + 1;
    const response = await this.savefinanceData(financeData, partialSave)
    if (response.IsRequestSuccessfull) {
      const { partialFinanceDocId, partialFinanceDocNo } = this.state;
      this.setState({
        tempFinance: financeData,
        pageIndex: value,
        partialFinanceDocId: response.DocumentId ? response.DocumentId : partialFinanceDocId,
        partialFinanceDocNo: response.DocumentNo ? response.DocumentNo : partialFinanceDocNo,
      });
    }
  }

  savefinanceData = async (financeData, partialSave,docId,linkSentDate) => {
    
    const { IsOther, fininceComments, fininceRate, answers } = financeData;
    const showroomFeedbacks = this.getFinanceAnswers(answers, fininceComments);
    const data = {
      IsFinalFeedbackSubmitted: partialSave ? false : true,
      Remarks: fininceComments,
      FeedbackStarRating: fininceRate,
      ParentSchemaName: 'Finance',//FINANCE //this.state.parentSchemaName,
      DocumentNo: this.state.documentNo,
      ParentDocumentName: this.state.documentNo,
      MobileNo: this.state.phone,
      Recipient: this.state.phone,
      Source: "NPS",
      FeedBackID: this.state.feedbackId2,
      Visit: this.state.visit,
      FeedbackLines: showroomFeedbacks,
      DocID:docId,
      linkSentDate:linkSentDate
    };
    console.log(data);
    const { partialFinanceDocId, partialFinanceDocNo } = this.state;
    if (partialFinanceDocId) {
      data["DocumentNo"] = partialFinanceDocNo;
      return await updateFeedbackNPS(partialFinanceDocId, data);
    } else {
      return await saveFeedback(data);
    }
  };

  getFinanceAnswers = (answers, fininceComments) => {
    let finaenceAnswers = [];
    const selectedAnsers = answers.FeebackAnswers.filter(x => x.IsSelected);
    selectedAnsers.map(answer => {
      let remarks = answer.IsOthersRow ? fininceComments : "";
      finaenceAnswers.push({
        QuestionId: answers.DocumentId,
        QuestionCode: answers.Code,
        QuestionName: answers.DisplayName,
        AnswerId: answer.DocumentId,
        AnswerCode: answer.Code,
        AnswerName: answer.DisplayName,
        id: answer.id,
        DocumentId: answer.DocumentId,
        SchemaId: answer.SchemaId,
        CustomerRemarks: remarks
      });
    });
    return finaenceAnswers;
  };
  onSalesSwitchChange = (checked) => {
    this.setState({ salesChecked: checked });
  };
  onHidePopup = () => {
    this.setState({ showPopup: false });
  }

  onShownPopup = () => {
    this.setState({ showPopup: true });
  }

  render() {
    const { pageIndex, invalidLink, noQuestions } = this.state;
    //if (this.state.loading) return <AjaxLoader />;

    return (
      <NPSTelemetryProvider after={() => { this.appInsights = getAppInsights() }}>
        <div style={{ fontFamily: "arial", color: "#000000", margin: "auto", maxWidth: 450 }}>
          {invalidLink ? (<MCLinkInvalid message="Link is invalid." />) : null}
          {noQuestions ? (<MCLinkInvalid message="Something went wrong." />) : null}
          {pageIndex === 1 && invalidLink == false && noQuestions == false ? (
            <StarComponent
              stars={this.state.list}
              onRatingClick={this.onRatingClick}
              gotoNext={this.gotoNext}
              remarks={this.state.remarks}
              rating={this.state.rating}
              delearName={this.state.delearName}
              location={this.state.location}
            />
          ) : null}

          {this.state.loading ? <AjaxLoader /> : null}

          {pageIndex === 2 ? (
            <AnswerComponent
              gotoNext={this.gotoNext}
              gotoBack={this.gotoBack}
              feedback={this.state.selectedFeedback}
              onSaleAnswerSelect={this.onSaleAnswerSelect}
              IsOther={this.state.IsOther}
              onSaleCommentChange={this.onSaleCommentChange}
              salesComments={this.state.salesComments}
              submitDisable={this.state.answerSubmitDisable}
              delearName={this.state.delearName}
              location={this.state.location}
              onHidePopup={this.onHidePopup}
              showPopup={this.state.showPopup}
              onShownPopup={this.onShownPopup}
              setFocus={this.state.setFocus}
            />
          ) : null}

          {pageIndex === 3 ? (
            <SuggestionsComponent
              stars={this.state.list}
              gotoNext={this.gotoNext}
              gotoBack={this.gotoBack}
              remarks={this.state.FinanceRemarks}
              financeQuestions={this.state.FinanceQuestions}
              onFinalCommentChange={this.onFinalCommentChange}
              finalComments={this.state.finalComments}
              suggestion={this.state.suggestion}
              ratingObj={this.state.ratingQuestion}
              delearName={this.state.delearName}
              location={this.state.location}
              onSalesSwitchChange={this.state.onSalesSwitchChange}
              tempFinanceData={this.state.tempFinance}
              onHidePopup={this.onHidePopup}
              onShownPopup={this.onShownPopup}
              appInsights={this.appInsights}
            />
          ) : null}

          {pageIndex === 4 ? (
            <Remarks
              gotoNext={this.gotoNext}
              gotoBack={this.gotoBack}
              onSalesSwitchChange={this.onSalesSwitchChange}
              salesChecked={this.state.salesChecked}
              onFinalCommentChange={this.onFinalCommentChange}
              finalComments={this.state.finalComments}
              suggestion={this.state.suggestion}
              ratingQuestion={this.state.ratingQuestion}
              delearName={this.state.delearName}
              location={this.state.location}
              showPopup={this.state.showPopup}
              onHidePopup={this.onHidePopup}
              onShownPopup={this.onShownPopup}
            />
          ) : null}

          {pageIndex === 5 ? <ThankComponent thankyou={this.state.thankyou} gotoOk={this.gotoOk} delearName={this.state.delearName}
            location={this.state.location} /> : null}

          {pageIndex === 6 ? <FeedbackGiven message={this.state.feedbackGivenMessage} /> : null}
        </div>
      </NPSTelemetryProvider>
    );
  }
}
export default withRouter(withAITracking(ai.reactPlugin, MCFeedback));
