import React from 'react';
import { Route } from 'react-router-dom';
import { Utility } from '../utility';
import Axios from 'axios';
import { store } from '../../store';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated() == true ? (
            setHeaderAndReturnComponent(Component, props)
        ) : (
                redirectToLogin()
            )
    )} />
)

function isAuthenticated() {
    let authenticationTokenName = Utility.getAuthenticationToken();
    return !Utility.isNullOrEmpty(authenticationTokenName);
}

function setHeaderAndReturnComponent(Component, props) {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + Utility.getAuthenticationToken();
    let { loading } = store.getState().common;
    if (!loading)
        return <Component {...props} />;

    return null;
}

function redirectToLogin() {
    window.location.href = "/login";
}