import { createReducer } from "../../reducers/createReducer";
import { commonConstants } from "./commonConstants";
import { Config } from "../../config";

const initialState = {
  showChangePassword: false,
  activeTab: 0,
  filter: "",
  sucessmessage: "",
  homeMetadata: {},
  navigationList: {
    isNavigationCollapsed: false,
    businessMenu: {
      selectedItem: {},
    },
    nonBusinessMenu: {
      selectedItem: {},
      subMenu: {
        visible: false,
        selectedItem: {},
        showExpandedMenuOnClose: false,
      },
    },
    hoverOnCollapsed: false,
  },
  schema: {
    selectedSchema: {},
    allSchemaVisibility: false,
  },
  selectedDocumentView: {},
  selectedDocument: {},
  previousTabName: "Dashboard",
  tabName: "Dashboard",
  customForm: {
    showCustomForm: false,
    cellInfo: {},
    formOptions: {},
    customItems: [],
  },
  subscriptionData: {
    subscription: {},
    schemaMetaData: {},
  },
  currentGridData: {},
  loading: true,
  isExporting: false,
  popupFormOptions: { visible: false, form: {}, customItems: [] },
  gridOptions: { refresh: false, viewName: "" },
  progressControlActiveTab: 0,
  disableImportButton: true,
  gotoBackOptions: {
    gotoBack: false,
    prevData: {},
  },
  activeView: "",
  searchBarOptions: {
    showSearchBar: false,
    documentId: "",
    viewDocumentId: "",
  },
  searchDataOptions: {
    search: null,
    clearSearch: false,
  },
  selectedDashboard: "",
  formLoading: false,
  graphFilterString: "",
  maximunDocumentUploadLimit: Config.maxDocUploadLimit, // Limit sets for doc uploads.
  uploadedImageCount: 0
};

export const commonReducer = createReducer(initialState, {
  [commonConstants.UI_SET_SHOW_CHANGE_PASSWORD]: setShowChangePassword,
  [commonConstants.UI_SET_SELECTED_NAVIGATION_ITEM]: setSelectedNavigationItem,
  [commonConstants.UI_SET_LOGIN_ACTIVE_TAB]: setLoginActiveTab,
  [commonConstants.UI_SET_HOME_METADATA]: setHomeMetadata,
  [commonConstants.UI_SET_VIEW_NAME]: setViewName,
  [commonConstants.UI_TOGGLE_NAVIGATION_WIDTH]: toggleIsNavigationCollapsed,
  [commonConstants.UI_TOGGLE_SUB_NAVIGATION_VISIBILITY]: toggleIsSubMenuVisible,
  [commonConstants.UI_SET_SELECTED_DOCUMENT_VIEW]: setSelectedDocumentView,
  [commonConstants.UI_SET_SELECTED_NON_BUSINESS_ITEM]: setSelectedNonBusinessItem,
  [commonConstants.UI_SET_SELECTED_SUB_NAVIGATION_ITEM]: setSelectedSubNavigationItem,
  [commonConstants.UI_SET_SELECTED_SCHEMA]: setSelectedSchema,
  [commonConstants.UI_SET_SELECTED_FILTER]: setSelectedFilter,
  [commonConstants.UI_SET_SHOW_EXPANDED_MENU_ON_CLOSE]: setShowExpandedMenuOnClose,
  [commonConstants.UI_SET_ALL_SCHEMA_VISIBILITY]: setAllSchemaVisibility,
  [commonConstants.UI_SET_SELECTED_DOCUMENT]: setSelectedDocument,
  [commonConstants.UI_SHOW_CUSTOM_FORM]: setShowCustomForm,
  [commonConstants.UI_SET_ACTIVE_TAB_NAME]: setActiveTabName,
  [commonConstants.UI_SET_SUBSCRIPTION_DATA]: setSubscriptionData,
  [commonConstants.UI_SET_CURRENT_GRID_DATA]: setCurrentGridData,
  [commonConstants.UI_SET_IS_EXPORTING]: setIsExporting,
  [commonConstants.UI_SET_IS_LOADING]: setIsLoading,
  [commonConstants.UI_SET_HOVER_ON_COLLAPSED]: setHoverOnCollapsed,
  [commonConstants.UI_SET_SUCESSS_MESSAGE]: setSucessMessage,
  [commonConstants.UI_SET_POPUP_FORM_OPTIONS]: setPopupFormOptions,
  [commonConstants.UI_UPDATE_GRID_DATA]: updateGridData,
  [commonConstants.UI_UPDATE_DOCUMENT_SPECIFICS]: updateDocumentSpecifics,
  [commonConstants.SET_ACTIVE_PROGRESS_TAB]: setActiveProgressTab,
  [commonConstants.DISABLE_IMPORT_BUTTON]: disableImportButton,
  [commonConstants.UI_SET_GO_TO_BACK]: setGoToBackItem,
  [commonConstants.UI_SET_ACTIVE_VIEW_DEV_CONSOLE]: setActiveViewDevConsole,
  [commonConstants.UI_SET_SHOW_SEARCH_OPTION]: setShowSearchOptions,
  [commonConstants.UI_SET_SELECTED_SEARCH_DATA]: setSelectedSearchData,
  [commonConstants.UI_SET_SELECTED_DASHBOARD_VIEW]: setSelectedDashBoard,
  [commonConstants.UI_SET_IS_FORM_LOADING]: setIsFormLoading,
  [commonConstants.UI_SET_GRAPH_FILTER_STRING]: setGraphFilterString,
  [commonConstants.UI_SET_HOMECHARGING_IMAGE_COUNT]: setHomeChargingImageCount,
  [commonConstants.UI_UPLOADED_IMAGE_COUNT]: setUploadedImageCount
});

function setShowChangePassword(state, action) {
  return {
    ...state,
    showChangePassword: action.payload,
  };
}

function setSelectedNavigationItem(state, action) {
  return {
    ...state,
    previousTabName: "Dashboard",
    tabName: "Dashboard",
    navigationList: {
      ...state.navigationList,
      businessMenu: {
        ...state.navigationList.businessMenu,
        selectedItem: action.payload,
      },
      nonBusinessMenu: {
        ...state.navigationList.nonBusinessMenu,
        subMenu: {
          ...state.navigationList.nonBusinessMenu.subMenu,
          selectedItem: {},
        },
      },
    },
    schema: {
      ...state.schema,
      selectedSchema: {},
    },
    customForm: {
      ...state.customForm,
      showCustomForm: false,
      cellInfo: {},
    },
    gotoBackOptions: {
      ...state.gridOptions,
      gotoBack: false,
      prevData: {},
    },
    searchBarOptions: {
      ...state.searchBarOptions,
      showSearchBar: false,
      documentId: "",
      viewDocumentId: "",
    },
    searchDataOptions: {
      ...state.searchDataOptions,
      search: "",
      clearSearch: false,
    },
    selectedDashboard: "",
  };
}

function setLoginActiveTab(state, action) {
  return {
    ...state,
    activeTab: action.payload,
  };
}

function setHomeMetadata(state, action) {
  return {
    ...state,
    homeMetadata: action.payload,
  };
}

function setViewName(state, action) {
  return {
    ...state,
    viewName: action.payload,
    navigationList: {
      ...state.navigationList,
      businessMenu: {
        ...state.navigationList.businessMenu,
        selectedItem: {},
      },
    },
    selectedDocumentView: Object.assign({}, {}),
    customForm: {
      ...state.customForm,
      showCustomForm: false,
      cellInfo: {},
    },
  };
}

function toggleIsNavigationCollapsed(state) {
  return {
    ...state,
    navigationList: {
      ...state.navigationList,
      isNavigationCollapsed: !state.navigationList.isNavigationCollapsed,
    },
  };
}

function toggleIsSubMenuVisible(state) {
  return {
    ...state,
    navigationList: {
      ...state.navigationList,
      nonBusinessMenu: {
        ...state.navigationList.nonBusinessMenu,
        subMenu: {
          ...state.navigationList.nonBusinessMenu.subMenu,
          visible: !state.navigationList.nonBusinessMenu.subMenu.visible,
        },
      },
    },
  };
}

function setSelectedDocumentView(state, action) {
  return {
    ...state,
    selectedDocumentView: Object.assign(action.payload, {}),
  };
}

function setSelectedNonBusinessItem(state, action) {
  return {
    ...state,
    navigationList: {
      ...state.navigationList,
      nonBusinessMenu: {
        ...state.navigationList.nonBusinessMenu,
        selectedItem: action.payload,
        subMenu: {
          ...state.navigationList.nonBusinessMenu.subMenu,
          visible: true,
        },
      },
    },
    customForm: {
      ...state.customForm,
      showCustomForm: false,
      cellInfo: {},
    },
  };
}

function setSelectedSubNavigationItem(state, action) {
  return {
    ...state,
    navigationList: {
      ...state.navigationList,
      nonBusinessMenu: {
        ...state.navigationList.nonBusinessMenu,
        subMenu: {
          ...state.navigationList.nonBusinessMenu.subMenu,
          selectedItem: action.payload,
        },
      },
    },
    customForm: {
      ...state.customForm,
      showCustomForm: false,
      cellInfo: {},
    },
  };
}

function setSelectedSchema(state, action) {
  return {
    ...state,
    schema: {
      ...state.schema,
      selectedSchema: action.payload,
    },
  };
}

function setSelectedFilter(state, action) {
  console.log("setSelectedFilterReducer", state, action);
  return {
    ...state,
    filter: action.payload
  };
}

function setShowExpandedMenuOnClose(state, action) {
  return {
    ...state,
    navigationList: {
      ...state.navigationList,
      nonBusinessMenu: {
        ...state.navigationList.nonBusinessMenu,
        subMenu: {
          ...state.navigationList.nonBusinessMenu.subMenu,
          showExpandedMenuOnClose: action.payload,
        },
      },
    },
  };
}

function setAllSchemaVisibility(state, action) {
  return {
    ...state,
    schema: {
      ...state.schema,
      allSchemaVisibility: action.payload,
    },
  };
}

function setSelectedDocument(state, action) {
  return {
    ...state,
    selectedDocument: action.payload,
  };
}

function setShowCustomForm(state, action) {
  return {
    ...state,
    tabName: action.payload.show ? "Form" : state.previousTabName,
    customForm: {
      ...state.customForm,
      showCustomForm: action.payload.show,
      cellInfo: action.payload.show ? action.payload.cellInfo : {},
      formOptions: action.payload.show ? action.payload.formOptions : {},
      component: action.payload.show ? action.payload.component : {},
      rowIndex: action.payload.show ? action.payload.rowIndex : 0,
      customItems: action.payload.show ? action.payload.customItems : [],
    },
    progressControlActiveTab: 0,
  };
}

function setActiveTabName(state, action) {
  return {
    ...state,
    tabName: action.payload,
    previousTabName: action.payload,
  };
}

function setSubscriptionData(state, action) {
  return {
    ...state,
    subscriptionData: {
      subscription: action.payload.subscription,
      schemaMetaData: action.payload.schemaMetaData,
    },
  };
}

function setCurrentGridData(state, action) {
  return {
    ...state,
    currentGridData: action.payload,
  };
}

function setIsLoading(state, action) {
  return {
    ...state,
    loading: action.payload,
  };
}

function setIsExporting(state, action) {
  return {
    ...state,
    isExporting: action.payload,
  };
}

function setHoverOnCollapsed(state, action) {
  return {
    ...state,
    navigationList: {
      ...state.navigationList,
      hoverOnCollapsed: action.payload,
    },
  };
}

function setSucessMessage(state, action) {
  return {
    ...state,
    sucessmessage: action.payload,
  };
}

function setPopupFormOptions(state, action) {
  return {
    ...state,
    popupFormOptions: action.payload,
  };
}

function updateGridData(state, action) {
  return {
    ...state,
    gridOptions: {
      ...state.gridOptions,
      refresh: action.payload.refresh,
      viewName: action.payload.viewName,
    },
  };
}

function updateDocumentSpecifics(state, action) {
  return {
    ...state,
    selectedDocument: {
      ...state.selectedDocument,
      DocumentId: action.payload.DocumentId,
      CampaignPosting: action.payload.CampaignPosting,
    },
  };
}

function setActiveProgressTab(state, action) {
  return {
    ...state,
    progressControlActiveTab: action.payload,
  };
}

function disableImportButton(state, action) {
  return {
    ...state,
    disableImportButton: action.payload,
  };
}

function setGoToBackItem(state, action) {
  return {
    ...state,
    gotoBackOptions: {
      ...state.gotoBackOptions,
      gotoBack: action.payload.gotoBack,
      prevData: action.payload.prevData,
    },
  };
}

function setActiveViewDevConsole(state, action) {
  return {
    ...state,
    activeView: action.payload,
  };
}

function setShowSearchOptions(state, action) {
  return {
    ...state,
    searchBarOptions: {
      ...state.searchBarOptions,
      showSearchBar: action.payload.showSearchBar,
      documentId: action.payload.documentId,
      viewDocumentId: action.payload.viewDocumentId,
    },
  };
}

function setSelectedSearchData(state, action) {
  return {
    ...state,
    searchDataOptions: {
      ...state.searchDataOptions,
      search: action.payload.search,
      clearSearch: action.payload.clearSearch,
    },
  };
}

function setSelectedDashBoard(state, action) {
  return {
    ...state,
    selectedDashboard: action.payload,
  };
}

function setIsFormLoading(state, action) {
  return {
    ...state,
    formLoading: action.payload,
  };
}

function setGraphFilterString(state, action) {
  return {
    ...state,
    graphFilterString: action.payload,
  };
}

function setHomeChargingImageCount(state, action) {
  return {
    ...state,
    maximunDocumentUploadLimit: action.payload,
  };
}

function setUploadedImageCount(state, action) {
  return {
    ...state,
    uploadedImageCount: action.payload,
  };
}



