import axios from "axios";

axios.interceptors.request.use(
    config => {
        // console.log(config);
        return config;
    },
    error => {
        console.log("test");
    }
);

axios.interceptors.response.use(
    config => {
        return config;
    },
    error => {
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;

        if (!expectedError) {
            console.log(error);
        }

        return Promise.reject(error);
    }
);

function setAcessToken(token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
function setWhoAmIHeader() {
    axios.defaults.headers.common["Authorization"] = "Bearer";
    axios.defaults.headers.common["ClientId"] =
        "3b395214-7b3c-beab-3ce2-5a80e7455b0c";
    axios.defaults.headers.common["ClientSecret"] =
        "3b395214-7b3c-beab-3ce2-5a80e7455b0c";
}

function setResourceHeaders(element){
    axios.defaults.headers.common["SystemName"] =element.SystemName;
    axios.defaults.headers.common["DisplayName"] = element.DisplayName;
  }

function setDisplayName(DisplayName, DocumentId){
    axios.defaults.headers.common["DisplayName"] = DisplayName;
    axios.defaults.headers.common["DocumentId"] = DocumentId;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    all : axios.all,
    setAcessToken,
    setWhoAmIHeader,
    setResourceHeaders,
    setDisplayName
};
