import React, { Fragment } from 'react';
//import UserIcon from '../../assets/img/user.jpg';
import UserIcon from '../../assets/img/Avatar_icon.png';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Utility } from '../utility';
import { connect } from 'react-redux';
import {
    setShowChangePassword,
    setHomeMetaData,
    toggleNavigationWidth,
    setViewName,
    toggleSubNavigationVisibility,
    setSelectedSubNavigationItem,
    setSelectedSchema,
    setIsLoading,
    setActiveViewDevConsole
} from './commonActions';
import ChangePassword from './ChangePassword';
import { APIClient } from '../APIClient';
import notificationIcon from '../../assets/img/notification-icon.svg';
import menuIcon from '../../assets/img/menu.png';
import Axios from "axios/index";
import { AjaxLoader } from "./AjaxLoader";
import { getAuthorizedMetaData } from "../dataStore";
import Webchat from './WebChat/WebChat';
import { withRouter } from "react-router-dom";
import Search from './Search';

class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            isProcessing: true,
            isNotSubscriptionUser: false,
            showDevConsole: false,
            openChat: false,
            openChatPop: false,
            showChatBoard: false
        };

        this.signOut = this.onSignOut.bind(this);
        this.changePassword = () => { this.props.setShowChangePassword(true) };
        this.toggleNavigation = this.toggleNavigation.bind(this);
        this.setViewName = (viewName) => this.onSetViewName.bind(this, viewName);
        this.openChatWindow = this.openChatWindow.bind(this);
        this.resetChatWindow = this.resetChatWindow.bind(this);
    }

    async componentDidMount() {
        let showDevConsole = false;

        if (window.location.pathname.indexOf('terms-and-conditions') > 0) {
            this.props.setIsLoading(false);
            this.setState({ isProcessing: false });
            return;
        }

        this.setState({ isNotSubscriptionUser: Utility.getUserRole() !== "SubscriptionUser" });
        let params = { DeviceType: "Android", DeviceId: "123" };
        if (!Utility.isNullOrEmpty(global.config.subscriptionName)) {
            params["SubscriptionName"] = global.config.subscriptionName;
        }
        Axios.defaults.headers.common['ClientId'] = '3b395214-7b3c-beab-3ce2-5a80e7455b0c';
        Axios.defaults.headers.common['ClientSecret'] = '3b395214-7b3c-beab-3ce2-5a80e7455b0c';
        delete Axios.defaults.headers.common['Authorization'];  // Removed sending Authorization token in whoAmI call
        let response = await APIClient.get(global.config.whoAmIApiUrl, { params: params });
        if (response && response.data) {
            if (this.isCheckForAuthorization(window.location.pathname)) {
                if (!this.isAuthorized(Utility.getUserRole())) {
                    alert('You are not authorized to access this portal');
                    await this.onSignOut();
                }
                else {
                    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + Utility.getAuthenticationToken();
                    let schemaMetaData = await getAuthorizedMetaData();
                    if (schemaMetaData && schemaMetaData.data) {
                        response.data.Metadata = schemaMetaData.data;
                        let Metadata = schemaMetaData.data;
                        let showSchema = (Metadata.filter(x => x.IsAuthorized).length === Metadata.length);
                        let showSubscription = this.isAuthroized(Metadata, "Subscription");
                        let showNLPConf = this.isAuthroized(Metadata, "NLPConfigurations");
                        let showDesigner = this.isAuthroized(Metadata, "Asset");
                        let showAPIExplorer = this.isAuthroized(Metadata, "APILog");

                        showDevConsole = (showSchema || showSubscription || showNLPConf || showDesigner || showAPIExplorer);

                        this.ShowChatbotOnPortal(response.data.Properties);
                    }
                }
            }
            let token = Utility.getAuthenticationToken();
            if (!token) {
                Utility.setAuthenticationToken(response.data.Authorization);
            }
            this.props.setHomeMetaData(response.data);
        }
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + Utility.getAuthenticationToken();
        this.props.setIsLoading(false);
        this.setState({ isProcessing: false, showDevConsole });
    }
    isCheckForAuthorization(pathname) {
        return (pathname.indexOf('login') === -1 &&
            pathname.indexOf('preview') === -1 &&
            pathname.indexOf('thankyou') === -1 &&
            pathname.indexOf('terms-and-conditions') === -1 &&
            pathname.indexOf('attachment') === -1 &&
            pathname.indexOf('ktmfeedback') === -1 &&
            pathname.indexOf('mcfeedback') === -1 &&
            pathname.indexOf('mcfinance') === -1 &&
            pathname.indexOf('ktmservice') === -1 &&
            pathname.indexOf('mcservice') === -1 &&
            pathname.indexOf('ktmfinance') === -1);

    }

    isAuthroized(Metadata, SystemName) {
        let schema = Metadata.find(x => x.SystemName === SystemName);

        return (schema && schema.IsAuthorized);
    }

    onSetViewName(viewName) {
        this.props.history.push("/developerConsole");
        // this.props.setViewName(viewName);
        // this.props.setActiveViewDevConsole('');
    }

    ShowChatbotOnPortal(Properties) {
        let botSecretKey = Properties.find(x => x.Key === 'Bot_SET_TokenKey');
        if (botSecretKey && botSecretKey.Value) {
            Utility.setBotToken(botSecretKey.Value);
        }
        let ChatBoardPortal = Properties.find(x => x.Key === 'ShowChatbotOnPortal');
        let showChatBoard = ChatBoardPortal ? (ChatBoardPortal.Value.toUpperCase() === 'TRUE' ? true : false) : false;
        this.setState({ showChatBoard });
    }

    isAuthorized(Role) {
        return !(Utility.isNullOrEmpty(Role) || Role === "B2CUser");
    }

    toggleNavigation() {
        let { subMenu } = this.props.common.navigationList.nonBusinessMenu;
        if (subMenu.visible)
            this.props.toggleSubNavigationVisibility();

        this.props.toggleNavigationWidth()
    }

    onSchemaClicked() {

    }

    getAvatar() {
        return <img id="userIcon" src={UserIcon} width="25px" className="rounded-circle" alt="" />
    }

    async onSignOut() {
        let data = { AuthorizationToken: Utility.getAuthenticationToken() };
        try {
            if (Utility.getAuthenticationToken()) {
                await APIClient.post(global.config.identityApiUrl + "signoff", data, false);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            Utility.removeAuthenticationToken();
            window.location = "/login";
        }
    }

    openChatWindow(value) {
        this.setState({ openChatPop: value });
        const openChat = this.state.openChat;
        if (!openChat) {
            this.setState({ openChat: true });
        }
    }

    resetChatWindow() {
        this.setState({ openChat: false });
        setTimeout(() => { this.setState({ openChat: true }) }, 100);
    }
    isHideHeader(pathName) {
        return (pathName.indexOf('login') >= 0
            || pathName.indexOf('preview') >= 0
            || pathName.indexOf('thankyou') >= 0
            || pathName.indexOf('terms-and-conditions') >= 0
            || pathName.indexOf('attachment') >= 0
            || pathName.indexOf('ktmfeedback') >= 0
            || pathName.indexOf('mcfeedback') >= 0
            || pathName.indexOf('mcfinance') >= 0
            || pathName.indexOf('ktmservice') >= 0
            || pathName.indexOf('mcservice') >= 0
            || pathName.indexOf('ktmfinance') >= 0);
    }
    render() {

        let { isNotSubscriptionUser, isProcessing, showDevConsole, openChat, openChatPop, showChatBoard } = this.state;
        let hideHeader = this.isHideHeader(window.location.pathname);

        let ajaxLoader = <AjaxLoader fullScreen={true} />;
        let className = "header ";
        let { isNavigationCollapsed } = this.props.common.navigationList;
        let { showSearchBar } = this.props.common.searchBarOptions;
        className += (isNavigationCollapsed) ? "collapsed " : "expanded ";

        if (isProcessing && hideHeader) {
            return ajaxLoader
        }
        else if (isProcessing && !hideHeader) {
            return <Fragment>{ajaxLoader}<div className={className} /></Fragment>
        }

        if (hideHeader) return null;

        const chatPanelCss = openChatPop ? 'position-absolute chatPanel chatPanelShow' : 'position-absolute chatPanel chatPanelHide';
        const displayClose = openChatPop ? 'block' : 'none';

        return (<div id="header" className={className}>
            <div className="d-inline-block position-absolute header-menu-icon">
                {(!isNavigationCollapsed) ? <span className="oi oi-caret-left main-color mr-1 font-xs" /> : null}
                <img src={menuIcon} alt="" className="cursor-pointer" onClick={this.toggleNavigation} />
                {(isNavigationCollapsed) ? <span className="oi oi-caret-right main-color ml-1 font-xs" /> : null}
            </div>
            {/*<div className="d-inline-block position-absolute header-vertical-line"/>*/}
            {/*<div className="d-inline-block position-absolute header-search">*/}
            {/*<InputGroup>*/}
            {/*<InputGroupAddon className="search-icon-div" addonType="prepend" ><span className="oi oi-magnifying-glass"/></InputGroupAddon>*/}
            {/*<Input className="search-input" placeholder="Search in Service" />*/}
            {/*</InputGroup>*/}
            {/*</div>*/}

            {
                (showSearchBar) ? <div style={{ margin: 15, 'marginLeft': 72, float: 'left', width: '93%' }}> <Search /> </div> : null
            }

            <div className={chatPanelCss}>
                <div className="header">
                    <div className="chatTitle">WEB CHAT</div>
                    <div className="Close" style={{ 'display': displayClose }} onClick={() => this.openChatWindow(false)}></div>
                    <div className="dx-icon-refresh" style={{ 'display': displayClose }} onClick={this.resetChatWindow}></div>
                </div>
                <div className="layoutsize" >{openChat ? <Webchat /> : null}</div>
            </div>

            {
                showChatBoard ?
                    <div className="d-inline-block position-absolute chatButton">
                        <i className="fa fa-comments cursor-pointer" onClick={() => this.openChatWindow(true)} aria-hidden="true"></i>
                    </div> : null
            }

            <div className="d-inline-block position-absolute notification-icon">
                <img src={notificationIcon} alt="" />
            </div>

            <UncontrolledDropdown>
                <DropdownToggle className="custom-dropdown" style={{ alignItems: "center" }} caret>
                    {this.getAvatar()}
                </DropdownToggle >
                <DropdownMenu right>
                    {(isNotSubscriptionUser && showDevConsole) ? <DropdownItem tag="a" className="cursor-pointer" onClick={this.setViewName('developerConsole')}>Developer Console</DropdownItem> : null}
                    <DropdownItem tag="a" className="cursor-pointer" onClick={this.changePassword}>Change Password</DropdownItem>
                    <DropdownItem tag="a" className="cursor-pointer" onClick={this.signOut}>Sign Out</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ChangePassword />
        </div>);
    }
}

const mapStateToProp = (store) => ({
    common: store.common
});

const mapDispatchToProps = {
    setShowChangePassword,
    setHomeMetaData,
    toggleNavigationWidth,
    setViewName,
    toggleSubNavigationVisibility,
    setSelectedSubNavigationItem,
    setSelectedSchema,
    setIsLoading,
    setActiveViewDevConsole
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Header));