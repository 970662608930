import React from "react";
import ReactDOM from "react-dom";
import Template from './../Components/assets/template';
class BaseControl {
    itemType;
    cssClass;
    colSpan;
    colCount;
    caption;
    items;
    title;
    exEditorType;
    editEnabled;
}
class DxBaseControl extends BaseControl {
    dataField;
    label;
    editorType;
    width;
    height;
    editorOptions;
    template;
}
export class DxCard extends DxBaseControl {
    constructor(attribute) {
        super();
        this.dataField = attribute.data.DocumentId.replace(/[-]/g,""); 
        this.cardId = attribute.data.id;
    
        if (attribute.data.showData) 
            this.editorOptions = { value: attribute.data, elementAttr : attribute.data.TemplateId};
        else  
            this.editorOptions = {elementAttr : attribute.data.TemplateId}

        this.label = { visible: false };
        this.template = (data, itemElement) => {
            console.log(data);
            ReactDOM.render(this.getTemplate(data), itemElement);
        };
    }
    getTemplate(data) {
        const cardType = data.editorOptions.elementAttr;
        const value = data.editorOptions.value;
        return <Template cardtype={cardType}  Data={value} />;
    }
}

export function generateLayout(items, showData = true) {
    const jsonData = [];
    items.forEach(element => {
        element.showData = showData;
        let card = new DxCard({ data: element });
        jsonData.push(card);
    });
    return jsonData;
}
