import React from "react";
import Switch from "react-switch";
import imgArrow from "../../../assets/images/arrow.svg";

import imgArrowBack from "../../../assets/images/arrowBack.svg";
import KtmHeader from "./KtmHeader";

const KtmRemarks = ({ gotoNext, gotoBack, onFinalCommentChange, finalComments, onSalesSwitchChange, 
        salesChecked, suggestion, ratingQuestion , delearName, location , showPopup, onHidePopup, onShownPopup }) => {

    function onBlurHandler(){
        onHidePopup();
    }

    function onClickHandler(){
        onShownPopup();
    }

    return (
        <div style={{ position: "relative", height: "92vh" }}>
            <KtmHeader delearName={delearName} location={location} />
            <div className="container" style={{height:"78vh"}}>
            <div style={{ width: "98%", paddingTop: 20 }}>
                {suggestion && suggestion.DisplayName ? suggestion.DisplayName : null}
            </div>

            
                <div style={{ width: "100%", marginTop: 10 }}>
                    <textarea
                        style={{ color:'#ffffff' }}
                        onChange={(event) => onFinalCommentChange(event.target.value)}
                        value={finalComments}
                        onBlur={onBlurHandler}  
                        onClick={onClickHandler}
                        placeholder="Enter your suggestions"
                        rows="8"
                    ></textarea>
                </div>
            
            <div className="ktmSwitch" style={{ height: 55 }}>
                <div style={{ float: "left", width: "75%" }}>
                    {ratingQuestion && ratingQuestion.DisplayName ? ratingQuestion.DisplayName : null }
                </div>
                <div
                    style={{ float: "right", width: "25%", textAlign: "right" }}
                >
                    <label>
                        <Switch
                        offColor="#4c4c4c"
                        onColor="#ff6600" 
                            onChange={onSalesSwitchChange}
                            checked={salesChecked}
                        />
                    </label>
                </div>
            </div>
            </div>
            { !showPopup ? 
            <div className="btContainer">
                <div
                    className="submitBtBack"
                    
                    onClick={() => gotoBack()}
                >
                    <img
                        src={imgArrowBack}
                        width={25}
                        style={{ paddingRight: 10, marginLeft: -25 }}
                    />
                    BACK
                                </div>

                <div
                    className="submitBtKTM"
                    style={{ position: "absolute", right: 0 }}
                    onClick={() => { gotoNext("save") }}
                >
                    <img
                        src={imgArrow}
                        width={25}
                        style={{ paddingRight: 10, marginLeft: 25 }}
                    />
                    SEND FEEDBACK
                </div>
            </div>
             : null }
        </div>

    );
};

export default KtmRemarks;
