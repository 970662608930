
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ai } from '../appInsights';

export const AppName = {
    KTM: "KTM",
    MC: "MC",
    CV: "CV",
}
export const FeedbackType = {
    Sales: "SALES",
    Service: "SERVICE",
    Lead: "LEAD",
    Finance: "Finance"
}
export const PageName = {
    Rating: "RATING",
    Questions: "QUESTIONS",
    FinanceQuestions: "FINANCEQUESTIONS",
    Feedback: "FEEDBACK",
    Thanks: "THANKS"
}
export const ActionType = {
    NextBtn: "NEXTBUTTON",
    SubmitBtn: "SUBMITBUTTON",
    BackBtn: "BACKBUTTON",
    PageDropped: "PAGE DROPPED"
}
/**
 * Initialize the Application Insights class
 * @param {string} appName - Appliction name like "MC","PB","KTM"
 * @param {string} fbType -  Type of feedback "Sales,Service,Finance"
 * @param {string} pageName -  Name of the current page or View"
 * @param {date} startTime -  Start time of the View"
 * @param {date} endTime -  End time of the View"
 * @param {string} actionName -  Type of action perform"
 * @return {void}
 */
export const logSubmitOrBrowserCloseLogHandler = async (appName, fbType, startTime, actionName = 'SUBMITBUTTON') => {
    const endTime = new Date();
    const duration = endTime.getTime() - startTime.getTime();
    const logData = {
        feedbackType: fbType,
        duration,
        actionName: 'SUBMITBUTTON',
    }

    ai.trackEvent({ name: appName, properties: logData });
};

export const logFreeTextHandler = async (appName, fbType, Comments) => {
    const event = "Final Comments"
    const logData = {
        feedbackType: fbType,
        actionName: event,
        Comments: Comments
    }
    ai.trackEvent({ name: appName, properties: logData });
};

export const logRatingHandler = async (appName, fbType, ratingValue) => {
    const event = "Rating Click"
    const logData = {
        feedbackType: fbType,
        actionName: event,
        ratingValue: ratingValue
    }
    ai.trackEvent({ name: appName, properties: logData });
};

export const logQuestionHandler = async (appName, fbType, Questions, Answer) => {
    const event = "Answer Selection"
    const logData = {
        feedbackType: fbType,
        actionName: event,
        Questions: Questions,
        Answer: Answer,
    }
    ai.trackEvent({ name: appName, properties: logData });
};

export const logLinkClick = (appName, actionType) => {
    const logData = {
        actionType,
        actionName: 'Link Click'
    }
    ai.trackEvent({ name: appName, properties: logData });
}

export const logLanguageClick = (appName, legacyId) => {
    const logData = {
        legacyId,
        actionName: 'Language Click'
    }
    ai.trackEvent({ name: appName, properties: logData });
}

export const logTraceHandler = async (message) => {
    ai.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
};

/**
 * Initialize the Application Insights class
 * @param {string} appName - Appliction name like "MC","PB","KTM"
 * @param {string} fbType -  Type of feedback "Sales,Service,Finance"
 * @param {string} pageName -  Name of the current page or View"
 * @param {date} startTime -  Start time of the View"
 * @param {date} endTime -  End time of the View"
 * @param {string} actionName -  Type of action perform"
 * @param {object} error -  Error detatil object"
 * @param {enum} errorLevel -  Error SeverityLevel"
 * @return {void}
 */
export const logErrorHandler = async (error) => {
    ai.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
};

