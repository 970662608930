import React from 'react';

const KTMHeader = ({delearName, location}) => {

    return(
        <div style={{ textAlign:'center' }}>
        <div className="bajajLogoKTM" style={{float:"left"}}>
        </div>
          <div style={{float:"right", paddingRight:15, paddingTop:15, textAlign:"right" }}>
            <div style={{fontSize:14, color :"#ff6600" , textTransform:"uppercase", fontWeight:"600",letterSpacing:1}}>{delearName && delearName.length > 0 ? delearName.replace(/%20/g, ' ') : null}</div>
            <div style={{fontSize:12, color :"#ff6600", fontWeight:"600",letterSpacing:1}}>{location && location.length > 0? location.replace(/%20/g, ' ') : null}</div>
          </div>
          <div style={{clear:"both"}}></div>
      </div>
    )

}

export default KTMHeader;