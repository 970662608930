import React from 'react';
import {
    setSelectedSubNavigationItem,
    setSelectedNavigationItem,
    setViewName,
    toggleSubNavigationVisibility,
    toggleNavigationWidth
} from "../../commonActions";
import {connect} from "react-redux";
import {Utility} from "../../../utility";
import {getSchemaLocalizedStore} from "../../../dataStore";
import {AlertPopup} from "../../../AlertPopup";
import {Icons} from '../../Icons';
import { Collapse } from 'reactstrap';
import {withRouter} from "react-router-dom";

class SubNavigation extends React.Component{
    constructor(){
        super();

        this.state = {
            activeItemIndex: -1,
            activeChildIndex: -1,
            activeRelativeItemIndex: -1,
            activeMainNavIndex: -1
        }
    }

    onItemClick(systemName, itemIndex){
        let item = this.props.common.homeMetadata.Metadata.find(x=>x.HomeSectionSystemName === systemName && x.IsAuthorized);
        if(!item){
            AlertPopup.showPopup("error", "Selected Item is not present in Metadata");
            return;
        }

        this.loadDocumentById(item.DocumentId, itemIndex);
    }

    async loadDocumentById(schemaId, itemIndex, childIndex = -1, relativeItemIndex=-1){
        this.props.toggleSubNavigationVisibility();
        this.props.toggleNavigationWidth();
        
        this.toggleChildVisibility(itemIndex, childIndex, relativeItemIndex);
        this.props.history.push(`/dashboard/${schemaId}`);
    }

    showChildSections(item, e){
        this.setActive(e);
        this.props.setSelectedSubNavigationItem(item);
    }

    setActive(e){
        let element = document.querySelectorAll(".left-sub-navigation .active");
        if(element.length > 0){
            element[0].classList.remove("active");
        }
        if(e.target.nodeName !== "LI"){
            e.target.parentNode.classList.add("active");
        }
        else {
            e.target.classList.add("active");
        }
    }

    onToggleCollapse(index) {
        index = (index === this.state.activeMainNavIndex) ? -1 : index;
        this.setState({ activeMainNavIndex: index });
    }

    getItems(){
        let self = this;
        let { selectedItem } = this.props.common.navigationList.nonBusinessMenu;
        let { activeItemIndex, activeMainNavIndex } = this.state;
        if(Utility.isObjectEmpty(selectedItem)) return null;

        return selectedItem.ChildSections.map((item, index) => {
            let isActive = activeItemIndex === index;
            let isActiveMenu = activeMainNavIndex === index;
            let arrowIconType = isActiveMenu ? "upArrow.svg" : "downArrow.svg";
            if(item.ChildSections){
                let childItems = self.getChildren(item, index);

                if(childItems.length > 0) {
                    return (
                        <li key={`${selectedItem.DisplayName} ${index}`}>
                            <div id={"main" + index} onClick={this.onToggleCollapse.bind(this, index)}>
                                <span  className={isActiveMenu ? "title active" : "title"}>{item.DisplayName}</span>
                                <span className="float-right"><Icons type={arrowIconType} width="12px" height="8px" /></span>
                            </div>
                            <Collapse isOpen={isActiveMenu}>
                                <ul>
                                    {childItems}
                                </ul>
                            </Collapse>
                        </li>
                    );
                }
                else{
                    return null;
                }
            }

            let relatedItems = self.props.common.homeMetadata.Metadata.find(x=>x.HomeSectionSystemName === item.SystemName && x.IsAuthorized);
            if(relatedItems) {
                return (
                    <li key={`${selectedItem.DisplayName} ${index}`}
                        onClick={this.onItemClick.bind(this, item.SystemName, index)}>
                        <span className={isActive ? "title active" : "title"}>{item.DisplayName}</span>
                    </li>
                );
            }
            else{
                return null;
            }
        })
    }

    getChildren(item, i){
        let self = this;
        let {activeItemIndex, activeChildIndex, activeRelativeItemIndex} = this.state;
        let children = [];
        item.ChildSections.forEach((child, index) => {
            let relatedItems = self.props.common.homeMetadata.Metadata.filter(x=>x.HomeSectionSystemName === child.SystemName && x.IsAuthorized && x.View.filter(y => y.ShowInHome && y.IsAuthorized).length > 0).map((relatedItem, key) => {

                return (<li key={`childSection${index}${key}`} className={(activeItemIndex === i &&activeChildIndex === index && activeRelativeItemIndex === key) ? "list-item active cursor-pointer" : "list-item cursor-pointer"} onClick={this.loadDocumentById.bind(this, relatedItem.DocumentId, i, index, key)}>
                    <span className="pr-2"><Icons type="circle.svg" width="8px" height="8px"/></span>
                    {relatedItem.PluralDisplayName}
                </li>);
            })
            if(relatedItems.length > 0) {
                children.push(<li key={`${item.DisplayName} ${i} ${index}`}>
                    <div
                        className={(activeItemIndex === i && activeChildIndex === index) ? "sub-Item active" : "sub-Item"}>{child.DisplayName}</div>
                    {(relatedItems.length > 0) ? <ul className="sub-Item-list">{relatedItems}</ul> : null}
                </li>);
            }
        });

        return children
    }

    toggleChildVisibility(index, childIndex, relativeItemIndex){
        this.setState({
            activeItemIndex: index ,
            activeChildIndex: childIndex,
            activeRelativeItemIndex: relativeItemIndex
        });
    }

    render(){
        return (
            <div className="left-sub-navigation">
                <div className="h-100" style={{overflowY: "auto"}}>
                <ul className="left-sub-navigation-list">
                    {this.getItems()}
                </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProp = (store) => ({
    common: store.common
});

const mapDispatchToProps = {
    setSelectedSubNavigationItem,
    setSelectedNavigationItem,
    setViewName,
    toggleSubNavigationVisibility,
    toggleNavigationWidth
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(SubNavigation));