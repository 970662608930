import React from 'react';

const MCHeader = ({ delearName, location, logo, isKTM, huskarnaLogo }) => {
  return (
    <header>
      <div
        className='headerDivKTM'
        style={isKTM ? { background: '#373f43', paddingRight: 0 } : { flexDirection: 'row-reverse', background: '#1C548F' }}>
        <div className='dealerInfo'>
          <div className='dealerName'>{delearName && delearName.length ? delearName.replace(/%20/g, ' ') : null}</div>
          <div className='showroomLocation'>{location && location.length > 0 ? location.replace(/%20/g, ' ') : null}</div>
        </div>
        <div>
          <img className={isKTM ? 'ktmLogo1' : 'bajajNewLogo'} src={logo} />
          {
            isKTM &&
            <img className={'ktmLogo1'} src={huskarnaLogo} />
          }
        </div>
      </div>
    </header>
  )
}
export default MCHeader;
