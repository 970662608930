import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { AjaxLoader } from './AjaxLoader';
import { ValidationGroup } from 'devextreme-react/ui/validation-group';
import { Form } from 'devextreme-react/ui/form';
import { CommonOptionService } from './optionService';
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Utility } from '../utility';
import { setShowChangePassword } from './commonActions';
import { connect } from 'react-redux';
import { APIClient } from '../APIClient';

class ChangePassword extends React.Component {
    constructor() {
        super();

        this.state = {
            isProcessing: false,
            showSuccessMessage: false
        };

        this.changePasswordRef = React.createRef();
        this.hideChangePassword = () => { this.props.setShowChangePassword(false) }
        this.changePassword = this.onChangePassword.bind(this);
    }

    showError(errorMessage) {
        ReactDOM.render(<Alert color="danger mt-3">
            {errorMessage}
        </Alert>, document.getElementById('errorMessage'));
    }

    hideError() {
        ReactDOM.unmountComponentAtNode(document.getElementById('errorMessage'));
    }

    async onChangePassword() {
        let { isValid } = this.changePasswordRef.current._instance.validate();
        if (!isValid) return;

        let { OldPassword, NewPassword } = this.changePasswordRef.current.props.formData;
        let IdentityId = Utility.getIdentityId();
        let data = { IdentityId, OldPassword, NewPassword }
        this.setState({ isProcessing: true });
        try {
            let response = await APIClient.post(global.config.identityApiUrl + "changepassword", data, false);
            if (!response.data.IsChangePasswordSuccessfult && 'Reason' in response.data) {
                this.showError(response.data.Reason);
            }

            if (response.data.IsRequestSuccessfull) {
                this.showAlertMessage("Password has been updated successfully.", "success");
            }
            else{
                this.showAlertMessage(response.data.Message, "danger");
            }
        }
        catch (error) {
            this.showError("Somthing Went wrong. Please contact support");
        }
        finally {
            this.setState({ isProcessing: false });
        }
    }

    showAlertMessage(message, type) {
        ReactDOM.render(<Alert color={type}>
            {message}
        </Alert>, document.getElementById('message'));
    }

    getModalBody() {
        return (
            <Fragment>
                <div id="message"></div>
                <ValidationGroup>
                    <Form ref={this.changePasswordRef} formData={{ OldPassword: '', NewPassword: '', ConfirmNewPassword: '' }}
                        items={CommonOptionService.changePasswordFormItems()} />
                    <Button className="mt-3 w-100 btn-primary border border-white" onClick={this.changePassword}>
                        Change Password
                </Button>
                </ValidationGroup>
            </Fragment>
        );
    }

    render() {
        let { isProcessing } = this.state;
        let isOpen = this.props.common.showChangePassword;

        return (
                <Modal isOpen={isOpen} centered={true} style={{ width: "350px" }}>
                {isProcessing ? <AjaxLoader /> : null}
                    <ModalHeader toggle={this.hideChangePassword} className="main-color">Change Password</ModalHeader>
                    <ModalBody>
                        {this.getModalBody()}
                    </ModalBody>
                </Modal>
        );
    }
}

const mapStateToProps = (store) => ({
    common: store.common
});

const mapDispatchToProps = { setShowChangePassword };

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);