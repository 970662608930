import React from 'react';
import { CardTypes } from '../../Services/appenums';
import Templates from "./templates";

const Template = (props) => {
    let templateElement = null;
    switch (props.cardtype)
        {
            case (CardTypes.CardWithText):
                templateElement = <Templates.withText {...props}/>
                break;
            case (CardTypes.CardWithImage):
                templateElement = <Templates.withImage {...props} />
                break;
            case (CardTypes.CardWithTable):        
                templateElement = <Templates.withTable {...props} />
                break;
            case CardTypes.CardwithTableHeader:
                templateElement = <Templates.withTableHeader {...props} />
                break;
            case (CardTypes.CardwithGrayBGFull):        
                templateElement = <Templates.withGrayBGFull {...props} />
                break;
            case (CardTypes.CardwithHorzImage):        
                templateElement = <Templates.withHorzImage {...props} />
                break;
            case (CardTypes.CardwithRightImage):        
                templateElement = <Templates.withRightImage {...props} />
                break;
            case (CardTypes.CardwithLeftImage):        
                templateElement = <Templates.withLeftImage {...props} />
                break;
            case (CardTypes.CardwithGrayBG):        
                templateElement = <Templates.withGrayBG {...props} />
                break;
            case CardTypes.CardwithFullImage:
                templateElement = <Templates.withFullImage {...props} />
                break;
            case CardTypes.CardWithoutTitle:
                templateElement = <Templates.withoutTitle {...props} />
                break;
            case (CardTypes.CardwithNumberTable):
                templateElement = <Templates.withNumberTable {...props} />
                break;
            case (CardTypes.CardwithNumberTableHeader):
                templateElement = <Templates.withNumberTableHeader {...props} />
                break;  
            case CardTypes.CardWithFullImageWithoutHeader:
                templateElement = <Templates.withFullImageWithoutHeader {...props} />
                break;
            case CardTypes.CardwithLeftImageWithoutHeader:    
                templateElement = <Templates.withLeftImageWithoutHeader {...props} />
                break;
            case CardTypes.CardwithRightImageWithoutHeader:
                templateElement = <Templates.withRightImageWithoutHeader {...props} />
                break;
            case CardTypes.CardwithHorzImageWithoutHeader:
                templateElement = <Templates.withHorzImageWithoutHeader {...props} />
                break;    
            case CardTypes.CardWithFullImageTable:
                templateElement = <Templates.withFullImageTable {...props} />
                break;
            case CardTypes.CardWithLeftImageBottomTable:
                templateElement = <Templates.withLeftImageBottomTable {...props} />
                break;    
            default:
                templateElement = null;
                break;
    }
    return templateElement;
}

export default Template;