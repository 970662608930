import { combineReducers } from 'redux'
import { commonReducer } from '../common/Components/commonReducer';
import {nlpConfigReducer} from '../routes/DeveloperConsole/Component/NLPConfiguration/NLPConfigReducer';
import {designerReducer} from "../routes/Designer/DesignerReducer";
import {reportReducer} from "../routes/Report/reportReducer";
import { callCenterReducer } from "../routes/CallCenter/callCenterReducer";

export const rootReducer = combineReducers({
    common: commonReducer,
    nlpConfig: nlpConfigReducer,
    designer: designerReducer,
    report: reportReducer,
    callCenter: callCenterReducer
});