import React from "react";

import imgsuccessIcon from "../../../assets/images/successIconKTM.svg";

import imgbajajLogo from "../../../assets/images/bajajLogo.svg";

import imgecheck from "../../../assets/images/echeck.svg";

import KtmHeader from "./KtmHeader";

const KtmThankComponent = ({ gotoOk, thankyou, delearName, location }) => {
  return (

    <div style={{ position: "relative",height:"92vh" }}>
      <KtmHeader delearName={delearName} location={location} />
      <div className="mainContainer" style={{ position: "relative" }}>            
            <div className="middleRate">
              <img src={imgsuccessIcon} width={80} />
            </div>
            <div className="thanksTxt" style={{color:"#ffffff"}}>{thankyou && thankyou.DisplayName ? thankyou.DisplayName : null}</div>
            <div className="thanksTxtSmall" style={{color:"#ffffff"}}>{thankyou && thankyou.DisplayHeader ? thankyou.DisplayHeader : null }</div>
            <div className="thanksTxtBajaj" style={{color:"#ffffff"}}>{thankyou && thankyou.DisplayDescription ?thankyou.DisplayDescription : null}</div>
          </div>
    </div>
    
  );
};

export default KtmThankComponent;
